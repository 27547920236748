import React, { useEffect, useState } from 'react'; // Importa useState
import fondo from "../../Assets/images/fondoItem2.png"; // Asegúrate de que la ruta sea correcta
import { useSelector, useDispatch } from 'react-redux';
import { getMe } from '../../features/authSlice'; // Ajusta la ruta a tu authSlice
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const SaludoUsuario = () => {
    const dispatch = useDispatch(); // Inicializa dispatch
    const navigate = useNavigate(); // Inicializa navigate
    const { usuario, isLoading, isError } = useSelector((state) => state.auth); // Extrae el usuario del estado
    const [isVisible, setIsVisible] = useState(true); // Estado local para controlar la visibilidad

    useEffect(() => {
        if (!usuario) {
            dispatch(getMe());
        }
    }, [dispatch, usuario]); // Agrega usuario como dependencia

    if (isLoading) return <div>Cargando...</div>;
    if (isError) return <div>Error al cargar usuario.</div>;

    // Manejar la acción del botón "Sí"
    const handleYesClick = () => {
        navigate('/empleo'); // Redirige a /empleo
    };

    // Manejar la acción del botón "No"
    const handleNoClick = () => {
        setIsVisible(false); // Oculta el componente
    };

    if (!isVisible) return null; // Si no es visible, no renderiza nada

    return (
        <div
            className="relative flex flex-col items-center justify-center w-full h-64 p-4 bg-cover bg-center rounded-lg"
            style={{ backgroundImage: `url(${fondo})` }} // Fondo de la imagen
        >
            <div className="absolute inset-0 flex flex-col items-center justify-center rounded-lg"> {/* Capa oscura para mejorar la legibilidad */}
                <h1 className="text-4xl font-STHeitiMedium text-white">
                    Hola, {usuario?.nombre_usuario || 'Cargando usuario...'}
                </h1>
                <p className="text-xl text-white font-STHeitiMedium mt-2">
                    ¿Estas buscando empleo?
                </p>
                <div className="flex mt-4">
                    <button
                        className="bg-gray-100 text-black px-4 py-2 rounded-md mr-16 text-xl font-STHeitiMedium"
                        onClick={handleYesClick} // Agregar evento de clic
                    >
                        Si
                    </button>
                    <button
                        className="bg-gray-100 text-black px-4 py-2 rounded-md text-xl font-STHeitiMedium"
                        onClick={handleNoClick} // Agregar evento de clic
                    >
                        No, pero estoy abierto a opciones
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SaludoUsuario;
