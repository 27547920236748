import React from 'react';
import { Link } from 'react-router-dom';

const EmpresaOFCard = ({ id, nombre, logo, descripcion, onEditar, onVer }) => {
    console.log(logo)
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer">
      <img src={logo} alt={nombre} className="h-40 w-full object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold text-gray-800">{nombre}</h3>
        <p className="text-gray-600 mt-2 text-sm">{descripcion}</p>
        <div className="flex justify-between mt-4">
          <Link 
            to={`/gestionar-ofertas/${id}`} 
            className="bg-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-600 transition-colors"
            onClick={onVer} // Ejecuta la función onVer antes de redirigir
          >
            Gestionar Ofertas Laborales
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmpresaOFCard;
