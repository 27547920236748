import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaUserFriends, FaHandshake, FaNetworkWired } from 'react-icons/fa';

const Services = () => {
  const services = [
    {
      icon: FaBriefcase,
      title: 'Oportunidades Laborales',
      description: 'Conectamos a nuevos talentos y profesionales agustinos experimentados con empleadores que buscan su próximo colaborador.',
      animationProps: {
        initial: { x: '-100%', opacity: 0 },
        whileInView: { x: 0, opacity: 1 },
        transition: { duration: 0.8, ease: 'easeInOut' },
      },
    },
    {
      icon: FaUserFriends,
      title: 'Encuentra a tus Amigos',
      description: 'Reencuéntrate con tus compañeros de promoción y crea lazos que te acompañen en tu carrera.',
      animationProps: {
        initial: { scale: 0.8, opacity: 0 },
        whileInView: { scale: 1, opacity: 1 },
        transition: { duration: 0.8, ease: 'easeInOut' },
      },
    },
    {
      icon: FaHandshake,
      title: 'Alianzas Estratégicas',
      description: 'Fomenta colaboraciones con otros profesionales y construye un futuro sólido juntos.',
      animationProps: {
        initial: { y: 20, opacity: 0 },
        whileInView: { y: 0, opacity: 1 },
        transition: { duration: 0.8, ease: 'easeInOut' },
      },
    },
    {
      icon: FaNetworkWired,
      title: 'Red Social Activa',
      description: 'Únete a una comunidad agustina vibrante donde la interacción y el apoyo son la clave para el éxito.',
      animationProps: {
        initial: { rotate: -10, opacity: 0 },
        whileInView: { rotate: 0, opacity: 1 },
        transition: { duration: 0.8, ease: 'easeInOut' },
      },
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-b from-indigo-200 to-blue-300 py-20 px-4"
    >
      <div className="max-w-7xl mx-auto text-center">
        {/* Header section */}
        <motion.h1
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-6xl font-extrabold mb-10 text-gray-900"
        >
          FESA y Reconéctate: ¡Una Familia!
        </motion.h1>
        <motion.p
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-2xl mb-16 text-gray-700"
        >
          Una plataforma que impulsa tu carrera y fortalece tus conexiones.
        </motion.p>
        {/* Services grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2"
              {...service.animationProps}
            >
              <div className="flex items-center justify-center mb-4">
                <service.icon className="text-5xl text-blue-500 animate-pulse" />
              </div>
              <h3 className="text-2xl font-bold mb-2 text-gray-800">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>
        {/* Call to action */}
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          className="mt-16 text-center"
        >
          <a
            href="/registrarse"
            className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-purple-500 to-blue-600 text-white rounded-full font-bold shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105"
          >
            Únete a Nuestra Familia &rarr;
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Services;
