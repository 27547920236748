import React from 'react';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-b from-blue-200 to-indigo-300 py-20 px-4"
    >
      <div className="max-w-7xl mx-auto text-center">
        {/* Header section */}
        <motion.h1
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-6xl font-extrabold mb-10 text-gray-900"
        >
          Contáctanos
        </motion.h1>
        <motion.p
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-2xl mb-16 text-gray-700"
        >
          Estamos aquí para ayudarte. ¡Tu opinión es importante para nosotros!
        </motion.p>
        
        {/* Contact Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-16">
          <motion.div
            initial={{ x: '-100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <FaPhone className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Teléfono</h3>
            <p className="text-gray-600">+591 67493117</p>
          </motion.div>
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <FaEnvelope className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Correo Electrónico</h3>
            <p className="text-gray-600">fesa@sanagustin.edu.bo</p>
          </motion.div>
          <motion.div
            initial={{ x: '-100%', opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <FaMapMarkerAlt className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Dirección</h3>
            <p className="text-gray-600">Av. America , Cochabamba, Bolivia</p>
          </motion.div>
        </div>

        {/* Contact Form */}
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          className="bg-white p-8 rounded-lg shadow-lg"
        >
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Envíanos un Mensaje</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-gray-700">Nombre</label>
              <input type="text" required className="w-full p-2 border rounded" />
            </div>
            <div>
              <label className="block text-gray-700">Correo Electrónico</label>
              <input type="email" required className="w-full p-2 border rounded" />
            </div>
            <div>
              <label className="block text-gray-700">Mensaje</label>
              <textarea rows="4" required className="w-full p-2 border rounded" />
            </div>
            <button
              type="submit"
              className="w-full px-6 py-3 bg-gradient-to-r from-purple-500 to-blue-600 text-white rounded-full font-bold shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105"
            >
              Enviar Mensaje
            </button>
          </form>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;
