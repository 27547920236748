import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMe } from '../../features/authSlice';
import axios from 'axios';
import baseURL from "../../features/baseURL.js"
import { FaImage, FaVideo, FaFileAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const AddPost = () => {
  const dispatch = useDispatch();
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    contenido: '',
    url_media: '',
    tipo_media: '',
    tipo_autor: 'Perfil Profesional',
    id_empresa: ''
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!usuario) {
      dispatch(getMe());
    }

    if (usuario) {
      getEmpresas();
    }
  }, [dispatch, usuario]);

  const getEmpresas = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/empresas/usuario`);
      setEmpresas(response.data);
      setLoadingEmpresas(false);
    } catch (error) {
      console.error('Error loading companies:', error);
      toast.error('Error al cargar las empresas');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'id_empresa') {
      const company = empresas.find((empresa) => empresa.id === Number(value));
      setSelectedCompany(company);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        toast.error('El archivo es demasiado grande. El tamaño máximo es 10MB.');
        return;
      }
      setSelectedFile(URL.createObjectURL(file));
      setFormData(prevData => ({
        ...prevData,
        url_media: file,
        tipo_media: file.type.includes('image') ? 'Foto' : file.type.includes('video') ? 'Video' : 'Documento'
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const postData = new FormData();
    postData.append('id_autor', usuario.uuid_usuario);
    postData.append('tipo_autor', formData.tipo_autor);
    postData.append('contenido', formData.contenido);
    postData.append('tipo_media', formData.tipo_media);
    if (formData.url_media) {
      postData.append('url_media', formData.url_media);
    }
    if (formData.id_empresa) {
      postData.append('id_empresa', formData.id_empresa);
    }

    try {
      const response=await axios.post(`${baseURL.BASE_URL}/posts/add_post`,postData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Publicación creada exitosamente');
      resetForm();
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Ocurrió un error al crear la publicación');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFormData({
      contenido: '',
      url_media: '',
      tipo_media: '',
      tipo_autor: 'Perfil Profesional',
      id_empresa: ''
    });
    setSelectedFile(null);
    setSelectedCompany(null);
  };

  if (isLoading) return <div className="text-center mt-10 font-STHeiti">Cargando...</div>;
  if (isError) return <div className="text-center mt-10 font-STHeiti">Error al cargar usuario.</div>;

  return (
    <motion.div 
      className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6 w-screen font-STHeiti"
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center mb-4">
        {selectedCompany ? (
          <>
            {selectedCompany.url_logo ? (
              <img
                src={`${baseURL.BASE_URL}${selectedCompany.url_logo}`}
                alt="Foto de empresa"
                className="w-12 h-12 rounded-full border border-gray-300 shadow-sm mr-3 object-cover"
              />
            ) : (
              <div className="w-12 h-12 rounded-full mr-3 bg-gray-200 shadow-sm flex items-center justify-center">
                <span className="text-gray-500 text-xl font-bold">{selectedCompany.nombre_legal.charAt(0)}</span>
              </div>
            )}
            <h2 className="font-semibold text-gray-800 text-lg">{selectedCompany.nombre_legal}</h2>
          </>
        ) : (
          <>
            {usuario && usuario.url_foto_perfil_usuario ? (
              <img
                src={`${baseURL.BASE_URL}${usuario.url_foto_perfil_usuario}`}
                alt="Foto de perfil"
                className="w-12 h-12 rounded-full border border-gray-300 shadow-sm mr-3 object-cover"
              />
            ) : (
              <div className="w-12 h-12 rounded-full mr-3 bg-gray-200 shadow-sm flex items-center justify-center">
                <span className="text-gray-500 text-xl font-bold">{usuario?.nombre_usuario?.charAt(0) || '?'}</span>
              </div>
            )}
            <h2 className="font-semibold text-gray-800 text-lg">{usuario?.nombre_usuario || 'Cargando usuario...'}</h2>
          </>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <textarea
          name="contenido"
          value={formData.contenido}
          onChange={handleChange}
          placeholder="¿Qué deseas compartir?"
          className="w-full p-3 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 resize-none transition-all duration-300"
          rows="4"
          required
        />

        {selectedFile && (
          <div className="mt-3 bg-gray-50 p-2 rounded-lg shadow-inner relative">
            {formData.tipo_media === 'Foto' ? (
              <img src={selectedFile} alt="Vista previa" className="w-full h-auto rounded-lg" />
            ) : formData.tipo_media === 'Video' ? (
              <video controls src={selectedFile} className="w-full h-auto rounded-lg" />
            ) : (
              <p className="text-sm text-gray-600 break-all">{formData.url_media.name}</p>
            )}
            <button
              type="button"
              onClick={() => {
                setSelectedFile(null);
                setFormData(prev => ({ ...prev, url_media: '', tipo_media: '' }));
              }}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
            >
              ✕
            </button>
          </div>
        )}

        <div className="mt-4">
          <select
            name="tipo_autor"
            value={formData.tipo_autor}
            onChange={handleChange}
            className="w-full p-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-300"
          >
            <option value="Perfil Profesional">Perfil Profesional</option>
            <option value="Empresa">Empresa</option>
          </select>

          {formData.tipo_autor === 'Empresa' && (
            <select
              name="id_empresa"
              value={formData.id_empresa}
              onChange={handleChange}
              className="w-full mt-2 p-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-300"
              required
            >
              <option value="">Selecciona una empresa</option>
              {!loadingEmpresas && empresas.map((empresa) => (
                <option key={empresa.uuid_empresa} value={empresa.id}>{empresa.nombre_legal}</option>
              ))}
            </select>
          )}
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-2">
            <label htmlFor="file-upload" className="p-3 text-gray-600 hover:bg-gray-200 rounded-full transition-colors duration-200 cursor-pointer shadow-md">
              <FaImage className="text-xl" />
              <input
                type="file"
                id="file-upload"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
            <label htmlFor="video-upload" className="p-3 text-gray-600 hover:bg-gray-200 rounded-full transition-colors duration-200 cursor-pointer shadow-md">
              <FaVideo className="text-xl" />
              <input
                type="file"
                id="video-upload"
                accept="video/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
            <label htmlFor="document-upload" className="p-3 text-gray-600 hover:bg-gray-200 rounded-full transition-colors duration-200 cursor-pointer shadow-md">
              <FaFileAlt className="text-xl" />
              <input
                type="file"
                id="document-upload"
                accept=".pdf"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
          </div>
          <motion.button
            type="submit"
            className={`bg-gray-800 text-white px-6 py-2 rounded-full font-semibold transition-colors duration-300 ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
            }`}
            whileHover={{ scale: 1.05 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Publicando...' : 'Publicar'}
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
};

export default AddPost;