import React from 'react';
import { Award, Calendar, MapPin } from 'lucide-react';

const CertificateCard = ({ title, institution, issueDate, location, certificateId }) => {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 border-2 border-indigo-200 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1">
      <div className="flex items-start mb-4">
        <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center mr-4 shadow-md">
          <Award className="text-white" size={24} />
        </div>
        <div>
          <h2 className="font-bold text-2xl text-indigo-800">{title}</h2>
          <p className="text-indigo-600 text-lg">{institution}</p>
        </div>
      </div>
      
      <div className="space-y-2 mb-4">
        <div className="flex items-center text-gray-700">
          <Calendar className="mr-2" size={18} />
          <span>Emision/Validez: {issueDate}</span>
        </div>
        <div className="flex items-center text-gray-700">
          <MapPin className="mr-2" size={18} />
          <span>{location}</span>
        </div>
      </div>
      
      <div className="flex justify-between items-center mt-4 pt-4 border-t border-indigo-200">
        <span className="text-sm text-gray-500">ID Certificado: {certificateId}</span>
      </div>
    </div>
  );
};

export default CertificateCard;