import React, { useState } from 'react';
import imagenFondo from "../../Assets/images/fondoItem.png";
import frase from "../../Assets/images/fraseReconectate.webp";

const ReConectateBanner = () => {
  // Estado para controlar la visibilidad del banner
  const [isVisible, setIsVisible] = useState(true);

  // Función para manejar el cierre del banner
  const handleClose = () => {
    setIsVisible(false);
  };

  // Si el banner no es visible, no renderizarlo
  if (!isVisible) return null;

  return (
    <div className="relative w-full h-64 overflow-hidden bg-cover bg-center" 
         style={{ backgroundImage: `url(${imagenFondo})` }}>
      <div className="absolute inset-0 flex items-center justify-between p-8">
        <div className="text-6xl font-Myriad text-gray-800">
          <img src={frase} alt="frase" />
        </div>
        <div className="max-w-md text-left text-white">
          <p className="text-xl font-STHeitiMedium mb-2">
            En FESA, cultivamos relaciones que trascienden lo laboral.
            Conéctate con amigos y profesionales que comparten tus valores y metas, y juntos, impulsemos nuestro desarrollo.
          </p>
        </div>
        {/* Botón para cerrar el banner */}
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 text-white bg-red-600 hover:bg-red-700 rounded-full p-2">
          X
        </button>
      </div>
    </div>
  );
};

export default ReConectateBanner;
