import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { ThumbsUp, MessageSquare, Share2, MoreHorizontal, Send, Heart, Zap } from 'lucide-react';
import baseURL from "../../features/baseURL.js";

const Post = ({ post }) => {
  const [comment, setComment] = useState('');
  const [likes, setLikes] = useState(post.likes || 0);
  const [comments, setComments] = useState(post.comments || []);
  const [isLiked, setIsLiked] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Check if the user has already liked the post (you might need to implement this)
    // setIsLiked(checkIfUserLikedPost(post.uuid_post));
  }, [post.uuid_post]);

  const handleLike = async () => {
    try {
      await axios.post(`${baseURL.BASE_URL}/posts/${post.uuid_post}/like`);
      setLikes(prevLikes => prevLikes + (isLiked ? -1 : 1));
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    try {
      const response = await axios.post(`${baseURL.BASE_URL}/posts/${post.uuid_post}/comments`, { comment });
      setComments(prevComments => [...prevComments, response.data]);
      setComment('');
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const getAuthorName = () => {
    if (post.tipo_autor === 'Perfil Profesional' && post.perfil?.usuario?.exalumno) {
      return `${post.perfil.usuario.exalumno.nombres_exalumno} ${post.perfil.usuario.exalumno.apellidos_exalumno}`;
    } else if (post.tipo_autor === 'Empresa' && post.empresa) {
      return post.empresa.nombre_legal;
    }
    return 'Usuario Desconocido';
  };

  const getAuthorInitials = () => {
    const name = getAuthorName();
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  const getAvatarContent = () => {
    if (post.tipo_autor === 'Perfil Profesional') {
      return post.perfil?.usuario?.url_foto_perfil_usuario ? (
        <img src={`${baseURL.BASE_URL}${post.perfil.usuario.url_foto_perfil_usuario}`} alt="Avatar" className="w-full h-full object-cover rounded-full" />
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-purple-400 to-indigo-600 rounded-full text-white font-bold">
          {getAuthorInitials()}
        </div>
      );
    } else if (post.tipo_autor === 'Empresa') {
      return post.empresa?.url_logo ? (
        <img src={`${baseURL.BASE_URL}${post.empresa.url_logo}`} alt="Logo" className="w-full h-full object-cover rounded-full" />
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-blue-400 to-teal-600 rounded-full text-white font-bold">
          {getAuthorInitials()}
        </div>
      );
    }
    return (
      <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-gray-400 to-gray-600 rounded-full text-white font-bold">
        {getAuthorInitials()}
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-xl shadow-lg mb-6 max-w-2xl mx-auto overflow-hidden transition-all duration-300 hover:shadow-2xl"
    >
      <div className="p-6">
        <div className="flex items-center space-x-4">
          <motion.div 
            whileHover={{ scale: 1.1 }}
            className="w-14 h-14 rounded-full overflow-hidden flex-shrink-0 shadow-md"
          >
            {getAvatarContent()}
          </motion.div>
          <div className="flex-grow">
            <h3 className="font-bold text-lg text-gray-800">{getAuthorName()}</h3>
            <p className="text-sm text-gray-500">{new Date(post.createdAt).toLocaleString()}</p>
          </div>
          <motion.button 
            whileHover={{ rotate: 90 }}
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
          >
            <MoreHorizontal className="h-6 w-6" />
          </motion.button>
        </div>
        <motion.p 
          className={`mt-4 text-gray-700 ${isExpanded ? '' : 'line-clamp-3'}`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {post.contenido || 'Sin contenido'}
        </motion.p>
        {!isExpanded && post.contenido && post.contenido.length > 150 && (
          <button 
            onClick={() => setIsExpanded(true)}
            className="text-blue-500 hover:text-blue-600 text-sm mt-2"
          >
            Leer más
          </button>
        )}
        {post.url_media && (
          <motion.img 
            src={`${baseURL.BASE_URL}${post.url_media}`} 
            alt="Post media" 
            className="w-full rounded-lg mt-4 object-cover cursor-pointer"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          />
        )}
      </div>
      <div className="border-t border-gray-200 px-6 py-4">
        <div className="flex justify-between items-center">
          <motion.button
            onClick={handleLike}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`flex items-center space-x-2 ${isLiked ? 'text-red-500' : 'text-gray-500'} hover:text-red-500 transition-colors duration-200`}
          >
            <Heart className={`h-6 w-6 ${isLiked ? 'fill-current' : ''}`} />
            <span className="font-semibold">{likes}</span>
          </motion.button>
          <motion.button
            onClick={() => setShowComments(!showComments)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="flex items-center space-x-2 text-gray-500 hover:text-blue-500 transition-colors duration-200"
          >
            <MessageSquare className="h-6 w-6" />
            <span className="font-semibold">{comments.length}</span>
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="flex items-center space-x-2 text-gray-500 hover:text-green-500 transition-colors duration-200"
          >
            <Share2 className="h-6 w-6" />
            <span className="font-semibold">Compartir</span>
          </motion.button>
        </div>
      </div>
      <AnimatePresence>
        {showComments && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="border-t border-gray-200 px-6 py-4 space-y-4"
          >
            {comments.map((cmt, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="flex items-start space-x-3"
              >
                <div className="w-10 h-10 rounded-full bg-gradient-to-br from-purple-400 to-indigo-600 flex items-center justify-center text-white font-bold text-sm flex-shrink-0">
                  {cmt.author ? cmt.author[0].toUpperCase() : 'U'}
                </div>
                <div className="flex-grow bg-gray-100 rounded-2xl p-3">
                  <p className="font-semibold text-sm text-gray-800">{cmt.author || 'Usuario Anónimo'}</p>
                  <p className="text-sm text-gray-700">{cmt.comment}</p>
                </div>
              </motion.div>
            ))}
            <form onSubmit={handleCommentSubmit} className="mt-4 flex items-center space-x-2">
              <input
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Escribe un comentario..."
                className="flex-grow px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
              <motion.button
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-full p-3 hover:from-blue-600 hover:to-indigo-700 transition-all duration-200"
              >
                <Send className="h-5 w-5" />
              </motion.button>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Post;