import React from 'react';
import { Book, Calendar, User, Award } from 'lucide-react';

const PublicationCard = ({ tipo_publicacion, titulo_publicacion, autor, fecha_publicacion }) => {
  const getIcon = (tipo) => {
    switch (tipo) {
      case 'Articulo':
        return <Book className="text-white" size={24} />;
      case 'Libro':
        return <Book className="text-white" size={24} />;
      case 'Paper':
        return <Award className="text-white" size={24} />;
      case 'Patente':
        return <Award className="text-white" size={24} />;
      default:
        return <Book className="text-white" size={24} />;
    }
  };

  const getColor = (tipo) => {
    switch (tipo) {
      case 'Articulo':
        return 'from-green-50 to-emerald-100 border-emerald-200';
      case 'Libro':
        return 'from-blue-50 to-sky-100 border-sky-200';
      case 'Paper':
        return 'from-purple-50 to-indigo-100 border-indigo-200';
      case 'Patente':
        return 'from-red-50 to-rose-100 border-rose-200';
      default:
        return 'from-gray-50 to-slate-100 border-slate-200';
    }
  };

  return (
    <div className={`bg-gradient-to-br ${getColor(tipo_publicacion)} border-2 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1`}>
      <div className="flex items-start mb-4">
        <div className={`w-12 h-12 ${tipo_publicacion === 'Articulo' ? 'bg-emerald-500' : tipo_publicacion === 'Libro' ? 'bg-sky-500' : tipo_publicacion === 'Paper' ? 'bg-indigo-500' : 'bg-rose-500'} rounded-full flex items-center justify-center mr-4 shadow-md`}>
          {getIcon(tipo_publicacion)}
        </div>
        <div>
          <h2 className="font-bold text-2xl text-gray-800">{titulo_publicacion}</h2>
          <p className="text-gray-600 text-lg">{tipo_publicacion}</p>
        </div>
      </div>
      
      <div className="space-y-2 mb-4">
        <div className="flex items-center text-gray-700">
          <User className="mr-2" size={18} />
          <span>Autor: {autor}</span>
        </div>
        <div className="flex items-center text-gray-700">
          <Calendar className="mr-2" size={18} />
          <span>Fecha de publicación: {fecha_publicacion}</span>
        </div>
      </div>
      
      
    </div>
  );
};

export default PublicationCard;