import React from 'react';
import NavBarMain from "../Components/NavBarMain/NavBarMain.jsx"
const LayoutMain = ({ children }) => {


  return (
    <React.Fragment>
        <div className="min-h-screen bg-gray-100">
      <NavBarMain className="fixed top-0 left-0 right-0 z-50"/>
      <div className="flex-1 mr-70 pt-10">
          {children}
        </div>
         
          </div>
    </React.Fragment>
  );
};

export default LayoutMain;

