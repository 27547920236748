// src/components/ui/Alert.jsx

import React from 'react';

export const Alert = ({ type = 'info', children }) => {
  const alertClasses = {
    info: 'bg-blue-100 text-blue-700 border-blue-500',
    success: 'bg-green-100 text-green-700 border-green-500',
    warning: 'bg-yellow-100 text-yellow-700 border-yellow-500',
    error: 'bg-red-100 text-red-700 border-red-500',
  };

  return (
    <div className={`flex p-4 mb-4 border rounded-lg ${alertClasses[type]}`} role="alert">
      {children}
    </div>
  );
};

export const AlertDescription = ({ children }) => {
  return <p className="mt-2">{children}</p>;
};

export const AlertTitle = ({ children }) => {
  return <strong className="text-lg">{children}</strong>;
};

export const AlertDialog = ({ open, onOpenChange, children }) => {
  return open ? (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-30" onClick={() => onOpenChange(false)} />
      <div className="bg-white rounded-lg shadow-lg p-6 z-10">
        {children}
      </div>
    </div>
  ) : null;
};

export const AlertDialogAction = ({ onClick, children }) => {
  return (
    <button
      className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const AlertDialogContent = ({ children }) => {
  return <div className="mt-4">{children}</div>;
};

export const AlertDialogDescription = ({ children }) => {
  return <p className="text-sm text-gray-600">{children}</p>;
};

export const AlertDialogFooter = ({ children }) => {
  return <div className="mt-4 flex justify-end">{children}</div>;
};

export const AlertDialogHeader = ({ children }) => {
  return <div className="font-bold">{children}</div>;
};

export const AlertDialogTitle = ({ children }) => {
  return <h3 className="text-lg">{children}</h3>;
};
