import React, { useEffect } from 'react';
import LogIn from './Components/LogIn';
import LandingPageLayout from './Pages/LandingPage/LandingPageLayout';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import RegistrarseLayout from './Components/Registrarse/Registrarse';
import LayoutMain from "./Pages/MainFeed/LayoutMain.jsx"
import AddPublicacion from "./Pages/Publicacion/AddPublicacion.jsx";
import ActivateAccount from './Components/Registrarse/ActivationAccount.jsx';
import MainPerfilProfesional from './Components/PerfilProfesional/MainPerfilProfesionalRegistro.jsx';
import DetallesProfesionales from './Components/PerfilProfesional/MainDetallesProfesionales.jsx';
import ProfilePhotoUpload from './Components/Profile/profileandWelcome.jsx';
import MiPerfilPage from './Pages/Mired/MiPerfilPage.jsx';
import ListaEmpresasPage from './Pages/Empresa/ListaEmpresasPage.jsx';
import AgregarEmpresaPage from './Pages/Empresa/AddEmpresaPage.jsx';
import EditEmpresaPage from './Pages/Empresa/EdiEmpresa.jsx';
import EdiPerfilPage from './Pages/Mired/EdiPerfilPage.jsx';
import MiPerfilProfesionalPage from './Pages/Mired/MiPerfilProfesional.jsx';
import { getMe } from './features/authSlice.js';
import { useDispatch } from 'react-redux';
import AboutUsPage from './Pages/LandingPage/AboutUsPage.jsx';
import ServicesPage from './Pages/LandingPage/ServicesPage.jsx';
import ContactUsPage from './Pages/LandingPage/ContactUs.jsx';
import JobsOpPage from './Pages/LandingPage/JobsOpPage.jsx';
import OportunidadLaboralPage from './Pages/OportunidadLaboral/OportunidadLaboralPage.jsx';
import AplicacionLaboralPage from './Pages/OportunidadLaboral/PostulacionPage.jsx';
import MisPostulacionesLaboralesPage from './Pages/OportunidadLaboral/misPostulacionesPage.jsx';
import PostulacionesEmpresasPage from './Pages/OportunidadLaboral/empresasPostulacionesPage.jsx';
import EmpresaGestionarOfertas from './Pages/OportunidadLaboral/empresasGestionasOfertas.jsx';
import ListaPostulantesPage from './Pages/OportunidadLaboral/listaPostulantes.jsx';
import PrivateRoute from './Components/privateRoute.jsx';
function App() {

  
  return (
    
   <BrowserRouter >
    <Routes>
        <Route path="/login" element={<LogIn/>}/>
        <Route path='/' element={<LandingPageLayout/>}/>
        <Route path='/registrarse' element={<RegistrarseLayout/>}/>
        <Route path="/usuarios/activate/:uuid/:token" element={<ActivateAccount />} />
        <Route element={<PrivateRoute />}>
          <Route path='/perfilProfesional/crearPerfil' element={<MainPerfilProfesional/>}/>
          <Route path='/perfilProfesional/detalles_profesionales' element={<DetallesProfesionales/>}/>
          <Route path='/perfilProfesional/foto-perfil' element={<ProfilePhotoUpload/>}/>
          <Route path='/home' element={<LayoutMain/>}/>
          <Route path='/mi-red' element={<MiPerfilPage/>}/>
          <Route path='/mi-red/empresas' element={<ListaEmpresasPage/>}/>
          <Route path='/mi-red/empresas/add_empresa' element={<AgregarEmpresaPage/>}/>
          <Route path='/mi-red/empresas/update_empresa/:id' element={<EditEmpresaPage/>}/>
          <Route path="/mi-red/editar-perfil" element={<EdiPerfilPage/>}/>
          <Route path="/mi-red/perfilProfesional" element={<MiPerfilProfesionalPage/>}/>
          <Route path="/empleo" element={<OportunidadLaboralPage/>}/>
          <Route path="/empleo/postulacion/:uuid" element={<AplicacionLaboralPage/>}/>
          <Route path="/mis-postulaciones" element={<MisPostulacionesLaboralesPage/>}/>
          <Route path="/negocios" element={<PostulacionesEmpresasPage/>}/>
          <Route path="/gestionar-ofertas/:id" element={<EmpresaGestionarOfertas/>}/>
          <Route path="/postulantes/:uuid" element={<ListaPostulantesPage/>}/>
        </Route>
        
        <Route path="/nosotros" element={<AboutUsPage/>}/>
        <Route path="/servicios" element={<ServicesPage/>}/>
        <Route path="/contacto" element={<ContactUsPage/>}/>
        <Route path="/jobs" element={<JobsOpPage/>}/>
        
    </Routes>
   </BrowserRouter>
  );
}

export default App;
