// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  list: [],
  searchTerm: '',
  isLoading: false,
  isError: false,
  message: '',
};

export const getPerfilesProfesionales = createAsyncThunk('perfilesProfesionales/getPerfilesProfesionales', async () => {
  const response = await axios.get('/perfilesProfesionales'); // Ajusta la URL según tu API
  return response.data;
});

const userSlice = createSlice({
  name: 'perfilesProfesionales',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPerfilesProfesionales.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = '';
      })
      .addCase(getPerfilesProfesionales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      })
      .addCase(getPerfilesProfesionales.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setSearchTerm } = userSlice.actions;

export default userSlice.reducer;
