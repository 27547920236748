import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaPlus, FaBriefcase, FaMapMarkerAlt, FaMoneyBillWave, FaHeart, FaFilter } from 'react-icons/fa';
import axios from 'axios';
import baseURL from '../../features/baseURL';
import AddJobOpportunity from './AddOportunidadLaboral';
import JobOpportunityDetails from './DetallesOportunidadLaboral';
import fondoBolsa from "../../Assets/images/fondoBolsa2.webp"
import OfertaLaboralCard from './ofertlaboralCard';
const JobOpportunitiesList = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    salary: [0, 200000],
    contractType: '',
    modalidad: '',
  });

  useEffect(() => {
    fetchOpportunities();
  }, []);

  useEffect(() => {
    filterOpportunities();
  }, [searchTerm, opportunities, filters]);

  const fetchOpportunities = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/oportunidadesLaborales`);
      setOpportunities(response.data);
      setFilteredOpportunities(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching opportunities:', error);
      setLoading(false);
    }
  };

  const filterOpportunities = () => {
    const results = opportunities.filter(opportunity =>
      (opportunity.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
       opportunity.empresa.nombre_legal.toLowerCase().includes(searchTerm.toLowerCase())) &&
      opportunity.salario_min >= filters.salary[0] &&
      opportunity.salario_max <= filters.salary[1] &&
      (filters.contractType === '' || opportunity.tipo_contrato === filters.contractType) &&
      (filters.modalidad === '' || opportunity.modalidad === filters.modalidad)
    );
    setFilteredOpportunities(results);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
  };

  const handleCreationSuccess = () => {
    setIsCreating(false);
    fetchOpportunities();
  };

  const handleSelectOpportunity = (opportunity) => {
    setSelectedOpportunity(opportunity);
  };

  const handleCloseDetails = () => {
    setSelectedOpportunity(null);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterName]: value }));
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-50 to-indigo-100">
        <motion.div
          animate={{ rotate: 360, scale: [1, 1.2, 1] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
          className="w-16 h-16 border-t-4 border-blue-600 border-solid rounded-full"
        />
      </div>
    );
  }

  if (isCreating) {
    return <AddJobOpportunity onSuccess={handleCreationSuccess} />;
  }

  if (selectedOpportunity) {
    return <JobOpportunityDetails opportunity={selectedOpportunity} onClose={handleCloseDetails} />;
  }

  return (
    <motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  className="container mx-auto px-4 py-12 min-h-screen w-full font-sans max-w-screen-4xl"
  
>
      <h1 className="text-5xl md:text-6xl  text-transparent bg-clip-text bg-gray-700 mb-8 text-center font-STHeiti mt-8">Oportunidades Laborales</h1>
      
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="relative flex-grow mb-4 md:mb-0 md:mr-4">
          <input
            type="text"
            placeholder="Buscar oportunidades..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-12 pr-4 py-3 rounded-lg bg-white text-gray-800 shadow-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 font-STHeiti"
          />
          <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500" />
        </div>
        <div className="flex space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={toggleFilters}
            className="bg-orange-300 text-gray-600  px-6 py-3 rounded-lg flex items-center shadow-lg hover:bg-orange-400 transition-colors duration-300 font-STHeitiMedium"
          >
            <FaFilter className="mr-2" /> Filtros
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleCreateNew}
            className="bg-orange-300 text-gray-600 font-semibold px-6 py-3 rounded-lg flex items-center shadow-lg hover:bg-orange-400 transition-colors duration-300 font-STHeitiMedium"
          >
            <FaPlus className="mr-2" /> Crear Nueva
          </motion.button>
        </div>
      </div>

      <AnimatePresence>
        {showFilters && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-white rounded-lg shadow-lg p-6 mb-6"
          >
            <h3 className="text-xl font-semibold mb-4 font-STHeiti">Filtros Avanzados</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
  <label className="block text-sm font-medium text-gray-700 mb-2 font-STHeiti font-semibold">Rango Salarial</label>
  <div className="flex items-center space-x-4 font-STHeiti">
    <input
      type="range"
      min="0"
      max="200000"
      step="1000"
      value={filters.salary[1]}
      onChange={(e) => handleFilterChange('salary', [filters.salary[0], parseInt(e.target.value)])}
      className="w-full range-slider"
    />
    <span className="text-sm text-gray-600 font-STHeiti">${filters.salary[1]}</span>
  </div>

  <style jsx>{`
    .range-slider {
      -webkit-appearance: none; /* Quita el estilo por defecto en Safari */
      appearance: none; /* Quita el estilo por defecto */
      width: 100%; /* Ancho completo */
      height: 8px; /* Altura de la barra */
      background: #EA9902; /* Color de la barra */
      border-radius: 5px; /* Bordes redondeados */
    }

    .range-slider::-webkit-slider-thumb {
      -webkit-appearance: none; /* Quita el estilo por defecto en Safari */
      appearance: none; /* Quita el estilo por defecto */
      width: 20px; /* Ancho del "thumb" */
      height: 20px; /* Altura del "thumb" */
      border-radius: 50%; /* Bordes redondeados para el "thumb" */
      background: #EA9902; /* Color del "thumb" */
      cursor: pointer; /* Cambia el cursor al pasar por encima */
    }

    .range-slider::-moz-range-thumb {
      width: 20px; /* Ancho del "thumb" */
      height: 20px; /* Altura del "thumb" */
      border-radius: 50%; /* Bordes redondeados */
      background: #EA9902; /* Color del "thumb" */
      cursor: pointer; /* Cambia el cursor al pasar por encima */
    }

    /* Si solo quieres cambiar el color de la barra, puedes comentar o eliminar lo siguiente */
    /* .range-slider::-webkit-slider-runnable-track {
      background: #EA9902; /* Color de la barra */
    } */
    
    /* .range-slider::-moz-range-track {
      background: #EA9902; /* Color de la barra */
    } */
  `}</style>
</div>


              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2 font-STHeiti font-semibold">Tipo de Contrato</label>
                <select
                  value={filters.contractType}
                  onChange={(e) => handleFilterChange('contractType', e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md font-STHeiti "
                >
                  <option value="">Todos</option>
                  <option value="Tiempo Completo">Tiempo Completo</option>
                  <option value="Medio Tiempo">Medio Tiempo</option>
                  <option value="Freelance">Freelance</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2 font-STHeiti font-semibold">Modalidad</label>
                <select
                  value={filters.modalidad}
                  onChange={(e) => handleFilterChange('modalidad', e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md font-STHeiti "
                >
                  <option value="">Todas</option>
                  <option value="Remoto">Remoto</option>
                  <option value="Presencial">Presencial</option>
                  <option value="Híbrido">Híbrido</option>
                </select>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
  {filteredOpportunities.length > 0 ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      {filteredOpportunities.map((opportunity) => (
        <OfertaLaboralCard
          key={opportunity.id}
          opportunity={opportunity}
          onSelect={handleSelectOpportunity}
        />
      ))}
    </motion.div>
  ) : (
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center text-gray-500 text-lg mt-8"
    >
      No se encontraron oportunidades que coincidan con tu búsqueda.
    </motion.p>
  )}
</AnimatePresence>

    </motion.div>
  );
};

export default JobOpportunitiesList;