import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import ListadoPostulantes from '../../Components/OportunidadLaboral/ListaPostulantesOportunidad.jsx'
const ListaPostulantesPage= () => {
    
    
  return (
   <LayoutMain>
       <ListadoPostulantes/>
   </LayoutMain>
  )
}

export default ListaPostulantesPage;