import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Book, Briefcase, Award, Folder, FileText, Plus, Trash2, ChevronRight,CheckCircle,Languages,MoveRight,HeartHandshake,Medal} from 'lucide-react';
import axios from 'axios';
import baseURL from "../../features/baseURL.js"
import { Alert, AlertDescription, AlertTitle, AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../UI/Alert.jsx';
import Card from '../UI/CardExperienciaLaboral.jsx';
import EducationCard from '../UI/AcademicsCard.jsx';
import CardCerrtificado from '../UI/CardCertficado.jsx'
import ProjectCard from '../UI/proyectoCard.jsx';
import CardPublicacion from '../UI/CardPublicacion.jsx'
import LanguageSelector from '../UI/languajeSelector.jsx';
import LanguageLevelSelector from '../UI/languajeLevelSelector.jsx';
import LenguajesCard from "../UI/LenguajesCard.jsx"
import { ArrowRightIcon } from "@heroicons/react"
import { useNavigate } from 'react-router-dom';
import VolunteerCard from '../UI/VolunteerCard.jsx';
import RecognitionCard from '../UI/ReconocimientoCards.jsx';


const DetallesProfesionales = () => {
  const [activeTab, setActiveTab] = useState('educacion');
  const [educacion, setEducacion] = useState([]);
  const [experiencia, setExperiencia] = useState([]);
  const [certificaciones, setCertificaciones] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [lenguajes,setLenguajes]=useState([])
  const [publicaciones, setPublicaciones] = useState([]);
  const [voluntariados,setVoluntariados]=useState([]);
  const [reconocimientos,setReconocimientos]=useState([]);


  const [educacionesObtenidas,setEducacionesObtenidas]=useState(null)
  const [experienciasObtenidas,setExperienciasObtenidas]=useState(null)
  const [certificacionesObtenidas,setCertificacionesObtenidas]=useState(null)
  const [proyectosObtenidos,setProyectosObtenidos]=useState(null)
  const [publicacionesObtenidas,setPublicacionesObtenidas]=useState(null)
  const [lenguajesObtenidos,setLenguajesObtenidos]=useState(null)
  const [voluntariadosObtenidos,setVoluntariadosObtenidos]=useState(null)
  const [reconocimientosObtenidos,setReconocimientosObtenidos]=useState(null)
  const gradoObtenidoOptions = ['Bachillerato','Licenciatura','Maestria','Doctotado','Post-Doctorado'];
  const tipoPublicacionOptions=['Articulo', 'Libro', 'Paper', 'Patente'];
  const tipoExperienciaLaboralOptions=['Contrato de Planta', 'Contrato Temporal', 'Pasantia', 'Contrato de servicios']
  const [showAddForm, setShowAddForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5,setIsLoading5]=useState(true);
  const [isLoading6,setIsLoading6]=useState(true);
  const [isLoading7,setIsLoading7]=useState(true);
  const [isLoading8,setIsLoading8]=useState(true);
  const [isLoadingButton,setIsLoadingButton]=useState(false)

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [fileName, setFileName] = useState('');
 const  navigate=useNavigate();
  const handleLanguageSelect = (selectedLanguage) => {
    // Suponiendo que solo hay un objeto en el estado
    setLenguajes((prevLanguajes) => [
        {
            ...prevLanguajes[0], // Mantén el resto de las propiedades del primer objeto
            nombre_lenguaje: selectedLanguage.name, // Actualiza solo el nombre del lenguaje
        }
    ]);
    
};
const handleNext=()=>
{
  navigate('/perfilProfesional/foto-perfil')
}
 const handleLevelSelect=(selectedLevel)=>{
  setLenguajes((prevLanguajes) => [
    {
        ...prevLanguajes[0], // Mantén el resto de las propiedades del primer objeto
        nivel_lenguaje: selectedLevel, // Actualiza solo el nombre del lenguaje
    }
]);
 }
  
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file) {
      setFileName(file.name);
    }
  };



  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    getEducaciones();
    getExperiencias();
    getCertificaciones();
    getProyectos();
    getPublicaciones();
    getLenguajes();
    getVoluntariados();
    getReconocimientos();

  };
  const getEducaciones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/educaciones/perfilProfesional`);
      setEducacionesObtenidas(response.data || []);
    } catch (error) {
      console.error("Error al obtener educaciones:", error);
      setEducacionesObtenidas([]);
    } finally {
      setIsLoading(false);
    }
  };
  const getExperiencias = async () => {
    setIsLoading2(true);
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/experienciasLaborales/perfilProfesional`);
      setExperienciasObtenidas(response.data || []);
    } catch (error) {
      console.error("Error al obtener experiencias:", error);
      setExperienciasObtenidas([]);
    } finally {
      setIsLoading2(false);
    }
  };
  const getCertificaciones=async()=>{
    setIsLoading3(true);
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/certificaciones/perfilProfesional`);
      setCertificacionesObtenidas(response.data || []);
    } catch (error) {
      console.error("Error al obtener certificaciones:", error);
      setCertificacionesObtenidas([]);
    } finally {
      setIsLoading3(false);
    }
  }
  const getProyectos=async()=>
  {
    setIsLoading4(true);
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/proyectos/perfilProfesional`);
      setProyectosObtenidos(response.data || []);
    } catch (error) {
      console.error("Error al obtener proyectos:", error);
      setProyectosObtenidos([]);
    } finally {
      setIsLoading4(false);
    }
  }
  const getPublicaciones=async()=>
    {
      setIsLoading5(true);
      try {
        const response = await axios.get(`${baseURL.BASE_URL}/publicaciones/perfilProfesional`);
        setPublicacionesObtenidas(response.data || []);
      } catch (error) {
        console.error("Error al obtener publicaciones:", error);
        setPublicacionesObtenidas([]);
      } finally {
        setIsLoading5(false);
      }
    }
    const getLenguajes=async()=>{
      setIsLoading6(true);
      try {
        const response = await axios.get(`${baseURL.BASE_URL}/lenguajes/perfilProfesional`);
        setLenguajesObtenidos(response.data || []);
        console.log(response)
      } catch (error) {
        console.error("Error al obtener lenguajes:", error);
        setLenguajesObtenidos([]);
      } finally {
        setIsLoading6(false);
      }
    }
    const getVoluntariados=async()=>{
      setIsLoading7(true);
      try {
        const response = await axios.get(`${baseURL.BASE_URL}/voluntariados/perfilProfesional`);
        setVoluntariadosObtenidos(response.data || [])
      } catch (error) {
        console.error("Error al obtener voluntariados:", error);
        setVoluntariadosObtenidos([]);
      }finally{
        setIsLoading7(false);
      }
    }
    const getReconocimientos=async()=>{
      setIsLoading8(true);
      try {
        const response=await axios.get(`${baseURL.BASE_URL}/reconocimientos/perfilProfesional`);
        setReconocimientosObtenidos(response.data || []);

      } catch (error) {
        console.error("Error al obtener los reconocimienntos",error)
        setReconocimientosObtenidos([])
      }
      finally{
        setIsLoading8(false);
      }
    }
  const tabs = [
    { id: 'educacion', label: 'Educación', icon: Book },
    { id: 'experiencia', label: 'Experiencia Laboral', icon: Briefcase },
    { id: 'certificaciones', label: 'Certificaciones', icon: Award },
    { id: 'proyectos', label: 'Proyectos', icon: Folder },
    { id: 'publicaciones', label: 'Publicaciones', icon: FileText },
    { id:'lenguajes',label:'Idiomas',icon:Languages},
    { id: 'voluntariados',label:'Voluntariados',icon:HeartHandshake},
    {id:'reconocimientos',label:'Reconocimientos',icon:Medal}
  ];
  const handleSaveItem = async (section, id) => {
    setIsLoadingButton(true);
    let itemToSave;
    let link=null;
    // Encuentra el item a guardar basado en la sección y el id
    switch (section) {
      case 'educacion':
        itemToSave = educacion.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/educaciones/add_educacion`;
        
        break;
      case 'experiencia':
        itemToSave = experiencia.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/experienciasLaborales/add_experienciaLaboral`;

        break;
      case 'certificaciones':
        itemToSave = new FormData();
        const certItem = certificaciones.find(item => item.id === id);
        Object.keys(certItem).forEach(key => {
          itemToSave.append(key, certItem[key]);
        });
        
        // Añadir el archivo PDF si existe
        const fileInput = document.getElementById('document-upload');
        if (fileInput && fileInput.files[0]) {
          itemToSave.append('url_documento_certificacion', fileInput.files[0], fileInput.files[0].name);
        }
        link = `${baseURL.BASE_URL}/certificaciones/add_certificacion`;

      break;


      case 'proyectos':
        itemToSave = proyectos.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/proyectos/add_proyecto`;

        break;
      case 'publicaciones':
        itemToSave = publicaciones.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/publicaciones/add_publicacion`;

        break;
      case 'lenguajes':
        itemToSave = lenguajes.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/lenguajes/add_lenguaje`;
      break;
      case 'voluntariados':
        itemToSave=voluntariados.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/voluntariados/add_voluntariado`;

      break;

      case 'reconocimientos':
        itemToSave=reconocimientos.find(item => item.id === id);
        link=`${baseURL.BASE_URL}/reconocimientos/add_reconocimiento`;


        break;
    }
  
    try {
     // Envía el ítem al backend usando axios
     const response = await axios.post(link, itemToSave, {
      headers: section === 'certificaciones' ? 
        { 'Content-Type': 'multipart/form-data' } : 
        { 'Content-Type': 'application/json' }
    });
      setShowSuccessModal(true);
        clearFormAndRefreshData(section);
    console.log(itemToSave)
    } catch (error) {
      console.error('Error al guardar:', error);
      alert('Ocurrió un error al guardar los datos.');
      setIsLoadingButton(false);

    }finally{
      setIsLoadingButton(false);

    }
  };
  const clearFormAndRefreshData = (section) => {
    setShowAddForm(false);
    switch(section) {
      case 'educacion':
        setEducacion([]);
        getEducaciones();
        break;
      case 'experiencia':
        setExperiencia([]);
        getExperiencias();
        break;
      case 'certificaciones':
        setCertificaciones([])
        getCertificaciones();
        break;
      case 'proyectos':
        setProyectos([]);
        getProyectos();
      break;
      case 'publicaciones':
        setPublicaciones([])
        getPublicaciones();
      break;
      case 'lenguajes':
        setLenguajes([]);
        getLenguajes();
      break;
      case 'voluntariados':
        setVoluntariados([]);
        getVoluntariados();
      break;
      case 'reconocimientos':
        setReconocimientos([]);
        getReconocimientos();
      break;
      // ... (casos similares para otras secciones)
    }
  };
  const handleAddItem = (section) => {
    const newItem = { id: Date.now() };
    switch(section) {
      case 'educacion':
        setEducacion([...educacion, { ...newItem, institucion: '', titulo: '', gradoObtenido:'',areaEstudio:'',fechaInicio: '',fechaFinalizacion: '',isEstudioActual:false,GPA:0.00,descripcion:'',logros:''}]);
        setShowAddForm(true);

        break;
      case 'experiencia':
        setExperiencia([...experiencia, { ...newItem, nombreEmpresa:'',cargoLaboral:'',fechaInicio:'',fechaFinalizacion:'',isTrabajoActual:false,descripcion:'',responsabilidades:'',logros:'',ubicacion:'',tipoExperienciaLaboralOptions:''}]);
        setShowAddForm(true);

        break;
      case 'certificaciones':
        setCertificaciones([...certificaciones, { ...newItem, nombreCertificacion: '', organizacionEmisora: '', fechaEmision: '',fechaCaducidad:'',idCertificacion:'',localizacion:'' }]);
        setShowAddForm(true);

        break;
      case 'proyectos':
        setProyectos([...proyectos, { ...newItem, nombre_proyecto: '', fecha_inicio_proyecto:'', fecha_final_proyecto: '',is_proyectoCurso:false,descripcion_proyecto:'',rol_proyecto:''}]);
        setShowAddForm(true);

        break;
      case 'publicaciones':
        setPublicaciones([...publicaciones, { ...newItem, titulo_publicacion: '', tipo_publicacion: '',fecha_publicacion:'',autores:''}]);
        setShowAddForm(true);

        break;
      case 'lenguajes':
        setLenguajes([...lenguajes,{ ...newItem,nombre_lenguaje:'English (US)',nivel_lenguaje:'Basico'}]);
        setShowAddForm(true);
        break;
      case 'voluntariados':
        setVoluntariados([...voluntariados,{...newItem,nombre_organizacion:'',rol_organizacion:'',fecha_inicio_voluntariado:'',fecha_finalizacion_voluntariado:'',causa_voluntariado:'',is_rolActual:'',descripcion:''}]);
        setShowAddForm(true);
        break;
      case 'reconocimientos':
        setReconocimientos([...reconocimientos,{...newItem,nombre_reconocimiento:'',organizacion_emisora:'',fecha_recepcion_reconocimiento:'',descripcion:''}]);
        setShowAddForm(true);
      break;
    } 
  };

  const handleRemoveItem = (section, id) => {
    switch(section) {
      case 'educacion':
        setEducacion(educacion.filter(item => item.id !== id));
        setShowAddForm(false);

        break;
      case 'experiencia':
        setExperiencia(experiencia.filter(item => item.id !== id));
        setShowAddForm(false);

        break;
      case 'certificaciones':
        setCertificaciones(certificaciones.filter(item => item.id !== id));
        setShowAddForm(false);

        break;
      case 'proyectos':
        setProyectos(proyectos.filter(item => item.id !== id));
        setShowAddForm(false);

        break;
      case 'publicaciones':
        setPublicaciones(publicaciones.filter(item => item.id !== id));
        setShowAddForm(false);

        break;
      case 'lenguajes':
        setLenguajes(lenguajes.filter(item=>item.id !==id))
        setShowAddForm(false);
      break;
      case 'voluntariados':
        setVoluntariados(voluntariados.filter(item=>item.id !==id))
        setShowAddForm(false);
      break;
      case 'reconomientos':
        setReconocimientos(reconocimientos.filter(item=>item.id !==id))
        setShowAddForm(false);
      break;
    }
  };

  const handleInputChange = (section, id, field, value) => {
    const updateState = (prevState) => prevState.map(item => item.id === id ? { ...item, [field]: value } : item);
    switch(section) {
      case 'educacion':
        setEducacion(prevState => updateState(prevState));
        break;
      case 'experiencia':
        setExperiencia(prevState => updateState(prevState));
        break;
      case 'certificaciones':
        setCertificaciones(prevState => updateState(prevState));
        break;
      case 'proyectos':
        setProyectos(prevState => updateState(prevState));
        break;
      case 'publicaciones':
        setPublicaciones(prevState => updateState(prevState));
        break;
      case 'lenguajes':
        setLenguajes(prevState => updateState(prevState));
      break;
      case 'voluntariados':
        setVoluntariados(prevState=>updateState(prevState));
      break;
      case 'reconocimientos':
        setReconocimientos(prevState=>updateState(prevState));
      break;
    }
  };

  const renderForm = (section) => {
    let items, setItems;
    switch(section) {
      case 'educacion':
        items = educacion;
        setItems = setEducacion;
        break;
      case 'experiencia':
        items = experiencia;
        setItems = setExperiencia;
        break;
      case 'certificaciones':
        items = certificaciones;
        setItems = setCertificaciones;
        break;
      case 'proyectos':
        items = proyectos;
        setItems = setProyectos;
        break;
      case 'publicaciones':
        items = publicaciones;
        setItems = setPublicaciones;
        break;
      case 'lenguajes':
        items=lenguajes;
        setItems=setLenguajes;
        break;
      case 'voluntariados':
        items=voluntariados;
        setItems=setVoluntariados;
      break;
      case 'reconocimientos':
        items=reconocimientos;
        setItems=setReconocimientos;
      break;
    }

    return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          {section === 'educacion' && (
            <>
              <h3 className="text-lg font-semibold">Educación registrada:</h3>
              {isLoading ? (
                <p>Cargando educaciones...</p>
              ) : educacionesObtenidas.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {educacionesObtenidas.map((educacionObtenida) => (
                    
                    <EducationCard
                    key={educacionObtenida.uuid_educacion}
                    title={educacionObtenida.nombre_institucion_educativa}
                    institution={educacionObtenida.grado_obtenido}
                    graduationDate={`${educacionObtenida.fecha_inicio} al ${educacionObtenida.fecha_finalizacion}`}
                    status={educacionObtenida.is_estudio_actual}
                    />
                   
                  ))}
                </div>
              ) : (
                <p>No hay educaciones registradas.</p>
              )}
            </>
          )}

           
             {section === 'experiencia' && (
        <>
          <h3 className="text-lg font-semibold">Experiencia Laboral registrada:</h3>
          {isLoading2 ? (
            <p>Cargando experiencias laborales...</p>
          ) : experienciasObtenidas.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {experienciasObtenidas.map((experienciaObtenida) => (
                <Card
                  key={experienciaObtenida.uuid_experienciaLaboral}
                  description={experienciaObtenida.cargo_laboral}
                  title={experienciaObtenida.nombre_empresa}
                  deadline={`${experienciaObtenida.fecha_Inicio} al ${experienciaObtenida.fecha_Finalizacion}`}
                  status={experienciaObtenida.is_trabajoActual}
                  tipoExperienciaLaboral={experienciaObtenida.tipoExperienciaLaboral}
                  />
              ))}
            </div>
          ) : (
            <p>No hay experiencias laborales registradas.</p>
          )}
        </>
      )}
      {section === 'certificaciones' && (
        <>
          <h3 className="text-lg font-semibold">Certificaciones:</h3>
          {isLoading3 ? (
            <p>Cargando certificaciones...</p>
          ) : certificacionesObtenidas.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {certificacionesObtenidas.map((certificacionObtenida) => (
                <CardCerrtificado
                  key={certificacionObtenida.uuid_certificacionObtenida}

                  title={certificacionObtenida.nombre_certificacion}
                  issueDate={`${certificacionObtenida.fecha_emision} al ${certificacionObtenida.fecha_caducidad}`}
                  institution={certificacionObtenida.organizacion_emisora}
                  location={certificacionObtenida.localizacion}
                  certificateId={certificacionObtenida.id_certificacion}
                  />
              ))}
            </div>
          ) : (
            <p>No hay certificaciones registradas.</p>
          )}
        </>
      )}
      {section === 'proyectos' && (
        <>
          <h3 className="text-lg font-semibold">Proyectos:</h3>
          {isLoading4 ? (
            <p>Cargando proyectos...</p>
          ) : proyectosObtenidos.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {proyectosObtenidos.map((proyectoObtenido) => (
                <ProjectCard
                  key={proyectoObtenido.uuid_proyectoObtenido}
                  nombre_proyecto={proyectoObtenido.nombre_proyecto}
                  fecha_inicio_proyecto={proyectoObtenido.fecha_inicio_proyecto}
                  fecha_final_proyecto={proyectoObtenido.fecha_final_proyecto}
                  descripcion_proyecto={proyectoObtenido.descripcion_proyecto}
                  rol_proyecto={proyectoObtenido.rol_proyecto}
                  tecnologias={proyectoObtenido.tecnologias}
                  
                  />
              ))}
            </div>
          ) : (
            <p>No hay proyectos registrados.</p>
          )}
        </>
      )}
      {section === 'publicaciones' && (
        <>
          <h3 className="text-lg font-semibold">Publicaciones:</h3>
          {isLoading5 ? (
            <p>Cargando publicaciones...</p>
          ) : publicacionesObtenidas.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {publicacionesObtenidas.map((publicacionObtenida) => (
                <CardPublicacion
                  key={publicacionObtenida.uuid_publicacion}
                  tipo_publicacion={publicacionObtenida.tipo_publicacion}
                  titulo_publicacion={publicacionObtenida.titulo_publicacion}
                  fecha_publicacion={publicacionObtenida.fecha_publicacion}
                  autor={publicacionObtenida.autores}
                />
              ))}
            </div>
          ) : (
            <p>No hay publicaciones registradas.</p>
          )}
        </>
      )}
      {section === 'lenguajes' && (
        <>
          <h3 className="text-lg font-semibold">Idiomas:</h3>
          {isLoading6 ? (
            <p>Cargando idiomas...</p>
          ) : lenguajesObtenidos.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {lenguajesObtenidos.map((lenguajeObtenido) => (
                <LenguajesCard
                  key={lenguajeObtenido.uuid_lenguaje}
                  nombre_lenguaje={lenguajeObtenido.nombre_lenguaje}
                  nivel_lenguaje={lenguajeObtenido.nivel_lenguaje}
                />
              ))}
            </div>
          ) : (
            <p>No hay idiomas obtenidos.</p>
          )}
        </>
      )}
      {section === 'voluntariados' && (
        <>
          <h3 className="text-lg font-semibold">Voluntariados:</h3>
          {isLoading7 ? (
            <p>Cargando voluntariados...</p>
          ) : voluntariadosObtenidos.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {voluntariadosObtenidos.map((voluntariadoObtenido) => (
                <VolunteerCard
                  key={voluntariadoObtenido.uuid_voluntariado}
                  nombre_organizacion={voluntariadoObtenido.nombre_organizacion}
                  rol_organizacion={voluntariadoObtenido.rol_organizacion}
                  fecha_inicio_voluntariado={voluntariadoObtenido.fecha_inicio_voluntariado}
                  fecha_finalizacion_voluntariado={voluntariadoObtenido.fecha_finalizacion_voluntariado}
                  causa_voluntariado={voluntariadoObtenido.causa_voluntariado}
                  is_rolActual={voluntariadoObtenido.is_rolActual}
                  descripcion={voluntariadoObtenido.descripcion}
                />
              ))}
            </div>
          ) : (
            <p>No hay voluntariados obtenidos.</p>
          )}
        </>
      )}
      {section === 'reconocimientos' && (
        <>
          <h3 className="text-lg font-semibold">Reconocimientos:</h3>
          {isLoading8 ? (
            <p>Cargando reconocimientos...</p>
          ) : reconocimientosObtenidos.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {reconocimientosObtenidos.map((reconocimientosObtenido) => (
                <RecognitionCard
                  key={reconocimientosObtenido.uuid_reconocimiento}
                  nombre_reconocimiento={reconocimientosObtenido.nombre_reconocimiento}
                   fecha_recepcion_reconocimiento={reconocimientosObtenido.fecha_recepcion_reconocimiento} 
                  organizacion_emisora={reconocimientosObtenido.organizacion_emisora}
                  descripcion={reconocimientosObtenido.descripcion}
                  />
              ))}
            </div>
          ) : (
            <p>No hay reconocimientos registradas.</p>
          )}
        </>
      )}
        
          
          
          {items.map((item, index) => (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              {renderFields(section, item)}
              <div className="flex justify-between mt-4">
                <motion.button
                  whileHover={{ scale: 1.05, backgroundColor: "#10B981" }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleSaveItem(section, item.id)}
                  disabled={isLoadingButton}
                  className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg transition duration-300 disable:bg-green-300"
                >
                  <CheckCircle size={18} className="mr-2" />
                  {isLoadingButton ? 'Cargando...' : 'Guardar'}

                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05, backgroundColor: "#EF4444" }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleRemoveItem(section, item.id)}
                  className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg transition duration-300"
                >
                  <Trash2 size={18} className="mr-2" />
                  Cancelar
                </motion.button>
              </div>
            </motion.div>
          ))}
          
          
            <motion.button
              whileHover={{ scale: 1.05}}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleAddItem(section)}
              style={{backgroundColor:"#B2D1CC"}}
              className="flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg transition duration-300 text-lg font-semibold"
            >
              <Plus size={24} className="mr-2" />
              Agregar 
            </motion.button>

            
        
        </motion.div>
      );
    };


  const renderFields = (section, item) => {
    switch(section) {
      case 'educacion':
        return (
          <>
         <label className="block text-lg font-medium text-gray-700 mb-2">Grado Académico</label>
             <label className="block text-sh font-small text-gray-700 mb-2">Nombre de la Institucion</label>

            <input
              type="text"
              placeholder="Institución"
              value={item.institucion}
              onChange={(e) => handleInputChange(section, item.id, 'institucion', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Titulo academico:</label>

            <input
              type="text"
              placeholder="Título"
              value={item.titulo}
              onChange={(e) => handleInputChange(section, item.id, 'titulo', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Descripcion:</label>

            <textarea
                
                value={item.descripcion}
                onChange={(e) => handleInputChange(section, item.id, 'descripcion', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="4"
                placeholder="Describe lo que te proporciono tu educacion..."
                required
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Logros:</label>

            <textarea
                
                value={item.logros}
                onChange={(e) => handleInputChange(section, item.id, 'logros', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="4"
                placeholder="Describe tus logros..."
                required
            />

            <div className="flex space-x-4 mb-4"> {/* Contenedor flex para los campos */}
                <div className="w-1/3"> {/* Campo de fecha de inicio */}
                    <label className="block text-sh font-small text-gray-700 mb-2">Nivel de grado academico:</label>

                    <select
                    name="modalidadTrabajoPreferido"
                    value={item.gradoObtenido}
                    onChange={(e)=>handleInputChange(section, item.id, 'gradoObtenido', e.target.value)}
                    className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    >
                    <option value="">Seleccionar...</option>
                    {gradoObtenidoOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                    </select>
                </div>
                <div className="w-1/3"> {/* Campo de fecha de finalización */}
                <label className="block text-sh font-small text-gray-700 mb-2">GPA/Promedio:</label>

                    <input
                    type="number"
                    placeholder="GPA"
                    value={item.GPA}
                    onChange={(e) => handleInputChange(section, item.id, 'GPA', parseFloat(e.target.value) || 0)} // Asegurarse de convertir el valor a número
                    className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    step="0.01" // Permitir valores decimales

                    />
                </div>
                <div className="w-1/3"> {/* Campo de fecha de finalización */}
                <label className="block text-sh font-small text-gray-700 mb-2">Area de Estudio:</label>

                <input
                    type="text"
                    placeholder="Area de Estudio"
                    value={item.areaEstudio}
                    onChange={(e) => handleInputChange(section, item.id, 'areaEstudio', e.target.value)}
                    className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                </div>
            </div>
            
          
            <div className="flex space-x-4 mb-4"> {/* Contenedor flex para los campos */}
                <div className="w-1/2"> {/* Campo de fecha de inicio */}
                    <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Inicio:</label>
                    <input
                    type="date"
                    placeholder="Fecha de Inicio"
                    value={item.fechaInicio}
                    onChange={(e) => handleInputChange(section, item.id, 'fechaInicio', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="w-1/2"> {/* Campo de fecha de finalización */}
                    <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Finalización:</label>
                    <input
                    type="date"
                    placeholder="Fecha de finalización"
                    value={item.fechaFinalizacion}
                    onChange={(e) => handleInputChange(section, item.id, 'fechaFinalizacion', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            </div>
            

            <div className="flex items-center">

            <input
                  type="checkbox"
                  name="isEstudioActual"
                  checked={item.isEstudioActual}
                  onChange={(e)=>handleInputChange(section, item.id, 'isEstudioActual', e.target.value)}
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition duration-300"
            />
                <label className="ml-3 block text-lg text-gray-900">
                  Cursando actualmente
                </label>
            </div>
            
          </>
        );
      case 'experiencia':
        return (
          <>
            <label className="block text-lg font-medium text-gray-700 mb-2">Experiencia Laboral</label>
            <label className="block text-sh font-small text-gray-700 mb-2">Nombre de Empresa:</label>
            <input
              type="text"
              placeholder="Nombre Empresa..."
              value={item.nombreEmpresa}
              onChange={(e) => handleInputChange(section, item.id, 'nombreEmpresa', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Tipo de experiencia laboral:</label>

            <select
            name="tipoExperienciaLaboral"
            value={item.tipoExperienciaLaboral}
            onChange={(e)=>handleInputChange(section, item.id, 'tipoExperienciaLaboral', e.target.value)}
            className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            >
            <option value="">Seleccionar...</option>
            {tipoExperienciaLaboralOptions.map(option => (
                <option key={option} value={option}>{option}</option>
            ))}
            </select>
            <label className="block text-sh font-small text-gray-700 mb-2">Cargo Laboral:</label>
            <input
              type="text"
              placeholder="Cargo Laboral..."
              value={item.cargoLaboral}
              onChange={(e) => handleInputChange(section, item.id, 'cargoLaboral', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Inicio:</label>

                  <input
                    type="date"
                    placeholder="Fecha de Inicio"
                    value={item.fechaInicio}
                    onChange={(e) => handleInputChange(section, item.id, 'fechaInicio', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Finalizacion:</label>
                  
                  <input
                    type="date"
                    placeholder="Fecha de Fin"
                    value={item.fechaFinalizacion}
                    onChange={(e) => handleInputChange(section, item.id, 'fechaFinalizacion', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
            </div>
            <label className="block text-sh font-small text-gray-700 mb-2">Descripcion:</label>
            <textarea
                
                value={item.descripcion}
                onChange={(e) => handleInputChange(section, item.id, 'descripcion', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="4"
                placeholder="Describe tu experiencia laboral..."
                required
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Responsabilidades:</label>
            <textarea
                
                value={item.responsabilidades}
                onChange={(e) => handleInputChange(section, item.id, 'responsabilidades', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="4"
                placeholder="Describe tus responsabilidades..."
                required
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Logros:</label>
            <textarea
                
                value={item.logros}
                onChange={(e) => handleInputChange(section, item.id, 'logros', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="4"
                placeholder="Describe tus logros..."
                required
            />
            <label className="block text-sh font-small text-gray-700 mb-2">Ubicacion:</label>
            <textarea
                
                value={item.ubicacion}
                onChange={(e) => handleInputChange(section, item.id, 'ubicacion', e.target.value)}
                className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                rows="2"
                placeholder="Describe tus responsabilidades..."
                required
            />
            <div className="flex items-center">
            <input
                  type="checkbox"
                  name="isTrabajoActual"
                  checked={item.isTrabajoActual}
            
                  onChange={(e)=>handleInputChange(section, item.id, 'isTrabajoActual', e.target.value)}
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition duration-300"
            />
            <label className="ml-3 block text-lg text-gray-900">
                  Trabajo Actual
            </label>
            </div>
          </>
        );
      case 'certificaciones':
        return (
          <>
            <label className="block text-lg font-medium text-gray-700 mb-2">Certificacion</label>
            <label className="block text-sh font-small text-gray-700 mb-2">Nombre de Certificacion:</label>
            <input
              type="text"
              placeholder="Nombre Certificacion..."
              value={item.nombreCertificacion}
              onChange={(e) => handleInputChange(section, item.id, 'nombreCertificacion', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            <label className="block text-sh font-small text-gray-700 mb-2">Organizacion Emisora:</label>
            <input
              type="text"
              placeholder="Organizacion Emisora..."
              value={item.organizacionEmisora}
              onChange={(e) => handleInputChange(section, item.id, 'organizacionEmisora', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            
            <div className="flex space-x-4 mb-4">
            <div className="w-1/2">
              <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Emision:</label>

                <input
                  type="date"
                  placeholder="Fecha de Emisión"
                  value={item.fechaEmision}
                  onChange={(e) => handleInputChange(section, item.id, 'fechaEmision', e.target.value)}
                  className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Caducidad:</label>

                <input
                  type="date"
                  placeholder="Fecha de Caducidad"
                  value={item.fechaCaducidad}
                  onChange={(e) => handleInputChange(section, item.id, 'fechaCaducidad', e.target.value)}
                  className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <label className="block text-sh font-small text-gray-700 mb-2">Localizacion:</label>
            <input
              type="text"
              placeholder="Localizacion..."
              value={item.localizacion}
              onChange={(e) => handleInputChange(section, item.id, 'localizacion', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex space-x-4 mb-4">
            <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">ID Certificacion (opcional):</label>
              <input
                type="text"
                placeholder="Organizacion Emisora..."
                value={item.idCertificacion}
                onChange={(e) => handleInputChange(section, item.id, 'idCertificacion', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="w-1/2">

              <label className="block text-sm font-medium text-gray-700 mb-2">
                Subir Documento del Certificado:
              </label>
              <div className="flex items-center w-1/2 mb-4">
              <input
                type="file"
                id="document-upload"
                className="hidden"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
              />
              <label
                htmlFor="document-upload"
                className="flex-grow px-4 py-2 border rounded-lg cursor-pointer bg-gray-100 text-black-500 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {fileName || "Seleccionar Archivo"}
              </label>
              {fileName && (
                <span className="ml-2 text-gray-600 truncate max-w-xs">
                  {fileName}
                </span>
              )}
            </div>
            </div>
            </div>
            
          </>
        );
        case 'proyectos':
          return (
            <>
           <label className="block text-lg font-medium text-gray-700 mb-2">Proyecto</label>
               
            <div className="flex space-x-4 mb-4"> {/* Contenedor flex para los campos */}
            <div className="w-1/2">
               <label className="block text-sh font-small text-gray-700 mb-2">Nombre del Proyecto</label>
  
              <input
                type="text"
                placeholder="Nombre de Proyecto"
                value={item.nombre_proyecto}
                onChange={(e) => handleInputChange(section, item.id, 'nombre_proyecto', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              </div>
              <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Rol en el Proyecto</label>
  
              <input
                type="text"
                placeholder="Rol en el proyecto"
                value={item.rol_proyecto}
                onChange={(e) => handleInputChange(section, item.id, 'rol_proyecto', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              </div>
            </div>
              
              <label className="block text-sh font-small text-gray-700 mb-2">Descripcion:</label>
  
              <textarea
                  
                  value={item.descripcion_proyecto}
                  onChange={(e) => handleInputChange(section, item.id, 'descripcion_proyecto', e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Describe el proyecto..."
                  required
              />
              <label className="block text-sh font-small text-gray-700 mb-2">Tecnologias</label>
  
              <textarea
                  
                  value={item.tecnologias}
                  onChange={(e) => handleInputChange(section, item.id, 'tecnologias', e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Describe las tecnologias usadas..."
                  required
              />
              
            
              <div className="flex space-x-4 mb-4"> {/* Contenedor flex para los campos */}
                  <div className="w-1/2"> {/* Campo de fecha de inicio */}
                      <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Inicio:</label>
                      <input
                      type="date"
                      placeholder="Fecha de Inicio"
                      value={item.fecha_inicio_proyecto}
                      onChange={(e) => handleInputChange(section, item.id, 'fecha_inicio_proyecto', e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                  </div>
                  <div className="w-1/2"> {/* Campo de fecha de finalización */}
                      <label className="block text-sm font-small text-gray-700 mb-2">Fecha de Finalización:</label>
                      <input
                      type="date"
                      placeholder="Fecha de finalización"
                      value={item.fecha_final_proyecto}
                      onChange={(e) => handleInputChange(section, item.id, 'fecha_final_proyecto', e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                  </div>
              </div>
              
  
              <div className="flex items-center">
  
              <input
                    type="checkbox"
                    name="is_proyectoCurso"
                    checked={item.is_proyectoCurso}
                    onChange={(e)=>handleInputChange(section, item.id, 'is_proyectoCurso', e.target.value)}
                    className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition duration-300"
              />
                  <label className="ml-3 block text-lg text-gray-900">
                    Proyecto Actual
                  </label>
              </div>
              
            </>
          );
        case 'publicaciones':
        return (
          <>
         <label className="block text-lg font-medium text-gray-700 mb-2">Publicacion</label>
         <div className="flex space-x-4 mb-4">
         <div className="w-1/2">

             <label className="block text-sh font-small text-gray-700 mb-2">Titulo de la Publicacion</label>

            <input
              type="text"
              placeholder="Titulo de la publicacion..."
              value={item.titulo_publicacion}
              onChange={(e) => handleInputChange(section, item.id, 'titulo_publicacion', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </div>
            <div className="w-1/2">
            <label className="block text-sh font-small text-gray-700 mb-2">Autores</label>

            <input
              type="text"
              placeholder="Autores..."
              value={item.autores}
              onChange={(e) => handleInputChange(section, item.id, 'autores', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Tipo de Publicacion:</label>

                <select
                name="tipoPublicacion"
                value={item.tipo_publicacion}
                onChange={(e)=>handleInputChange(section, item.id, 'tipo_publicacion', e.target.value)}
                className="w-full px-4 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                >
                <option value="">Seleccionar...</option>
                {tipoPublicacionOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
                </select>
              </div>
              <div className="w-1/2"> {/* Campo de fecha de inicio */}
                    <label className="block text-sh font-small text-gray-700 mb-2">Fecha de Publicacion:</label>
                    <input
                    type="date"
                    placeholder="Fecha de Publicacion..."
                    value={item.fecha_publicacion}
                    onChange={(e) => handleInputChange(section, item.id, 'fecha_publicacion', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
              </div>
            
            
          
            
            
          </>
        );
        case 'lenguajes':
        return (
          <>
              <label className="block text-lg font-medium text-gray-700 mb-2">Idioma</label>
        

             <label className="block text-sh font-small text-gray-700 mb-2">Selecciona un idioma:</label>

             
             <div className="p-4">
                <LanguageSelector 
                    onSelectLanguage={handleLanguageSelect} 
                />
            </div>
                      


         
            
              <div className="p-4">
                <label className="block text-sm font-small text-gray-700 mb-2">
                  Selecciona tu nivel de idioma:
                </label>
                <LanguageLevelSelector 
                  onSelectLevel={handleLevelSelect} 
                />
              </div>
           
                        
          
            
            
          </>
        );
          case 'voluntariados':
          return (
          <>
          <label className="block text-lg font-medium text-gray-700 mb-2">Voluntariado</label>
          <div className="flex space-x-4 mb-4">
          <div className="w-1/2">

             <label className="block text-sh font-small text-gray-700 mb-2">Nombre de voluntariado</label>

            <input
              type="text"
              placeholder="Nombre de organizacion..."
              value={item.nombre_organizacion}
              onChange={(e) => handleInputChange(section, item.id, 'nombre_organizacion', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </div>
            <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Rol Organizacion</label>

              <input
                type="text"
                placeholder="Rol en la organizacion..."
                value={item.rol_organizacion}
                onChange={(e) => handleInputChange(section, item.id, 'rol_organizacion', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
    
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Fecha Inicio Voluntariado</label>

              <input
                type="date"
                placeholder="Fecha Inicio Voluntariado..."
                value={item.fecha_inicio_voluntariado}
                onChange={(e) => handleInputChange(section, item.id, 'fecha_inicio_voluntariado', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              </div>
              <div className="w-1/2"> {/* Campo de fecha de inicio */}
              <label className="block text-sh font-small text-gray-700 mb-2">Fecha Finalizacion Voluntariado</label>

              <input
                type="date"
                placeholder="Fecha Finalizacion Voluntariado..."
                value={item.fecha_finalizacion_voluntariado}
                onChange={(e) => handleInputChange(section, item.id, 'fecha_finalizacion_voluntariado', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              </div>
              </div>
              <label className="block text-sh font-small text-gray-700 mb-2">Causa Voluntariado</label>
  
              <textarea
                  
                  value={item.causa_voluntariado}
                  onChange={(e) => handleInputChange(section, item.id, 'causa_voluntariado', e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Describe la causa del voluntariado..."
                  required
              />
              <label className="block text-sh font-small text-gray-700 mb-2">Descripcion</label>
  
              <textarea
                  
                  value={item.descripcion}
                  onChange={(e) => handleInputChange(section, item.id, 'descripcion', e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Descripcion del voluntaridado..."
                  required
              />
               <div className="flex items-center">
  
              <input
                    type="checkbox"
                    name="is_rolActual"
                    checked={item.is_rolActual}
                    onChange={(e)=>handleInputChange(section, item.id, 'is_rolActual', e.target.value)}
                    className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition duration-300"
              />
                  <label className="ml-3 block text-lg text-gray-900">
                    Voluntariado Actual
                  </label>
              </div>
          </>
        );
        case 'reconocimientos':
          return (
          <>
          <label className="block text-lg font-medium text-gray-700 mb-2">Reconocimiento</label>
          <div className="flex space-x-4 mb-4">
          <div className="w-1/2">

             <label className="block text-sh font-small text-gray-700 mb-2">Nombre de Reconocimiento</label>

            <input
              type="text"
              placeholder="Nombre de reconocimiento..."
              value={item.nombre_reconocimiento}
              onChange={(e) => handleInputChange(section, item.id, 'nombre_reconocimiento', e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </div>
            <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Organizacion Emisora</label>

              <input
                type="text"
                placeholder="Organizacion Emisora..."
                value={item.organizacion_emisora}
                onChange={(e) => handleInputChange(section, item.id, 'organizacion_emisora', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
    
            </div>
            <div className="flex space-x-4 mb-4">
             <div className="w-1/2">

              <label className="block text-sh font-small text-gray-700 mb-2">Fecha Recepcion Reconocimiento</label>

              <input
                type="date"
                placeholder="Fecha Recepcion Reconocimiento..."
                value={item.fecha_recepcion_reconocimiento}
                onChange={(e) => handleInputChange(section, item.id, 'fecha_recepcion_reconocimiento', e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              </div>
              
              <div className="w-1/2">
              <label className="block text-sh font-small text-gray-700 mb-2">Descripcion de reconocimiento</label>
  
              <textarea
                  
                  value={item.descripcion}
                  onChange={(e) => handleInputChange(section, item.id, 'descripcion', e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Describe el reconocimiento..."
                  required
              />
              </div>

              </div>
              
              
          </>
        );
      // Añade casos similares para certificaciones, proyectos y publicaciones
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-2xl mt-8 mb-8 ">
      <div className="flex space-x-4 mb-4">
        <div className="w-1/2">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Detalles Profesionales</h2>
        </div>
        <div className="w-1/2 flex items-center justify-end">
        <motion.button
        className='flex items-center px-6 py-3 mr-4 rounded-lg transition duration-300  bg-orange-400 text-white'
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleNext}>
          
          Siguiente
          <MoveRight className="h-6 w-6 text-gray-700 ml-4"/> 
          
        </motion.button>
        </div>
      </div>
      
      <div className="flex mb-8 overflow-x-auto bg-white border border-gray-300 rounded-lg shadow-md p-4 space-x-4 scrollbar">
        {tabs.map((tab) => (
          <motion.button
          key={tab.id}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setActiveTab(tab.id)}
          style={{
            backgroundColor: activeTab === tab.id ? '#B2D1CC' : 'transparent'
          }}
          className={`flex items-center px-6 py-3 rounded-lg transition duration-300 ease-in-out transform ${
            activeTab === tab.id
              ? 'text-white shadow-lg'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
            <tab.icon size={24} className="mr-2" />
            {tab.label}
          </motion.button>
        ))}
      </div>

      <AnimatePresence mode="wait">
        {renderForm(activeTab)}
      </AnimatePresence>
      <AlertDialog open={showSuccessModal} onOpenChange={setShowSuccessModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Registro Guardado</AlertDialogTitle>
            <AlertDialogDescription>
              El registro se ha guardado exitosamente.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowSuccessModal(false)}>OK</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default DetallesProfesionales;