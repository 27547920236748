import React from 'react';
import { FaUserEdit, FaBuilding, FaShieldAlt, FaCog } from 'react-icons/fa';

const Sidebar = ({ onOptionSelect }) => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white p-6 space-y-6">
      <ul>
        <li className="mb-4">
          <button
            onClick={() => onOptionSelect('empresas')}
            className="flex items-center p-2 w-full hover:bg-gray-700 rounded-lg transition duration-200"
          >
            <FaBuilding className="mr-2" /> Mis Empresas
          </button>
        </li>
        <li className="mb-4">
          <button
            onClick={() => onOptionSelect('editarPerfil')}
            className="flex items-center p-2 w-full hover:bg-gray-700 rounded-lg transition duration-200"
          >
            <FaUserEdit className="mr-2" /> Editar Perfil
          </button>
        </li>
        <li className="mb-4">
          <button
            onClick={() => onOptionSelect('perfilProfesional')}
            className="flex items-center p-2 w-full hover:bg-gray-700 rounded-lg transition duration-200"
          >
            <FaShieldAlt className="mr-2" /> Perfil Profesional
          </button>
        </li>
        <li>
          <button
            onClick={() => onOptionSelect('configuracion')}
            className="flex items-center p-2 w-full hover:bg-gray-700 rounded-lg transition duration-200"
          >
            <FaCog className="mr-2" /> Configuración
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
