import React, { useState } from 'react';

const levels = [
  { label: 'Básico', value: 'Basico' },
  { label: 'Intermedio', value: 'Intermedio' },
  { label: 'Avanzado', value: 'Avanzado' },
  { label: 'Nativo', value: 'Nativo' },
];

const LanguageLevelSelector = ({ onSelectLevel }) => {
  const [selectedLevel, setSelectedLevel] = useState(levels[0]);

  const handleLevelChange = (level) => {
    setSelectedLevel(level);
    onSelectLevel(level.value);
  };

  return (
    <div className="w-full p-6">
      <div className="flex items-center space-x-4">
        {levels.map((level, index) => (
          <div
            key={level.value}
            onClick={() => handleLevelChange(level)}
            className={`flex flex-col items-center cursor-pointer transition duration-300 ${
              selectedLevel.value === level.value ? 'text-blue-600' : 'text-gray-400'
            }`}
          >
            {/* Indicador visual circular para el nivel */}
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-full border-2 ${
                selectedLevel.value === level.value ? 'border-blue-600 bg-blue-100' : 'border-gray-300'
              }`}
            >
              {index + 1}
            </div>
            {/* Nombre del nivel */}
            <span className="mt-2 text-sm">{level.label}</span>
          </div>
        ))}
      </div>

      {/* Barra de progreso que refleja el nivel seleccionado */}
      <div className="w-full bg-gray-200 h-2 rounded-full mt-6">
        <div
          className={`h-full rounded-full transition-all duration-300 bg-blue-600`}
          style={{
            width: `${
              ((levels.findIndex((level) => level.value === selectedLevel.value) + 1) / levels.length) * 100
            }%`,
          }}
        />
      </div>
    </div>
  );
};

export default LanguageLevelSelector;
