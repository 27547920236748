import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaBuilding, FaCalendar, FaCheckCircle, FaTimesCircle, FaQuestionCircle, FaTrash } from 'react-icons/fa'; // Importa el ícono de la papelera
import baseURL from "../../features/baseURL.js";
import { toast } from 'react-toastify';
import axios from 'axios';

const PostulacionCard = ({ postulacion, onDelete }) => { // Añadido `onDelete` como prop
  const [ofertaLaboral, setOfertaLaboral] = useState(null);

  const getStatusIcon = () => {
    switch (postulacion.estado) {
      case 'Aceptada':
        return <FaCheckCircle className="text-green-500" />;
      case 'Rechazada':
        return <FaTimesCircle className="text-red-500" />;
      case 'Revision':
        return <FaQuestionCircle className="text-yellow-500" />;
      default:
        return <FaQuestionCircle className="text-gray-500" />;
    }
  };

  // Obtener la oferta laboral cuando el componente se monte
  useEffect(() => {
    const getOfertaLaboral = async () => {
      try {
        const response = await axios.get(`${baseURL.BASE_URL}/oportunidadesLaborales/${postulacion.id_ofertaLaboral}`);
        setOfertaLaboral(response.data);
      } catch (error) {
        console.error('Error fetching ofertas laborales:', error);
        toast.error('Error al cargar la oferta laboral');
        setOfertaLaboral(null);
      }
    };

    getOfertaLaboral();
  }, [postulacion.id_ofertaLaboral]);

  const handleDeletePostulacion = async () => {
    try {
      const response = await axios.delete(`${baseURL.BASE_URL}/postulacionesLaborales/${postulacion.uuid_postulacionlaboral}`);
      console.log(response.status)
      if (response.status === 200) {
        toast.success('Postulación eliminada con éxito');
        onDelete(postulacion.uuid_postulacionlaboral); // Llama a la función onDelete pasada como prop
      }
    } catch (error) {
      console.error('Error al eliminar la postulación:', error);
      toast.error('Error al eliminar la postulación');
    }
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="bg-white shadow-md rounded-lg p-4 flex justify-between items-center space-x-4"
    >
      <div className="flex items-center space-x-4">
        {ofertaLaboral ? ( // Verifica si la oferta laboral está disponible
          <>
            {/* Imagen de la empresa */}
            <img 
              src={`${baseURL.BASE_URL}${ofertaLaboral.empresa?.url_logo}` || 'ruta/a/imagen/default.png'} 
              alt={ofertaLaboral.empresa?.nombre_legal || 'Empresa'} 
              className="w-8 h-8 rounded-full" // Ajusta el tamaño y la forma según sea necesario
            />
            <div>
              <h3 className="text-lg font-semibold text-gray-700">{ofertaLaboral.titulo}</h3>
              <p className="text-sm text-gray-500">{ofertaLaboral.empresa?.nombre_legal || 'Nombre de la empresa no disponible'}</p>
            </div>
          </>
        ) : (
          <p className="text-sm text-gray-500">Cargando oferta laboral...</p>
        )}
      </div>

      <div className="flex items-center space-x-4">
        {/* Fecha de Postulación */}
        <div className="flex items-center space-x-1">
          <FaCalendar className="text-gray-500" />
          <p className="text-sm text-gray-500">
            <strong>Fecha de Postulación:</strong> {new Date(postulacion.fecha_postulacion).toLocaleDateString()}
          </p>
        </div>

        {/* Fecha de Cierre, solo si la oferta laboral está cargada */}
        {ofertaLaboral && (
          <div className="flex items-center space-x-1">
            <FaCalendar className="text-gray-500" />
            <p className="text-sm text-gray-500">
              <strong>Fecha de Cierre:</strong> {new Date(ofertaLaboral.fecha_cierre).toLocaleDateString()}
            </p>
          </div>
        )}
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={handleDeletePostulacion}
          className="text-red-500 hover:underline flex items-center space-x-1"
        >
          <FaTrash />
          <span>Borrar Postulación</span>
        </button>
      </div>

      <div className="flex items-center space-x-2">
        {getStatusIcon()}
        <span className="text-sm text-gray-600">{postulacion.estado}</span>
      </div>
    </motion.div>
  );
};

export default PostulacionCard;
