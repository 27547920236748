import React, { useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select"; // Asegúrate de instalar react-select
import fondoBolsa from '../../Assets/images/fondoBolsa.jpg';
import logoFesa from "../../Assets/images/logoFesa.png";
import logoReconectateWhite from "../../Assets/images/logoReconectate.webp";
import axios from "axios"
import baseURL from "../../features/baseURL.js"
import SweetModal from "./SweetModal.jsx"
import CountrySelect from './CountrySelect'; // Ajusta la ruta según tu estructura de proyecto
import SuccessModal from './SuccessModalRegister.jsx'
const startYear = 1960; // Año de inicio
const currentYear = new Date().getFullYear(); // Año actual
const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
  const year = startYear + index;
  return { value: year, label: year };
});

const RegistrarseLayout = () => {
    const genderOptions = [
        { value: 1, label: 'Masculino' },
        { value: 2, label: 'Femenino' }      ];
  const [nombre, setNombre] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [segundoNombre, setSegundoNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [nombre_usuario,setNombreUsuario]=useState("")
  const [promocion, setPromocion] = useState(new Date().getFullYear());
  const [apodo, setApodo] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCiudades, setIsCiudades] = useState(false);
  const [isLoading,setIsLoading]=useState(false)
  const [nombresObtenidos,setNombresObtenidos]=useState("")
  const [apellidosObtenidos,setApellidosObtenidos]=useState("")
  const [idExalumno,setIdExalumno]=useState(0)
  const [promoExalumno,setPromoExalumno]=useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [genero, setGenero] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountry2, setSelectedCountry2] = useState(null);
  const [ciudadResidencia,setCiudadResidencia]=useState("")
  const [direccionReferencia,setDireccionReferencia]=useState()
  const [ciudadNacimiento, setCiudadNacimiento] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [referencias, setReferencias] = useState("");
  const currentYear = new Date().getFullYear();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisible2, setModalVisible2] = useState(false);
  const [isModalesVisible,setModalesVisible]=useState(false);
  // Función para manejar la visibilidad de la contraseña
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleOkClick = () => {
    setModalVisible(false);
    setIsSubmitted(true);
  };
  // Lógica para manejar la transición entre formularios
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Valida los campos del primer formulario
    if (!nombre || !apellidoPaterno || !apellidoMaterno || !promocion) {
      Swal.fire({
        title: 'Error',
        text: 'Por favor, completa todos los campos obligatorios.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }
    try {
        const params = new URLSearchParams({
            nombre,
            segundoNombre,
            apellidoPaterno,
            apellidoMaterno,
            promocion: promocion // Promoción debe ser el valor del objeto de react-select
          });
          const response = await axios.get(`${baseURL.BASE_URL}/exalumnos/filtradoNAP`, { params });
          if (response.data && Object.keys(response.data).length > 0)
          {
            console.log(response.data[0])
            setIdExalumno(response.data[0].id)
            setNombresObtenidos(response.data[0].nombres_exalumno)
            setApellidosObtenidos(response.data[0].apellidos_exalumno)
            setPromoExalumno(response.data[0].promocion_exalumno);
            setModalVisible(true); // Abre el modal si el exalumno existe


          }
      
    } catch (error) {
        if (error.response) 
            {
            if (error.response.status === 404) {
              setModalVisible2(true); // Mostrar el modal de error si la respuesta es 404
            } else {
              console.error('Error del servidor:', error.response.status, error.response.data);
            }
          } else if (error.request) {
            console.error('No se recibió respuesta del servidor:', error.request);
          } else {
            console.error('Error en la configuración de la solicitud:', error.message);
          }
    }
    
    
  };
  

  // Lógica para manejar el paso de ciudades
  const handleCiudades = () => {
    if (!email || !fechaNacimiento || !password || !confPassword || !genero) {
      Swal.fire({
        title: 'Error',
        text: 'Por favor, completa todos los campos obligatorios.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (password !== confPassword) {
      Swal.fire({
        title: 'Error',
        text: 'Las contraseñas no coinciden.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }

    setIsCiudades(true); // Transiciona a la sección de ciudades
  };
  const handleSubmitDatos=async()=>{

    console.log(ciudadNacimiento+" "+selectedCountry+" "+ciudadResidencia+" "+selectedCountry2+" "+telefono+" "+direccionReferencia)
    if (!ciudadNacimiento|| !selectedCountry || !ciudadResidencia || !selectedCountry2 || !telefono || !direccionReferencia) {
        Swal.fire({
          title: 'Error',
          text: 'Por favor, completa todos los campos obligatorios.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
        setIsLoading(false);

        return; // Detiene la ejecución si hay campos vacíos
      }else

      {
        setIsLoading(true)
        const datosEnviar={
            id_exalumno:idExalumno,
            url_foto_perfil_usuario:"SinFoto",
            fecha_nacimiento_usuario:fechaNacimiento,
            nombre_usuario:nombre_usuario,
            telefono_usuario:telefono,
            pais_nacimiento_usuario:selectedCountry,
            ciudad_nacimiento_usuario:ciudadNacimiento,
            pais_residencia_usuario:selectedCountry2,
            ciudad_residencia_usuario:ciudadResidencia,
            direccion_referencia_usuario:direccionReferencia,
            id_genero:genero,
            password_usuario:password,
            email_usuario:email
          }
          console.log(datosEnviar)

          try {
            const response = await axios.post(`${baseURL.BASE_URL}/usuarios/add_usuario`, datosEnviar, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });           
              console.log(response.data);
              setModalesVisible(true)

          } catch (error) {
            console.error("Error del servidor:", error.response ? error.response.data : error.message);

          }finally{
            setIsLoading(false);

          }
      }
}
const handleCloseModales=()=>{
  setModalesVisible(false);
}

  return (
    <div className="min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${fondoBolsa})` }}
      >
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
            <img src={logoFesa} alt="LogoFesa" className="h-16" />
            </div>

        <div className="text-center mb-8">
          <h3 className="text-2xl font-bold text-gray-800">Encuentra la oportunidad que te impulsa, para dejar tu huella!</h3>
        </div>

        {/* Formulario de registro */}
        <div className="bg-white shadow-xl rounded-lg p-8 max-w-4xl mx-auto">
        <div className="flex justify-center mb-8">
            <img src={logoReconectateWhite} alt="LogoReconectateWhite" className="h-20" />
          </div>

          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Campos del formulario inicial */}
                <div>
                  <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">Primer Nombre:</label>
                  <input
                    type="text"
                    id="nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    className="mt-1 block w-full h-[40px] rounded-md border-gray-300 shadow-sm pl-[10px]"
                    placeholder="Ingrese su nombre..."

                    required
                  />
                </div>
                <div>
                  <label htmlFor="segundoNombre" className="block text-sm font-medium text-gray-700">Segundo Nombre:</label>
                  <input
                    type="text"
                    id="segundoNombre"
                    value={segundoNombre}
                    onChange={(e) => setSegundoNombre(e.target.value)}
                    className="mt-1 block w-full  h-[40px] rounded-md border-gray-300 shadow-sm pl-[10px]"
                    placeholder="Ingrese su segundo nombre..."

                  />
                </div>
                <div>
                  <label htmlFor="apellidoPaterno" className="block text-sm font-medium text-gray-700">Apellido Paterno:</label>
                  <input
                    type="text"
                    id="apellidoPaterno"
                    value={apellidoPaterno}
                    onChange={(e) => setApellidoPaterno(e.target.value)}
                    className="mt-1 block w-full  h-[40px] rounded-md border-gray-300 shadow-sm pl-[10px]"
                    placeholder="Ingrese su apellido paterno.."

                    required
                  />
                </div>

                <div>
                  <label htmlFor="apellidoMaterno" className="block text-sm font-medium text-gray-700">Apellido Materno:</label>
                  <input
                    type="text"
                    id="apellidoMaterno"
                    value={apellidoMaterno}
                    onChange={(e) => setApellidoMaterno(e.target.value)}
                    className="mt-1 block w-full h-[40px] rounded-md border-gray-300 shadow-sm pl-[10px]"
                    placeholder="Ingrese su apellido materno..."

                    required
                  />
                </div>
                <div>
                  <label htmlFor="nombreUsuario" className="block text-sm font-medium text-gray-700">Apodo:</label>
                  <input
                    type="text"
                    id="nombreUsuario"
                    value={nombre_usuario}
                    onChange={(e) => setNombreUsuario(e.target.value)}
                    className="mt-1 block w-full  h-[40px] rounded-md border-gray-300 shadow-sm pl-[10px]"
                    placeholder="Ingrese su nombre de usuario..."

                    required
                  />
                </div>
                <div>
                  <label htmlFor="promocion" className="block text-sm font-medium text-gray-700">Promoción:</label>
                  <Select
                    id="promocion"
                    options={years}
                    value={years.find(year => year.value === promocion)}
                    onChange={(selectedOption) => setPromocion(selectedOption ? selectedOption.value : "")}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700"
              >
                Siguiente
              </button>
            </form>
          ) : isCiudades ? (
            <form className="space-y-6">
              {/* Formulario de ciudades */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    
                  <label htmlFor="paisNacimiento" className="block text-sm font-medium text-gray-700 ">Pais Nacimiento:</label>
                  <CountrySelect value={selectedCountry} onChange={setSelectedCountry} />
                  
                </div>
                
                <div>
                  <label htmlFor="ciudadNacimiento" className="block text-sm font-medium text-gray-700">Ciudad Nacimiento:</label>
                  <input
                    type="text"
                    id="ciudadNacimiento"
                    value={ciudadNacimiento}
                    onChange={(e) => setCiudadNacimiento(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pl-[10px] h-[40px]"
                    required
                    placeholder="Ingrese la ciudad de nacimiento.."
                  />
                </div>
                <div>
                  <label htmlFor="paisResidencia" className="block text-sm font-medium text-gray-700">Pais Residencia:</label>
                  <CountrySelect value={selectedCountry2} onChange={setSelectedCountry2} />
                </div>
                <div>
                  <label htmlFor="ciudadResidencia" className="block text-sm font-medium text-gray-700">Ciudad Residencia:</label>
                  <input
                    type="text"
                    id="ciudadResidencia"
                    value={ciudadResidencia}
                    onChange={(e) => setCiudadResidencia(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pl-[10px] h-[40px]"
                    required
                    placeholder="Ingrese la ciudad de residencia.."
                  />
                </div>
                <div>
                  <label htmlFor="direccionRefencia" className="block text-sm font-medium text-gray-700">Direccion de Referencia:</label>
                  <input
                    type="text"
                    id="direccionReferencia"
                    value={direccionReferencia}
                    onChange={(e) => setDireccionReferencia(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pl-[10px] h-[40px]"
                    required
                    placeholder="Ingrese su direccion de referencia..."
                  />
                </div>
                <div>
                  <label htmlFor="telefono" className="block text-sm font-medium text-gray-700">Telefono:</label>
                  <input
                    type="text"
                    id="telefono"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pl-[10px] h-[40px]"
                    required
                    placeholder="Ingrese su telefono..."
                  />
                </div>
              </div>
              <button
                type="button"
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 disabled:bg-blue-400"
                onClick={handleSubmitDatos}
                disabled={isLoading}
              >

              {isLoading ? 'Cargando...' : 'Registrar'}

              </button>
              <SuccessModal isVisible={isModalesVisible} onClose={handleCloseModales} />
              </form>
          ) : (
            <form className="space-y-6">
              {/* Formulario de credenciales (email, password, etc.) */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Correo:</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm h-[40px] pl-[10px]"
                    placeholder="Ingrese su correo.."

                    required
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña:</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm h-[40px] pl-[10px]"
                    placeholder="Contraseña"

                    required
                  />
                </div>
                <div>
                  <label htmlFor="confPassword" className="block text-sm font-medium text-gray-700">Confirmar Contraseña:</label>
                  <input
                    type="password"
                    id="confPassword"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm h-[40px] pl-[10px]"
                    placeholder="Confirmar Contraseña"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="fechaNacimiento" className="block text-sm font-medium text-gray-700">Fecha de Nacimiento:</label>
                  <input
                    type="date"
                    id="fechaNacimiento"
                    value={fechaNacimiento}
                    onChange={(e) => setFechaNacimiento(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm h-[40px] pl-[10px]"

                    required
                  />
                </div>
                <div>
                  <label htmlFor="genero" className="block text-sm font-medium text-gray-700">Sexo:</label>
                  <Select
                        id="genero"
                        options={genderOptions}
                        value={genderOptions.find(option => option.value === genero)}
                        onChange={(selectedOption) => setGenero(selectedOption ? selectedOption.value : "")}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    />
                </div>
                {/* Otros campos de credenciales */}
              </div>
              <button
                type="button"
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700"
                onClick={handleCiudades}
              >
                Siguiente
              </button>
            </form>
          )}
          {isModalVisible && (
        <SweetModal
        title="¡Bienvenido!"
        text={`  ${nombresObtenidos}   ${apellidosObtenidos} Promoción: ${promoExalumno}`}
        icon="success"
        confirmButtonText="Ok"
        onClose={handleOkClick}
      />
        
      )}
      {isModalVisible2 && (
        <SweetModal
        title="¡No se encontró exalumno!"
        text="No se encontraron datos que coincidan con la búsqueda."
        icon="error"
        confirmButtonText="Ok"
        onClose={() => setModalVisible2(false)}

      />
      )}
        </div>
        
      </div>
      
    </div>
  );
};

export default RegistrarseLayout;
