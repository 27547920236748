import React from 'react';
import { Award, Calendar, Building2 } from 'lucide-react';

const RecognitionCard = ({ nombre_reconocimiento, organizacion_emisora, fecha_recepcion_reconocimiento, descripcion }) => {
  return (
    <div className="bg-gradient-to-br from-green-50 to-teal-100 border-2 border-teal-200 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1">
      <div className="flex items-start mb-4">
        <div className="w-12 h-12 bg-teal-500 rounded-full flex items-center justify-center mr-4 shadow-md">
          <Award className="text-white" size={24} />
        </div>
        <div>
          <h2 className="font-bold text-2xl text-teal-800">{nombre_reconocimiento}</h2>
          <p className="text-teal-600 text-lg">{organizacion_emisora}</p>
        </div>
      </div>

      <div className="space-y-2 mb-4">
        <div className="flex items-center text-gray-700">
          <Calendar className="mr-2" size={18} />
          <span>Recibido el: {fecha_recepcion_reconocimiento}</span>
        </div>
      </div>
      
      {descripcion && (
        <div className="text-gray-700 mt-4">
          <p>{descripcion}</p>
        </div>
      )}

      <div className="flex justify-between items-center mt-4 pt-4 border-t border-teal-200">
        <span className="text-sm text-gray-500">Organización: {organizacion_emisora}</span>
      </div>
    </div>
  );
};

export default RecognitionCard;
