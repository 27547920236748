import React from 'react';

const JobsComponent = () => {
  const categories = ['Tecnología', 'Finanzas', 'Salud', 'Educación', 'Investigación', 'Ciencia', 'Producción', 'Industria', 'Deporte', 'Diseño', 'Gerencia'];

  return (
    <section id="jobs" className="text-center mb-16">
      <h2 className="text-2xl font-semibold mb-6">Categorías de trabajo destacadas</h2>
      <div className="flex flex-wrap justify-center gap-4 mb-6">
        {categories.map((category, index) => (
          <div key={index} className="bg-white p-4 shadow-lg rounded-lg text-gray-700">
            {category}
          </div>
        ))}
      </div>
      <a
        href="/jobs"
        className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors"
      >
        Encuentra más trabajos
      </a>
    </section>
  );
};

export default JobsComponent;