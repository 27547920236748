import React, { useState, useEffect } from 'react';
import { FaSearch, FaBuilding, FaUsers } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Tooltip from '../UI/Tooltip.jsx';
import HighlightComponent from './Highlight.jsx';
import JobsComponent from './JobsC';
import { Link } from 'react-router-dom';
import logoBolsa from '../../Assets/images/logoReconectate.webp';
import fondoBolsa from '../../Assets/images/fondoBolsa.jpg';
import logoFesa from '../../Assets/images/logoFesa.png';

const LandingPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [tutorialStep, setTutorialStep] = useState(0);
  const images = [fondoBolsa];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    if (tutorialStep < 4) {
      const timer = setTimeout(() => {
        setTutorialStep(prevStep => prevStep + 1);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [tutorialStep]);

  const tutorialSteps = [
    { selector: '#explore-more', content: 'Comienza tu viaje aquí' },
    { selector: '#features', content: 'Descubre por qué somos la mejor opción' },
    { selector: '#jobs', content: 'Explora nuestras categorías de trabajo' },
    { selector: '#cta', content: 'Listo para comenzar? Regístrate aquí' },
  ];

  return (
    <div className="relative min-h-screen bg-gray-100">
      <section
        id="home"
        className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      >
        <div className="absolute top-4 left-4">
          <img src={logoFesa} alt="LogoFesa" className="w-20 h-auto" />
        </div>
        <div className="relative flex flex-col items-center justify-center max-w-md p-6 mx-auto bg-white shadow-lg rounded-lg text-center">
          <img src={logoBolsa} alt="Your Logo" className="w-40 h-auto mb-6" />
          <h1 className="text-3xl font-bold mb-4">Bienvenido a Reconéctate</h1>
          <p className="mb-6 text-gray-700">
            "En FESA, cada etapa de tu vida es una nueva oportunidad. Ya sea que estés dando tus primeros pasos en el mundo laboral o busques una nueva dirección con tu experiencia, aquí encontrarás el camino perfecto para tu carrera. ¡Tu próxima gran oportunidad te está esperando!"
          </p>
          <HighlightComponent active={tutorialStep === 0} content={tutorialSteps[0].content}>
            <Link
            id="explore-more"
            to="#features"
            className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Explorar Más
          </Link>
          </HighlightComponent>
        </div>
      </section>

      <main className="px-4 py-8">
        <HighlightComponent active={tutorialStep === 1} content={tutorialSteps[1].content}>
          <section id="features" className="text-center mb-16">
            <h2 className="text-2xl font-semibold mb-8">¿Por qué escoger Reconéctate?</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg">
                <FaSearch className="text-4xl text-blue-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Ofertas Laborales</h3>
                <p className="text-gray-600">Accede a miles de oportunidades laborales en varias industrias y empresas.</p>
              </div>
              <div className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg">
                <FaBuilding className="text-4xl text-blue-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Empresas de renombre</h3>
                <p className="text-gray-600">Conéctate con empresas líderes que buscan profesionales talentosos.</p>
              </div>
              <div className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg">
                <FaUsers className="text-4xl text-blue-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Asciende en tu carrera</h3>
                <p className="text-gray-600">Obtén información y herramientas para avanzar en tu recorrido profesional.</p>
              </div>
            </div>
          </section>
        </HighlightComponent>

        <HighlightComponent active={tutorialStep === 2} content={tutorialSteps[2].content}>
          <JobsComponent />
        </HighlightComponent>

        <HighlightComponent active={tutorialStep === 3} content={tutorialSteps[3].content}>
          <section id="cta" className="text-center bg-blue-100 py-8 mb-16 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">¿Listo para encontrar el trabajo de tus sueños?</h2>
            <p className="mb-6">Únete a miles de profesionales que han encontrado el éxito a través de Reconéctate.</p>
            <Link
              to="/registrarse"
              className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Iniciar
            </Link>

          </section>
        </HighlightComponent>
      </main>

      <footer className="bg-gray-800 text-white text-center py-4">
        <p>&copy; 2024 FESA. TODOS LOS DERECHOS RESERVADOS.</p>
      </footer>
    </div>
  );
};

export default LandingPage;