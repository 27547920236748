import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import { useNavigate } from 'react-router-dom'
import Services from '../../Components/LandingPage/Services.jsx'
const ServicesPage = () => {
  return (
    <div>
    <Layout>
      <Services/>
    </Layout>
    </div>
  )
}

export default ServicesPage
