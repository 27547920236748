import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaMapMarkerAlt, FaMoneyBillWave, FaHeart } from 'react-icons/fa';

const OfertaLaboralCard = ({ opportunity, onSelect }) => {
    console.log(opportunity)
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ scale: 1.03 }}
      transition={{ duration: 0.3 }}
      className="bg-white rounded-lg shadow-lg p-6 cursor-pointer hover:shadow-xl transition-all duration-300"
      onClick={() => onSelect(opportunity)}
    >
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-xl font-bold text-orange-500 font-STHeiti">{opportunity.titulo}</h2>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaHeart className="text-gray-400 hover:text-red-500 transition-colors duration-300" />
        </motion.div>
      </div>
      <p className="text-black-300 font-semibold mb-4 font-STHeiti">{opportunity.empresa.nombre_legal}</p>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaMapMarkerAlt className="mr-2 text-orange-500" />
        <span>{opportunity.modalidad}</span>
      </div>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaMoneyBillWave className="mr-2 text-orange-500" />
        <span>${opportunity.salario_min} - ${opportunity.salario_max} USD</span>
      </div>
      <div className="flex items-center text-sm text-gray-600 font-STHeiti">
        <FaBriefcase className="mr-2 text-orange-500" />
        <span>{opportunity.tipo_contrato}</span>
      </div>
    </motion.div>
  );
};

export default OfertaLaboralCard;
