import React from 'react';
import Swal from 'sweetalert2';

const AlertModal = ({ title, text, icon, confirmButtonText, onClose }) => {
  const showAlert = () => {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      customClass: {
        container: 'fixed inset-0 flex items-center justify-center z-50', // Centra el modal y asegura que esté en la parte superior con z-index alto
        popup: 'bg-white rounded-lg shadow-lg p-6', // Estilos del popup
        title: 'text-xl font-bold text-gray-900', // Estilos del título
        content: 'text-base text-gray-700', // Estilos del contenido
        confirmButton: 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500 focus:ring-offset-blue-200 px-4 py-2 rounded', // Estilos del botón de confirmación
      },
      backdrop: `rgba(0,0,0,0.5)`, // Fondo semi-transparente detrás del modal
    }).then((result) => {
      if (result.isConfirmed && onClose) {
        onClose();
      }
    });
  };

  React.useEffect(() => {
    showAlert();
  }, []);

  return null;
};

export default AlertModal;
