import React,{useEffect} from 'react'
import LayoutMainFeed from "./LayoutMainFeed.jsx"
import { useNavigate } from 'react-router-dom'
import MainRecomendacionesCV from "../../Components/MainFeed/MainRecomendacionesCV.jsx"
import ReConectateBanner from '../../Components/NavBarMain/BannerPrincipal.jsx'
const LayoutMain= () => {
    const navigate=useNavigate();
    
    
  return (
   <LayoutMainFeed>
      <ReConectateBanner/>
   </LayoutMainFeed>
  )
}

export default LayoutMain
