import React,{useEffect} from 'react'
import MainFeedNavBar from "../MainFeed/MainFeedNavBar.jsx"
import { useNavigate } from 'react-router-dom'
import EmpresaFicha from "../../Components/Empresa/EmpresaFicha.jsx"
const EditEmpresaPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <MainFeedNavBar>
       <EmpresaFicha/>
   </MainFeedNavBar>
  )
}

export default EditEmpresaPage
