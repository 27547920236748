import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoBolsa from "../../Assets/images/fraseReconectate.webp";
import logoFesa from "../../Assets/images/logoFesa.png";

const Navbar = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  return (
    <nav className="fixed top-0 left-0 w-full h-16 bg-white shadow-md flex items-center justify-between px-6 z-50">
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          <img src={logoBolsa} alt="Your Logo" className="max-w-xs h-auto" />
        </Link>
        <button
          className={`ml-4 px-3 py-2 border rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isActive ? 'bg-gray-100' : ''}`}
          aria-label="menu"
          aria-expanded={isActive}
          onClick={toggleNavbar}
        >
          <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
          <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
          <span className="block w-6 h-0.5 bg-gray-800"></span>
        </button>
      </div>

      <div className={`flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4 lg:ml-4 ${isActive ? 'block' : 'hidden'}`}>
        <Link
          className={`text-lg font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out ${location.pathname === '/' ? 'bg-gray-800 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
          to="/"
        >
          Inicio
        </Link>
        <Link
          className={`text-lg font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out ${location.pathname === '/nosotros' ? 'bg-gray-800 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
          to="/nosotros"
        >
          Sobre Nosotros
        </Link>
        <Link
          className={`text-lg font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out ${location.pathname === '/servicios' ? 'bg-gray-800 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
          to="/servicios"
        >
          Servicios
        </Link>
        <Link
          className={`text-lg font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out ${location.pathname === '/contacto' ? 'bg-gray-800 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
          to="/contacto"
        >
          Contacto
        </Link>
      </div>

      <div className="flex items-center space-x-4">
        <img src={logoFesa} alt="logoFesa" className="h-16" />
        <div className="hidden lg:flex items-center space-x-4">
          <Link className="text-gray-700 bg-gray-200 py-2 px-4 rounded-lg hover:bg-gray-300 transition duration-300" to="/login">
            Iniciar Sesión
          </Link>
          <Link className="text-gray-700 bg-gray-200 py-2 px-4 rounded-lg hover:bg-gray-300 transition duration-300" to="/registrarse">
            Registrarse
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
