import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Briefcase, MapPin, Clock, Globe, FileText, Link, Upload, ChevronRight, ChevronLeft, CheckCircle } from 'lucide-react';
import fondoBolsa from '../../Assets/images/fondoBolsa.jpg';
import logoFesa from "../../Assets/images/logoFesa.png";
import logoReconectateWhite from "../../Assets/images/logoReconectate.webp";
import axios from 'axios';
import baseURL from "../../features/baseURL.js"
import { Document, Page, pdfjs } from 'react-pdf';
import { useNavigate} from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;
const MainPerfilProfesional = () => {
  const [step, setStep] = useState(1);
  const [resumen,setResumen]=useState("")
  const [posicionActual,setPosicionActual]=useState("")
  const [yearsExperiencia,setYearsExperiencia]=useState(0)
  const [sitioTrabajoPreferido,setTrabajoPreferido]=useState("")
  const [modalidadTrabajoPreferido,setModalidadTrabajoPreferido]=useState("")
  const [isAbiertoRelocalizacion,setIsAbiertoRelocalizacion]=useState(false)
  const [documentoCV,setDocumentoCV]=useState("")
  const [documentoPortafolio,setDocumentoPortafolio]=useState("")
  const [numPagesCv, setNumPagesCv] = useState(null);
  const [numPagesPortafolio, setNumPagesPortafolio] = useState(null);
  const [isLoading,setIsloading]=useState(false)
  const navigate=useNavigate();


  // const handleInputChange = (e) => {
  //   const { name, value, type, checked, files } = e.target;
  //   setFormData(prev => ({
  //     ...prev,
  //     [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value
  //   }));
  // };
  const handleSubmit = async (e) => {
    setIsloading(true)
    e.preventDefault();
    try {
        const datos = new FormData();
        datos.append('resumen', resumen);
        datos.append('posicion_actual', posicionActual);
        datos.append('years_experiencia', yearsExperiencia);
        datos.append('sitio_trabajo_preferido', sitioTrabajoPreferido);
        datos.append('modalidad_trabajo_preferido', modalidadTrabajoPreferido);
        datos.append('is_abierto_relocalizacion', isAbiertoRelocalizacion);
        
        if (documentoCV) {
            datos.append('url_documento_cv', documentoCV);
        }
        if (documentoPortafolio) {
            datos.append('url_portafolio', documentoPortafolio);
        }
        console.log(datos)
        const dataObject = Object.fromEntries(datos.entries());
        console.log("Datos enviados:", dataObject);
        // Enviar el objeto FormData
        const response = await axios.post(`${baseURL.BASE_URL}/perfilesProfesionales/add_perfilProfesional`, datos, {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        });
        

        // Manejar la respuesta
        console.log('Respuesta del servidor:', response.data);
        
        navigate('/perfilProfesional/detalles_profesionales')
    } catch (error) {
        console.log('Error al enviar los datos:', error.response);
        setIsloading(false)

    }finally{
      setIsloading(false)
    }
};

  const handlePdfCV=(event)=>{
    const file=event.target.files[0];
    if(file){
      setDocumentoCV(file);
    }
  }
  const handlePdfPortafolio=(event)=>{
    const file=event.target.files[0];
    if(file){
      setDocumentoPortafolio(file)
    }
  }
  const onDocumentLoadSuccess = (pdf, setNumPages) => {
    setNumPages(pdf.numPages);
  };
  const renderPdfPreview = (file, numPages, onLoadSuccess) => {
    return (
      <div className="mt-4">
        <Document
          file={file}
          onLoadSuccess={(pdf) => onLoadSuccess(pdf)}
          className="border rounded-lg overflow-hidden"
        >
          <Page pageNumber={1} width={200} />
        </Document>
        <p className="mt-2 text-sm text-gray-600">
          Páginas: {numPages}
        </p>
      </div>
    );
  };

  const nextStep = () => setStep(prev => Math.min(prev + 1, 3));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

  const sitioTrabajoOptions = ['Presencial', 'Remoto', 'Híbrido'];
  const modalidadTrabajoOptions = ['Tiempo Completo', 'Medio Tiempo', 'Contrato'];

  const fadeInOutVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  const motivationalPhrases = [
    "¡Construye tu futuro profesional!",
    "Tu perfil es tu carta de presentación al mundo",
    "Destaca tus habilidades únicas"
  ];

  const ProgressBar = ({ currentStep }) => (
    <div className="w-full bg-gray-200 rounded-full h-6 mb-10">
      <motion.div 
        className="bg-blue-600 h-6 rounded-full flex items-center justify-end pr-2 text-white text-sm font-bold"
        initial={{ width: "0%" }}
        animate={{ width: `${(currentStep / 3) * 100}%` }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {Math.round((currentStep / 3) * 100)}%
      </motion.div>
    </div>
  );

  const StepIndicator = ({ step, currentStep }) => (
    <motion.div
      className={`flex flex-col items-center ${currentStep >= step ? 'text-blue-600' : 'text-gray-400'}`}
      whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <motion.div
        className={`rounded-full h-16 w-16 flex items-center justify-center text-2xl font-bold border-4 ${currentStep >= step ? 'border-blue-600 bg-white text-blue-600' : 'border-gray-300 bg-gray-100'}`}
        animate={{ scale: currentStep === step ? 1.2 : 1 }}
        transition={{ duration: 0.3 }}
      >
        {currentStep > step ? <CheckCircle size={32} /> : step}
      </motion.div>
      <span className="mt-2 font-semibold">
        {step === 1 ? 'Información' : step === 2 ? 'Preferencias' : 'Documentos'}
      </span>
    </motion.div>
  );

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <motion.div
            key="step1"
            variants={fadeInOutVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h4 className="text-3xl font-bold mb-6 text-blue-600">Información Básica</h4>
            <p className="text-xl text-gray-600 mb-6">{motivationalPhrases[0]}</p>
            <div className="space-y-6">
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Resumen Profesional</label>
                <textarea
                  name="resumen"
                  value={resumen}
                  onChange={(e)=>setResumen(e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  rows="4"
                  placeholder="Describe tu experiencia y habilidades clave..."
                  required
                />
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Posición Actual</label>
                <input
                  type="text"
                  name="posicionActual"
                  value={posicionActual}
                  onChange={(e)=>setPosicionActual(e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  placeholder="Ej: Desarrollador Full Stack Senior"
                  required
                />
              </div>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            variants={fadeInOutVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h4 className="text-3xl font-bold mb-6 text-blue-600">Preferencias Laborales</h4>
            <p className="text-xl text-gray-600 mb-6">{motivationalPhrases[1]}</p>
            <div className="space-y-6">
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Años de Experiencia</label>
                <input
                  type="number"
                  name="years_experiencia"
                  value={yearsExperiencia}
                  onChange={(e)=>setYearsExperiencia(e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  min="0"
                  max="50"
                />
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Sitio de Trabajo Preferido</label>
                <select
                  name="sitioTrabajoPreferido"
                  value={sitioTrabajoPreferido}
                  onChange={(e)=>setTrabajoPreferido(e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                >
                  <option value="">Seleccionar...</option>
                  {sitioTrabajoOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Modalidad de Trabajo Preferida</label>
                <select
                  name="modalidadTrabajoPreferido"
                  value={modalidadTrabajoPreferido}
                  onChange={(e)=>setModalidadTrabajoPreferido(e.target.value)}
                  className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                >
                  <option value="">Seleccionar...</option>
                  {modalidadTrabajoOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="isAbiertoRelocalizacion"
                  checked={isAbiertoRelocalizacion}
                  onChange={(e)=>setIsAbiertoRelocalizacion(e.target.checked)}
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition duration-300"
                />
                <label className="ml-3 block text-lg text-gray-900">
                  Abierto a reubicación
                </label>
              </div>
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            key="step3"
            variants={fadeInOutVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h4 className="text-3xl font-bold mb-6 text-blue-600">Documentos Profesionales</h4>
            <p className="text-xl text-gray-600 mb-6">{motivationalPhrases[2]}</p>
            <div className="space-y-8">
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Curriculum Vitae</label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors duration-300">
                  <div className="space-y-1 text-center">
                    {documentoCV ? (
                      <div>
                        {renderPdfPreview(documentoCV, numPagesCv, (pdf) => onDocumentLoadSuccess(pdf, setNumPagesCv))}
                        <p className="mt-2 text-sm text-gray-600">
                          Archivo seleccionado: {documentoCV.name}
                        </p>
                      </div>
                    ) : (
                      <>
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <label htmlFor="documentoCV" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <span>Subir CV</span>
                            <input id="documentoCV" name="documentoCV" type="file" className="sr-only" onChange={handlePdfCV} accept=".pdf,.doc,.docx" />
                          </label>
                          <p className="pl-1">o arrastrar y soltar</p>
                        </div>
                        <p className="text-xs text-gray-500">PDF, DOC hasta 10MB</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-700 mb-2">Portafolio</label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors duration-300">
                  <div className="space-y-1 text-center">
                    {documentoPortafolio ? (
                      <div>
                        {renderPdfPreview(documentoPortafolio, numPagesPortafolio, (pdf) => onDocumentLoadSuccess(pdf, setNumPagesPortafolio))}
                        <p className="mt-2 text-sm text-gray-600">
                          Archivo seleccionado: {documentoPortafolio.name}
                        </p>
                      </div>
                    ) : (
                      <>
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <label htmlFor="documentoPortafolio" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <span>Subir Portafolio</span>
                            <input id="documentoPortafolio" name="documentoPortafolio" type="file" className="sr-only" onChange={handlePdfPortafolio} accept=".pdf,.zip" />
                          </label>
                          <p className="pl-1">o arrastrar y soltar</p>
                        </div>
                        <p className="text-xs text-gray-500">PDF, ZIP hasta 20MB</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{backgroundImage:`url(${fondoBolsa})`}}>
      <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        <div className="flex justify-between items-center">
          <img src={logoFesa} alt="LogoFesa" className="h-16 sm:h-20" />
          <img src={logoReconectateWhite} alt="LogoReconectateWhite" className="h-20 sm:h-24" />
        </div>
        <div className="text-center">
          <motion.h2 
            className="mt-6 text-4xl font-extrabold text-gray-900 sm:text-5xl"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Crea tu Perfil Profesional
          </motion.h2>
          <motion.p 
            className="mt-2 text-xl text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            Completa los siguientes pasos para crear un perfil profesional impresionante
          </motion.p>
        </div>
        <div className="mt-12">
          <ProgressBar currentStep={step} />
          <div className="flex justify-between mb-12">
            {[1, 2, 3].map((num) => (
              <StepIndicator key={num} step={num} currentStep={step} />
            ))}
          </div>
          <AnimatePresence mode="wait">
            {renderStep()}
          </AnimatePresence>
          <div className="mt-12 flex justify-between">
            {step > 1 && (
              <motion.button
                whileHover={{ scale: 1.05, backgroundColor: "#2563EB" }}
                whileTap={{ scale: 0.95 }}
                onClick={prevStep}
                className="flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg transition duration-300 text-lg font-semibold"
              >
                <ChevronLeft className="mr-2" size={24} />
                Anterior
              </motion.button>
            )}
            {step < 3 ? (
              <motion.button
                whileHover={{ scale: 1.05, backgroundColor: "#2563EB" }}
                whileTap={{ scale: 0.95 }}
                onClick={nextStep}
                className="flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg transition duration-300 text-lg font-semibold ml-auto"
              >
                Siguiente
                <ChevronRight className="ml-2" size={24} />
              </motion.button>
            ) : (
              <motion.button
                whileHover={{ scale: 1.05, backgroundColor: "#047857" }}
                whileTap={{ scale: 0.95 }}
                onClick={
                  handleSubmit}
                  disabled={isLoading}
      
                className="flex items-center px-6 py-3 bg-green-600 text-white rounded-lg transition duration-300 text-lg font-semibold ml-auto disabled:bg-green-400"
              >
              {isLoading ? 'Cargando...' : 'Enviar Perfil'}
              <CheckCircle className="ml-2" size={24} />
              </motion.button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPerfilProfesional;