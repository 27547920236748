import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';
import EmpresaOFCard from './empresasOLCard.jsx';
import FichaTecnica from '../Empresa/EmpresaFicha.jsx';
import AddJobOpportunityEmpresa from './AddOfertaLaboralEmpresa';
import { useNavigate } from 'react-router-dom';
import baseURL from "../../features/baseURL.js";
import axios from 'axios';

const ListaEmpresas = () => {
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [mostrarFicha, setMostrarFicha] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editingOferta, setEditingOferta] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getEmpresasByUser();
  }, []);

  const getEmpresasByUser = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/empresas/usuario`);
      setEmpresas(response.data || []);
    } catch (error) {
      console.error("Error al obtener empresas", error);
      setEmpresas([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVer = (empresa) => {
    setEmpresaSeleccionada(empresa);
    setMostrarFicha(true);
  };

  const handleCloseFicha = () => {
    setMostrarFicha(false);
    setEmpresaSeleccionada(null);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    setEditingOferta(null);
  };

  const handleCloseCreating = () => {
    setIsCreating(false);
    setEditingOferta(null);
    getEmpresasByUser();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEmpresas = empresas.filter(empresa =>
    empresa && empresa.nombre_legal && 
    empresa.nombre_legal.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGestionarOfertas = (id) => {
    navigate(`/gestionar-ofertas/${id}`);
  };

  if (isLoading) {
    return <div className="text-center text-lg font-semibold mt-4">Cargando empresas...</div>;
  }

  if (isCreating) {
    return (
      <AddJobOpportunityEmpresa
        empresaId={empresaSeleccionada?.uuid_empresa}
        editingOferta={editingOferta}
        onClose={handleCloseCreating}
      />
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto p-6 mt-6"
    >
      <h1 className="text-3xl font-bold mb-6 text-center">Gestión de Ofertas de Empresas</h1>

      <div className="mb-6 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Buscar empresas..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full p-2 rounded border border-gray-300"
          />
        </div>
        <button
          onClick={handleCreateNew}
          className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors duration-300 flex items-center"
        >
          <FaPlus className="mr-2" /> Crear Nueva Oferta
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
        {filteredEmpresas.length > 0 ? (
          filteredEmpresas.map((empresa) => (
            <EmpresaOFCard
              key={empresa.uuid_empresa}
              id={empresa.uuid_empresa}
              nombre={empresa.nombre_legal || 'Nombre no disponible'}
              logo={empresa.url_logo ? `${baseURL.BASE_URL}${empresa.url_logo}` : '/path/to/default/logo.png'}
              descripcion={empresa.descripcion || 'Descripción no disponible'}
              onVer={() => handleVer(empresa)}
              onGestionarOfertas={() => handleGestionarOfertas(empresa.uuid_empresa)}
            />
          ))
        ) : (
          <div className="col-span-1 sm:col-span-2 lg:col-span-3 text-center text-gray-500 mt-4">
            No se encontraron empresas que coincidan con tu búsqueda.
          </div>
        )}
      </div>

      {mostrarFicha && empresaSeleccionada && (
        <FichaTecnica empresa={empresaSeleccionada} onClose={handleCloseFicha} />
      )}
    </motion.div>
  );
};

export default ListaEmpresas;