import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <Navigate to="/login" />;
  }

  return usuario ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;