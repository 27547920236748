import React, { useEffect, useState } from "react";
import Select from "react-select";

const CountrySelect = ({ value, onChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        // If a user select value is provided, set it as the initial value
        if (data.userSelectValue) {
          onChange(data.userSelectValue);
        }
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, [onChange]);

  const handleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  return (
    <div className="w-full max-w-xs mx-auto">
      <Select
        options={countries}
        value={countries.find((c) => c.value === value)}
        onChange={handleChange}
        isSearchable={true}
        placeholder="Selecciona un país"
        className="react-select-container"
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default CountrySelect;
