import React,{useEffect} from 'react'
import MainFeedNavBar from "../MainFeed/MainFeedNavBar.jsx"
import { useNavigate } from 'react-router-dom'
import ProfessionalProfileComponent from '../../Components/PerfilProfesional/profesionaProfileResume.jsx'
const MiPerfilProfesionalPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <MainFeedNavBar>
       <ProfessionalProfileComponent/>
   </MainFeedNavBar>
  )
}

export default  MiPerfilProfesionalPage
