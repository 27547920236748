import React, { useEffect, useState, useRef } from 'react';
import { Save, Camera } from 'lucide-react';
import baseURL from "../../features/baseURL.js";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const EditableFichaTecnica = ({ onClose }) => {
  const [msg, setMsg] = useState("");
  const [empresa, setEmpresa] = useState({});
  const [editedEmpresa, setEditedEmpresa] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getEmpresaById();
  }, [id]);

  const getEmpresaById = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/empresas/${id}`);
      setEmpresa(response.data);
      setEditedEmpresa(response.data);
      setPreviewUrl(response.data.url_logo ? `${baseURL.BASE_URL}${response.data.url_logo}` : null);
    } catch (error) {
      console.error('Error al obtener datos de la empresa:', error);
      setMsg(error.response?.data?.msg || 'Error al cargar los datos de la empresa');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedEmpresa((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setEditedEmpresa((prev) => ({ ...prev, url_logo: file }));
    }
  };

  const handleRemoveImage = () => {
    setPreviewUrl(null);
    setEditedEmpresa((prev) => ({ ...prev, url_logo: null }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    Object.keys(editedEmpresa).forEach(key => {
      if (key === 'url_logo' && editedEmpresa[key] instanceof File) {
        formData.append(key, editedEmpresa[key]);
      } else if (editedEmpresa[key] !== null) {
        formData.append(key, editedEmpresa[key]);
      }
    });

    try {
      await axios.patch(`${baseURL.BASE_URL}/empresas/update_empresa/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Cambios guardados correctamente');
      navigate('/mi-red/empresas');
    } catch (error) {
      console.error('Error al guardar cambios:', error);
      setMsg(error.response?.data?.msg || 'Error al guardar los cambios');
    }
  };

  return (
    <div className="p-8 rounded-lg shadow-xl max-w-2xl w-full mx-auto mt-8">
      <h2 className="text-3xl font-bold text-center mb-6 text-blue-800">FICHA TÉCNICA DE EMPRESA</h2>
      
      <div className="mb-6 text-center">
        <label htmlFor="url_logo" className="block text-sm font-medium text-gray-700">
          Logo de la empresa
        </label>
        <div className="mt-2">
          {previewUrl ? (
            <img src={previewUrl} alt="Logo de la empresa" className="mx-auto h-24 object-contain" />
          ) : (
            <div className="flex justify-center items-center border-2 border-dashed border-gray-300 h-24">
              <Camera className="text-gray-400" />
            </div>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoChange}
          ref={fileInputRef}
          className="mt-4"
        />
        {previewUrl && (
          <button onClick={handleRemoveImage} className="mt-2 text-red-500 hover:text-red-700">
            Eliminar Logo
          </button>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nombre Legal</label>
          <input
            type="text"
            name="nombre_legal"
            value={editedEmpresa.nombre_legal || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">NIT</label>
          <input
            type="text"
            name="NIT"
            value={editedEmpresa.NIT || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Industria</label>
          <input
            type="text"
            name="industria"
            value={editedEmpresa.industria || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Año de Fundación</label>
          <input
            type="text"
            name="año_fundacion"
            value={editedEmpresa.año_fundacion || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Sitio Web</label>
          <input
            type="text"
            name="webEmpresa"
            value={editedEmpresa.webEmpresa || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Teléfono</label>
          <input
            type="text"
            name="telefono"
            value={editedEmpresa.telefono || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="correo"
            value={editedEmpresa.correo || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">Descripción</label>
          <textarea
            name="descripcion"
            value={editedEmpresa.descripcion || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            rows="3"
          />
        </div>
      </div>


      {msg && <p className="text-red-500 mt-2">{msg}</p>}

      <div className="flex justify-end mt-6">
        <button
          onClick={handleSave}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <Save className="mr-2" /> Guardar Cambios
        </button>
      </div>
    </div>
  );
};

export default EditableFichaTecnica;