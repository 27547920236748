import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  FaBriefcase, 
  FaMapMarkerAlt, 
  FaMoneyBillWave, 
  FaCalendarAlt, 
  FaStar, 
  FaListUl, 
  FaTrophy, 
  FaCheckCircle,
  FaEnvelope,
  FaTimes
} from 'react-icons/fa';
import imagenFondo from "../../Assets/images/fondoItem2.png"

import baseURL from "../../features/baseURL.js"
const DEFAULT_COMPANY_LOGO = '/api/placeholder/150/150';

const JobOpportunityDetails = ({ opportunity, onClose }) => {
  const navigate=useNavigate()

  const handlePostulacion = () => {
    
    navigate(`/empleo/postulacion/${opportunity.uuid_ofertasTrabajo}`, { state: { opportunity } });
  }

  const formatFechaCierre = (fecha) => {
    const date = new Date(fecha);
    return isNaN(date) ? 'Fecha no válida' : date.toLocaleDateString();
  };

  const obtenerHabilidades = (habilidades) => {
    return typeof habilidades === 'string' ? habilidades.split(',').map(skill => skill.trim()) : [];
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto p-4"
      onClick={onClose}
    >
      <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-4xl"

        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div 
            className="bg-cover bg-center text-white p-6 relative" // Reemplaza las clases anteriores
            style={{ 
              backgroundImage: `url(${imagenFondo})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center'
            }}
                      >
          <button 
            className="absolute right-4 top-4 text-white hover:bg-white hover:bg-opacity-20 rounded-full p-1"
            onClick={onClose}
          >
            <FaTimes className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-4">
            <img
              src={`${baseURL.BASE_URL}${opportunity.empresa?.url_logo}` || DEFAULT_COMPANY_LOGO}
              alt={`${opportunity.empresa?.nombre_legal || 'Empresa'} Logo`}
              className="w-20 h-20 object-cover rounded-full border-4 border-white bg-white-500"
            />
            <div>
              <h2 className="text-2xl  font-STHeitiMedium">
                {opportunity.titulo || 'Título no disponible'}
              </h2>
              <p className="text-lg opacity-90 font-STHeiti">
                {opportunity.empresa?.nombre_legal || 'Empresa no especificada'}
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6 font-STHeiti">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 font-STHeiti">
            <DetailItem icon={FaBriefcase} label={opportunity.tipo_contrato || 'No especificado'} />
            <DetailItem icon={FaMapMarkerAlt} label={opportunity.modalidad || 'No especificado'} />
            <DetailItem icon={FaMoneyBillWave} label={opportunity.salario_min && opportunity.salario_max ? `${opportunity.salario_min} - ${opportunity.salario_max} Bs.` : 'No especificado'} />
            <DetailItem icon={FaCalendarAlt} label={`Cierre: ${formatFechaCierre(opportunity.fecha_cierre)}`} />
          </div>
          
          <hr className="my-6 border-gray-200 " />
          
          <Section title="Descripción" icon={FaStar} >
            <p className="text-gray-700 leading-relaxed font-STHeiti">
              {opportunity.descripcion || 'Descripción no disponible'}
            </p>
          </Section>
          
          <div className="grid md:grid-cols-2 gap-6 font-STHeiti">
            <SkillsSection title="Habilidades Requeridas" skills={obtenerHabilidades(opportunity.habilidades_requeridas)} />
            <SkillsSection title="Habilidades Deseadas (No excluyente)" skills={obtenerHabilidades(opportunity.habilidades_deseadas)} />
          </div>
          
          <Section title="Beneficios" icon={FaTrophy}>
            <p className="text-gray-700 leading-relaxed font-STHeiti">
              {opportunity.beneficios_especificos || 'Beneficios no especificados.'}
            </p>
          </Section>
          
          
          
          <Section title="Contacto" icon={FaEnvelope}>
            {opportunity.correo_contacto ? (
              <a href={`mailto:${opportunity.correo_contacto}`} className="text-orange-600 hover:underline">
                {opportunity.correo_contacto}
              </a>
            ) : (
              <p className="text-gray-500">Correo de contacto no disponible.</p>
            )}
          </Section>
          <div className="flex flex-wrap gap-4">
            <DetailToggle icon={FaCheckCircle} label="Posibilidad de Crecimiento" isActive={opportunity.posibilidad_crecimiento} />
            <DetailToggle icon={FaCheckCircle} label="Proceso de Selección" isActive={opportunity.proceso_seleccion} />
          </div>
          
          <div className="flex justify-end space-x-4 mt-6">
            <button
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition-colors"
              onClick={onClose}
            >
              Cerrar
            </button>
            <button
              className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700 transition-colors"
              onClick={handlePostulacion}
            >
              Postularse
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const DetailItem = ({ icon: Icon, label }) => (
  <div className="flex items-center space-x-2 text-gray-700">
    <Icon className="text-orange-500 h-5 w-5" />
    <span>{label}</span>
  </div>
);

const Section = ({ title, icon: Icon, children }) => (
  <div>
    <h4 className="text-lg font-semibold mb-2 flex items-center space-x-2">
      <Icon className="text-orange-500 h-5 w-5" />
      <span>{title}</span>
    </h4>
    {children}
  </div>
);

const SkillsSection = ({ title, skills }) => (
  <Section title={title} icon={FaListUl}>
    {skills.length > 0 ? (
      <div className="flex flex-wrap gap-2">
        {skills.map((skill, index) => (
          <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-sm">
            {skill}
          </span>
        ))}
      </div>
    ) : (
      <p className="text-gray-500">No se especificaron habilidades.</p>
    )}
  </Section>
);

const DetailToggle = ({ icon: Icon, label, isActive }) => (
  <div className={`flex items-center space-x-2 ${isActive ? 'text-green-600' : 'text-gray-500'}`}>
    <Icon className="h-5 w-5" />
    <span>{label}</span>
  </div>
);

JobOpportunityDetails.propTypes = {
  opportunity: PropTypes.shape({
    titulo: PropTypes.string,
    empresa: PropTypes.shape({
      nombre_legal: PropTypes.string,
      url_logo: PropTypes.string,
    }),
    tipo_contrato: PropTypes.string,
    modalidad: PropTypes.string,
    salario_min: PropTypes.number,
    salario_max: PropTypes.number,
    fecha_cierre: PropTypes.string,
    descripcion: PropTypes.string,
    habilidades_requeridas: PropTypes.string,
    habilidades_deseadas: PropTypes.string,
    beneficios_especificos: PropTypes.string,
    posibilidad_crecimiento: PropTypes.bool,
    proceso_seleccion: PropTypes.bool,
    correo_contacto: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

export default JobOpportunityDetails;