import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../features/authSlice';
import baseURL from '../../features/baseURL';
import AcademicsCard from "../UI/AcademicsCard.jsx";
import CardCerrtificado from '../UI/CardCertficado.jsx';
import Cardi from '../UI/CardExperienciaLaboral.jsx';
import { Link } from 'react-router-dom';
import { 
  User, MapPin, Phone, Calendar, Mail, Briefcase 
} from 'lucide-react';
import { 
  Badge, Tabs, TabsList, TabsTrigger, TabsContent, 
  Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter
} from '../UI/Components';
import axios from 'axios';

const ProfessionalProfileComponent = () => {
  const dispatch = useDispatch();
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);
  const [profileData, setProfileData] = useState(null);
  const [data, setData] = useState({
    educaciones: [],
    experiencias: [],
    certificaciones: [],
    proyectos: [],
    publicaciones: [],
    lenguajes: [],
    voluntariados: [],
    reconocimientos: [],
    perfilesProfesionales: {}
  });
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    if (!usuario) {
      dispatch(getMe());
    } else {
      setProfileData(usuario);
    }
  }, [dispatch, usuario]);

  useEffect(() => {
    if (profileData) {
      fetchAllData();
    }
  }, [profileData]);

  const fetchAllData = async () => {

    setIsLoadingData(true);
    try {
      const endpoints = [
        axios.get(`${baseURL.BASE_URL}/educaciones/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/experienciasLaborales/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/certificaciones/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/proyectos/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/publicaciones/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/lenguajes/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/voluntariados/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/reconocimientos/perfilProfesional`),
        axios.get(`${baseURL.BASE_URL}/perfilesProfesionales/me`) // <--- Endpoint agregado
      ];

      const [
        educaciones, experiencias, certificaciones, proyectos,
        publicaciones, lenguajes, voluntariados, reconocimientos, perfilesProfesionales
      ] = await Promise.all(endpoints);
      setData({
        educaciones: educaciones.data || [],
        experiencias: experiencias.data || [],
        certificaciones: certificaciones.data || [],
        proyectos: proyectos.data || [],
        publicaciones: publicaciones.data || [],
        lenguajes: lenguajes.data || [],
        voluntariados: voluntariados.data || [],
        reconocimientos: reconocimientos.data || [],
        perfilesProfesionales: perfilesProfesionales.data || {} // <--- Perfiles Profesionales asignado
      });
      console.log(data)

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  if (isLoading || isLoadingData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> No se pudo cargar el perfil.</span>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <Card className="mb-8">
        <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <div className="flex items-center space-x-4">
            <img
              src={`${baseURL.BASE_URL}${profileData.url_foto_perfil_usuario}` || '/api/placeholder/150/150'}
              alt={`${profileData.nombre_usuario}'s profile`}
              className="w-24 h-24 rounded-full border-4 border-white shadow-lg"
            />
            <div>
              <CardTitle className="text-3xl">{profileData.nombre_completo}</CardTitle>
              <CardDescription className="text-white opacity-80">{profileData.promocion}</CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="mt-4">
          <InfoItem icon={<Mail />} label="Email" value={profileData.email_usuario} />
          <InfoItem icon={<Phone />} label="Teléfono" value={profileData.telefono_usuario} />
          <InfoItem icon={<MapPin />} label="Ubicación" value={`${profileData.ciudad_residencia_usuario}, ${profileData.pais_residencia_usuario}`} />
        </CardContent>
        <CardFooter>
          <Badge variant={profileData.isActivo ? "success" : "destructive"}>
            {profileData.isActivo ? 'Activo' : 'Inactivo'}
          </Badge>
        </CardFooter>
      </Card>

      <Tabs defaultValue="profile" className="w-full">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="profile">Perfil</TabsTrigger>
          <TabsTrigger value="education">Educación</TabsTrigger>
          <TabsTrigger value="workExperience">Experiencia Laboral</TabsTrigger>
          <TabsTrigger value="certifications">Certificaciones</TabsTrigger>
        </TabsList>
        
        {/* Perfil */}
        <TabsContent value="profile">
          <Card>
            <CardHeader>
              <CardTitle>Perfil Profesional</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">{data.perfilesProfesionales.resumen}</p>
              <div className="grid grid-cols-2 gap-4">
                <InfoItem icon={<Briefcase />} label="Posición Actual" value={data.perfilesProfesionales.posicion_actual} />
                <InfoItem icon={<Calendar />} label="Años de Experiencia" value={data.perfilesProfesionales.years_experiencia} />
                <InfoItem icon={<MapPin />} label="Sitio de Trabajo Preferido" value={data.perfilesProfesionales.sitio_trabajo_preferido} />
                <InfoItem icon={<Briefcase />} label="Modalidad de Trabajo" value={data.perfilesProfesionales.modalidad_trabajo_preferido} />
                <InfoItem icon={<MapPin />} label="Abierto a Relocalización" value={data.perfilesProfesionales.is_abierto_relocalizacion ? 'Sí' : 'No'} />
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
    <Link 
        to={`${baseURL.BASE_URL}${data.perfilesProfesionales.url_documento_cv}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-500 hover:underline"
    >
        Ver CV
    </Link>
    <Link 
        to={`${baseURL.BASE_URL}${data.perfilesProfesionales.url_portafolio}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-500 hover:underline"
    >
        Ver Portafolio
    </Link>
</CardFooter>
          </Card>
        </TabsContent>

        {/* Educación */}
        <TabsContent value="education">
          <Card>
            <CardHeader>
              <CardTitle>Educación</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-semibold">Educación registrada:</h3>
              {data.educaciones.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {data.educaciones.map((educacion) => (
                    <AcademicsCard
                      key={educacion.uuid_educacion}
                      title={educacion.nombre_institucion_educativa}
                      institution={educacion.grado_obtenido}
                      graduationDate={`${educacion.fecha_inicio} al ${educacion.fecha_finalizacion}`}
                      status={educacion.is_estudio_actual}
                    />
                  ))}
                </div>
              ) : (
                <p>No hay educaciones registradas.</p>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        {/* Experiencia Laboral */}
        <TabsContent value="workExperience">
          <Card>
            <CardHeader>
              <CardTitle>Experiencia Laboral</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-semibold">Experiencia Laboral registrada:</h3>
              {data.experiencias.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {data.experiencias.map((experiencia) => (
                    <Cardi
                      key={experiencia.uuid_experienciaLaboral}
                      position={experiencia.cargo}
                      company={experiencia.nombre_empresa}
                      startDate={experiencia.fecha_inicio}
                      endDate={experiencia.fecha_finalizacion}
                      description={experiencia.descripcion}
                    />
                  ))}
                </div>
              ) : (
                <p>No hay experiencias laborales registradas.</p>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        {/* Certificaciones */}
        <TabsContent value="certifications">
          <Card>
            <CardHeader>
              <CardTitle>Certificaciones</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="text-lg font-semibold">Certificaciones registradas:</h3>
              {data.certificaciones.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {data.certificaciones.map((certificacion) => (
                    <CardCerrtificado
                      key={certificacion.uuid_certificado}
                      title={certificacion.titulo_certificado}
                      issuingOrganization={certificacion.institucion_emisora}
                      issueDate={certificacion.fecha_emision}
                    />
                  ))}
                </div>
              ) : (
                <p>No hay certificaciones registradas.</p>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-2">
    <div>{icon}</div>
    <div className="font-semibold">{label}:</div>
    <div>{value || 'No disponible'}</div>
  </div>
);

export default ProfessionalProfileComponent;
