import React from 'react';
import { Globe, Star } from 'lucide-react'; // Importa los íconos que quieras usar

const LanguageCard = ({ nombre_lenguaje, nivel_lenguaje }) => {
  return (
    <div className="bg-gradient-to-br from-purple-50 to-indigo-100 border-2 border-indigo-300 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1">
      <div className="flex items-start mb-4">
        <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center mr-4 shadow-md">
          <Globe className="text-white" size={24} />
        </div>
        <div>
          <h2 className="font-bold text-2xl text-indigo-800">{nombre_lenguaje}</h2>
          <p className="text-indigo-600 text-lg">{nivel_lenguaje}</p>
        </div>
      </div>

      <div className="flex justify-between items-center mt-4 pt-4 border-t border-indigo-200">
        <span className="text-sm text-gray-500">Lenguaje</span>
        <Star className="text-indigo-500" size={20} />
      </div>
    </div>
  );
};

export default LanguageCard;
