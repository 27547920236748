import React from 'react';
import Navbar from '../Components/LandingPage/NavBarLandingPage';
const Layout = ({ children }) => {


  return (
    <React.Fragment>
      <Navbar/>
          <main className='main-content'>{children}</main>
    </React.Fragment>
  );
};

export default Layout;
