import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Tooltip = ({ children, content, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false);

  const positionStyles = {
    top: { bottom: '100%', left: '50%', transform: 'translateX(-50%)' },
    bottom: { top: '100%', left: '50%', transform: 'translateX(-50%)' },
    left: { right: '100%', top: '50%', transform: 'translateY(-50%)' },
    right: { left: '100%', top: '50%', transform: 'translateY(-50%)' }
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <AnimatePresence>
        {isVisible && content && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm"
            style={{
              ...positionStyles[position],
              maxWidth: '200px',
              pointerEvents: 'none'
            }}
          >
            {content}
            <div
              className="absolute w-2 h-2 bg-gray-900"
              style={{
                ...(position === 'top' && { bottom: '-4px', left: '50%', transform: 'translateX(-50%) rotate(45deg)' }),
                ...(position === 'bottom' && { top: '-4px', left: '50%', transform: 'translateX(-50%) rotate(45deg)' }),
                ...(position === 'left' && { right: '-4px', top: '50%', transform: 'translateY(-50%) rotate(45deg)' }),
                ...(position === 'right' && { left: '-4px', top: '50%', transform: 'translateY(-50%) rotate(45deg)' })
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tooltip;