import React,{useEffect} from 'react'
import MainFeedNavBar from "../MainFeed/MainFeedNavBar.jsx"
import { useNavigate } from 'react-router-dom'
import ListaEmpresas from '../../Components/Empresa/ListaEmpresas.jsx'
const ListaEmpresasPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <MainFeedNavBar>
        <ListaEmpresas/>
   </MainFeedNavBar>
  )
}

export default ListaEmpresasPage
