import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaMapMarkerAlt, FaMoneyBillWave, FaHeart, FaEdit, FaTrash, FaUsers, FaCalendar } from 'react-icons/fa';

const OfertaLaboralCardEmpresa = ({ opportunity, onEdit, onDelete, onViewPostulantes }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-white rounded-lg shadow-lg p-6 mb-4"
    >
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-xl font-bold text-orange-500 font-STHeiti">{opportunity.titulo}</h2>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaHeart className="text-gray-400 hover:text-red-500 transition-colors duration-300" />
        </motion.div>
      </div>
      <p className="text-black-300 font-semibold mb-4 font-STHeiti">{opportunity.empresa.nombre_legal}</p>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaMapMarkerAlt className="mr-2 text-orange-500" />
        <span>{opportunity.modalidad}</span>
      </div>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaMoneyBillWave className="mr-2 text-orange-500" />
        <span>${opportunity.salario_min} - ${opportunity.salario_max} USD</span>
      </div>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaBriefcase className="mr-2 text-orange-500" />
        <span>{opportunity.tipo_contrato}</span>
      </div>
      <div className="flex items-center text-sm text-gray-600 mb-2 font-STHeiti">
        <FaCalendar className="mr-2 text-orange-500" />
        <span>{`Cierre: ${opportunity.fecha_cierre}`}</span>
      </div>

      {/* Botones de acción */}
      <div className="flex space-x-2 mt-4"> {/* Usar space-x-2 para espaciado entre botones */}
        <button onClick={() => onEdit(opportunity)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 flex items-center">
          <FaEdit className="mr-1" /> Editar
        </button>
        <button onClick={() => onDelete(opportunity.uuid_ofertasTrabajo)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 flex items-center">
          <FaTrash className="mr-1" /> Eliminar
        </button>
        <button onClick={() => onViewPostulantes(opportunity.uuid_ofertasTrabajo)} className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 flex items-center">
          <FaUsers className="mr-1" /> Ver Postulantes
        </button>
      </div>
    </motion.div>
  );
};

export default OfertaLaboralCardEmpresa;
