import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../features/authSlice';
import baseURL from '../../features/baseURL';
import { User, MapPin, Phone, Calendar, Mail, Flag } from 'lucide-react';

const PerfilResumeComponent = () => {
  const dispatch = useDispatch();
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);
  const [usuarioObt, setUsuarioObt] = useState(null);
  const [isLoading2, setIsLoading2] = useState(true);

  useEffect(() => {
    if (!usuario) {
      dispatch(getMe());
    } else {
      setUsuarioObt(usuario);
      setIsLoading2(false);
    }
  }, [dispatch, usuario]);

  if (isLoading || isLoading2) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> No se pudo cargar el perfil.</span>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl mx-auto">
      {usuarioObt && (
        <>
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
            <div className="flex items-center space-x-4">
              <img
                src={`${baseURL.BASE_URL}${usuario.url_foto_perfil_usuario}` || '/api/placeholder/150/150'}
                alt={`${usuarioObt.nombre_usuario}'s profile`}
                className="w-24 h-24 rounded-full border-4 border-white shadow-lg"
              />
              <div>
                <h2 className="text-3xl font-bold">{usuarioObt.nombre_completo}</h2>
                <p className="text-xl opacity-80">{usuarioObt.promocion}</p>
                <p className="flex items-center mt-2">
                  <Mail className="w-4 h-4 mr-2" />
                  {usuarioObt.email_usuario}
                </p>
              </div>
            </div>
          </div>

          <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <InfoItem icon={<User />} label="Nombre de Usuario" value={usuarioObt.nombre_usuario} />
              <InfoItem icon={<Calendar />} label="Fecha de Nacimiento" value={usuarioObt.fecha_nacimiento_usuario} />
              <InfoItem icon={<Phone />} label="Teléfono" value={usuarioObt.telefono_usuario} />
              <InfoItem icon={<Flag />} label="Género" value={usuarioObt.genero} />
            </div>
            <div className="space-y-4">
              <InfoItem 
                icon={<MapPin />} 
                label="Lugar de Nacimiento" 
                value={`${usuarioObt.ciudad_nacimiento_usuario}, ${usuarioObt.pais_nacimiento_usuario}`} 
              />
              <InfoItem 
                icon={<MapPin />} 
                label="Lugar de Residencia" 
                value={`${usuarioObt.ciudad_residencia_usuario}, ${usuarioObt.pais_residencia_usuario}`} 
              />
              <InfoItem 
                icon={<MapPin />} 
                label="Dirección de Referencia" 
                value={usuarioObt.direccion_referencia_usuario} 
              />
            </div>
          </div>

          <div className="bg-gray-100 p-4 text-center">
            <span className={`px-4 py-2 rounded-full text-sm font-semibold ${usuarioObt.isActivo ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
              {usuarioObt.isActivo ? 'Activo' : 'Inactivo'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-2">
    <div className="text-blue-500">{icon}</div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-medium">{value}</p>
    </div>
  </div>
);

export default PerfilResumeComponent;