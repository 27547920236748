import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import ListadoOportunidades from "../../Components/OportunidadLaboral/ListadoOportunidadLaboral.jsx"
const OportunidadLaboralPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <LayoutMain>
       <ListadoOportunidades/>
   </LayoutMain>
  )
}

export default  OportunidadLaboralPage
