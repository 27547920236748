import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  usuario: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

export const LogInUsuario = createAsyncThunk("usuario/LogInUsuario", async (usuario, thunkAPI) => {
  try {
    const response = await axios.post(`/login`, {
      email: usuario.email,
      password: usuario.password
    }, { withCredentials: true });
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
    return thunkAPI.rejectWithValue("An error occurred during login");
  }
});

export const getMe = createAsyncThunk("usuario/getMe", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`/me`, { withCredentials: true });
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
    return thunkAPI.rejectWithValue("An error occurred while fetching user data");
  }
});

export const LogOut = createAsyncThunk("usuario/LogOut", async (_, thunkAPI) => {
  try {
    await axios.delete(`/logout`, { withCredentials: true });
  } catch (error) {
    return thunkAPI.rejectWithValue("An error occurred during logout");
  }
});

export const verifyProfessionalProfile = createAsyncThunk(
  "usuario/verificarProfesional",
  async (uuid_usuario, thunkAPI) => {
    try {
      const response = await axios.get(
        `/perfilesProfesionales/usuario/${uuid_usuario}`,
        { withCredentials: true }
      );
      return { tienePerfilProfesional: !!response.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue("An error occurred while verifying professional profile");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state, action) => {
      state.usuario = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LogInUsuario.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.status = 'loading';
      })
      .addCase(LogInUsuario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.usuario = action.payload;
        state.status = 'succeeded';
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(LogInUsuario.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.status = 'failed';
      })
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
        state.status = 'loading';
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.usuario = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.status = 'failed';
      })
      .addCase(LogOut.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
        state.status = 'loading';
      })
      .addCase(LogOut.fulfilled, (state) => {
        Object.assign(state, initialState);
        state.status = 'idle';
        localStorage.removeItem('user');
      })
      .addCase(LogOut.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.status = 'failed';
      })
      .addCase(verifyProfessionalProfile.fulfilled, (state, action) => {
        if (state.usuario) {
          state.usuario.tienePerfilProfesional = action.payload.tienePerfilProfesional;
        }
      });
  },
});

export const { reset, setUser } = authSlice.actions;
export default authSlice.reducer;