import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logoReconectate from "../Assets/images/logoReconectate.webp";
import logoFesa from "../Assets/images/logoFesa.png";
import { IoEye, IoEyeOff } from 'react-icons/io5';
import fondoBolsa from '../Assets/images/fondoBolsa.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { LogInUsuario, reset, getMe, verifyProfessionalProfile } from '../features/authSlice.js';

const LogIn = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    passwordVisible: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usuario, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(reset()); // Resetea el estado al montar el componente
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess && usuario) {
      dispatch(getMe())
        .then((response) => {
          if (response.payload && response.payload.uuid_usuario) {
            dispatch(verifyProfessionalProfile(response.payload.uuid_usuario))
              .then((res) => {
                navigate(res.payload.tienePerfilProfesional ? '/home' : '/perfilProfesional/crearPerfil');
              })
              .catch((error) => {
                console.error('Error al verificar perfil profesional:', error);
                navigate('/perfilProfesional/crearPerfil');
              });
          }
        })
        .catch((error) => {
          console.error('Error al obtener datos del usuario:', error);
        });
    }
  }, [isSuccess, usuario, dispatch, navigate]);

  useEffect(() => {
    if (isError) {
      alert(message); // Mostrar un mensaje de error más amigable
    }
  }, [isError, message]);

  const togglePasswordVisibility = () => {
    setFormState((prevState) => ({
      ...prevState,
      passwordVisible: !prevState.passwordVisible,
    }));
  };

  const handleInputChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.email && formState.password) {
      dispatch(LogInUsuario({ email: formState.email, password: formState.password }));
    } else {
      alert('Por favor, complete todos los campos.');
    }
  };

  return (
    <section className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${fondoBolsa})` }}>

      <div className="absolute top-4 left-4">
        <img src={logoFesa} alt="LogoFesa" className="w-24 h-auto" />
      </div>

      <div className="flex flex-col items-center justify-center w-full max-w-md p-6 mx-auto bg-white shadow-md rounded-lg">
        <img src={logoReconectate} alt="Logo" className="w-40 h-auto mb-6" />
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Correo:</label>
            <input
              type="text"
              id="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              placeholder="Ingrese su correo..."
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Contraseña:</label>
            <input
              type={formState.passwordVisible ? "text" : "password"}
              id="password"
              name="password"
              value={formState.password}
              onChange={handleInputChange}
              placeholder="Contraseña"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {formState.passwordVisible ? <IoEyeOff className="text-gray-500" /> : <IoEye className="text-gray-500" />}
            </span>
          </div>

          <div className="mb-6">
            <button
              type="submit"
              className="w-full py-2 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {isLoading ? 'Cargando...' : 'Acceder'}
            </button>
            {isError && <p className='text-red-500'>{message}</p>}
          </div>

          <div className="text-center">
            <a href="#forgot-password" className="text-blue-500 hover:underline">¿Olvidó su contraseña?</a><br />
            <a href="/registrarse" className="text-blue-500 hover:underline">Regístrate</a>
          </div>
        </form>
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center text-gray-600 text-sm">
        <p>&copy; 2024 FESA. DERECHOS DE AUTOR.</p>
      </div>
    </section>
  );
};

export default LogIn;
