import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function ActivateAccount() {
  const { uuid, token } = useParams(); // Obtener los parámetros de la URL
  const navigate = useNavigate(); // Para navegar al login
  const [message, setMessage] = useState('');
  const [isActivated, setIsActivated] = useState(false);

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const response = await axios.patch(`/usuarios/activate/${uuid}/${token}`);
        console.log(response)
        setMessage(response.data.msg);
        setIsActivated(true);
      } catch (error) {
        setMessage('Error en la activación o enlace inválido');
        setIsActivated(false);
      }
    };
    activateAccount();
  }, [uuid, token]);

  const handleLogin = () => {
    navigate('/login'); // Navegar al login cuando se presione el botón
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        {isActivated ? (
          <div>
            <h2 className="text-2xl font-bold text-green-600 mb-4">¡Cuenta Activada!</h2>
            <p className="text-gray-700 mb-6">Tu cuenta ha sido activada exitosamente. Ahora puedes iniciar sesión.</p>
            <button
              onClick={handleLogin}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Iniciar Sesión
            </button>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold text-red-600 mb-4">Activación Fallida</h2>
            <p className="text-gray-700 mb-6">El enlace de activación no es válido o ya ha sido utilizado.</p>
            <button
              onClick={() => navigate('/registrarse')}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Registrarse de nuevo
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivateAccount;
