import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import ListaEmpresasOF from "../../Components/OportunidadLaboral/ListaEmpresasOF.jsx"
const PostulacionesEmpresasPage= () => {
    
    
  return (
   <LayoutMain>
       <ListaEmpresasOF/>
   </LayoutMain>
  )
}

export default  PostulacionesEmpresasPage
