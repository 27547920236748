import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import baseURL from "../../features/baseURL.js";
import Post from '../Publicacion/Post.jsx';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchPosts = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/posts`, {
        params: { page, limit: 10 }
      });

      const newPosts = response.data;
      
      if (Array.isArray(newPosts) && newPosts.length > 0) {
        setPosts(prevPosts => {
          const uniquePosts = [...prevPosts];
          newPosts.forEach(newPost => {
            if (!uniquePosts.some(post => post.uuid_post === newPost.uuid_post)) {
              uniquePosts.push(newPost);
            }
          });
          return uniquePosts;
        });
        
        setPage(prevPage => prevPage + 1);
        setHasMore(newPosts.length === 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      setHasMore(false);
    }
  }, [page]);

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div id="scrollableDiv" className="h-full overflow-auto p-4">
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchPosts}
        hasMore={hasMore}
        loader={<h4>Cargando...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {posts.map((post) => (
          <Post key={post.uuid_post} post={post} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Feed;