import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PostulanteCard from './postulanteCard';
import { FaDownload, FaSearch } from 'react-icons/fa';

const ListadoPostulantes = () => {
  const { uuid } = useParams();
  const [postulantes, setPostulantes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');  // Nuevo estado para filtro de estado
  const [filteredPostulantes, setFilteredPostulantes] = useState([]);

  useEffect(() => {
    fetchPostulantes();
  }, [uuid]);

  useEffect(() => {
    filterPostulantes();
  }, [searchTerm, statusFilter, postulantes]);

  const fetchPostulantes = async () => {
    try {
      const response = await axios.get(`/postulacionesLaborales/ofertaLaboral/${uuid}`);
      setPostulantes(response.data);
      setFilteredPostulantes(response.data);
    } catch (error) {
      console.error('Error fetching postulantes:', error);
    }
  };

  const filterPostulantes = () => {
    const results = postulantes.filter(postulante => {
      const nombreCompleto = postulante.perfilesProfesionale.usuario.exalumno.nombre_completo.toLowerCase();
      const estado = postulante.estado.toLowerCase();
      const searchMatch = nombreCompleto.includes(searchTerm.toLowerCase()) || postulante.perfilesProfesionale.usuario.email_usuario.toLowerCase().includes(searchTerm.toLowerCase());
      const statusMatch = statusFilter ? estado === statusFilter.toLowerCase() : true;
      return searchMatch && statusMatch;
    });
    setFilteredPostulantes(results);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleDownloadCVs = async () => {
    try {
      const response = await axios.get(`/postulacionesLaborales/ofertaLaboral/CV/${uuid}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `CVs_oferta_${uuid}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error descargando los CVs:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Lista de Postulantes</h1>
      
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Buscar por nombre o email..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-1/2 p-2 border rounded mr-4"
        />

        <select
          value={statusFilter}
          onChange={handleStatusChange}
          className="w-1/4 p-2 border rounded mr-4"
        >
          <option value="">Filtrar por estado</option>
          <option value="Aceptada">Aceptada</option>
          <option value="Rechazada">Rechazada</option>
          <option value="Pendiente">Pendiente</option>
        </select>

        <button
          onClick={handleDownloadCVs}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
        >
          <FaDownload className="mr-2" /> Descargar Todos los CVs
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredPostulantes.length > 0 ? (
          filteredPostulantes.map(postulante => (
            <PostulanteCard
              key={postulante.uuid_postulante}
              postulante={postulante}
              onUpdate={() => fetchPostulantes()}  // Refresca la lista tras cambios
            />
          ))
        ) : (
          <p className="text-center text-gray-500">No se encontraron postulantes.</p>
        )}
      </div>
    </div>
  );
};

export default ListadoPostulantes;
