import React,{useEffect} from 'react'
import LandingPage from '../../Components/LandingPage/LandingPage.jsx'
import Layout from '../Layout.jsx'
import { useNavigate } from 'react-router-dom'
const LandingPageLayout = () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <LandingPage/>
    </Layout>
  )
}

export default LandingPageLayout;
