import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Notification = () => {
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const { usuario } = useSelector((state) => state.auth);

  useEffect(() => {
    if (usuario && usuario.uuid_usuario) {
      const ws = new WebSocket(`ws://localhost:5000?userId=${usuario.uuid_usuario}`);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'unread_notifications') {
          setNotifications(data.data);
        } else if (data.type === 'postulacion') {
          setNotifications((prevNotifications) => [data.data, ...prevNotifications]);

        }
      };

      return () => {
        ws.close();
      };
    } else {
      console.warn('Usuario no autenticado o ID no definido.');
    }
  }, [usuario]);

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(`/api/notifications/${notificationId}/read`);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notif) => notif.uuid_comentario !== notificationId)
      );
    } catch (error) {
      console.error('Error al marcar la notificación como leída:', error);
    }
  };

  return (
    <div className="fixed bottom-5 right-5 max-w-sm w-full">
      {notifications.map((notification) => (
        <div
          key={notification.uuid_comentario}
          className="bg-white border-l-4 border-blue-500 p-4 mb-2 shadow-md rounded-lg"
        >
          <div className="flex items-center justify-between">
            <Link to={notification.url_destino} className="text-sm text-gray-700 hover:text-blue-500">
              {notification.mensaje}
            </Link>
            <button
              onClick={() => markAsRead(notification.uuid_comentario)}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              Marcar como leída
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Notification;