import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import MisPostulacionesLaborales from "../../Components/OportunidadLaboral/misPostulacionesLaborales.jsx"
const misPostulacionesLaboralesPage= () => {
    
    
  return (
   <LayoutMain>
       <MisPostulacionesLaborales/>
   </LayoutMain>
  )
}

export default  misPostulacionesLaboralesPage
