import React from 'react';
import { Heart, Calendar, CheckCircle } from 'lucide-react';

const VolunteerCard = ({
  nombre_organizacion,
  rol_organizacion,
  fecha_inicio_voluntariado,
  fecha_finalizacion_voluntariado,
  causa_voluntariado,
  is_rolActual,
  descripcion
}) => {
  return (
    <div className="bg-gradient-to-br from-green-50 to-teal-100 border-2 border-teal-300 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1">
      <div className="flex items-start mb-4">
        <div className="w-12 h-12 bg-teal-500 rounded-full flex items-center justify-center mr-4 shadow-md">
          <Heart className="text-white" size={24} />
        </div>
        <div>
          <h2 className="font-bold text-2xl text-teal-800">{nombre_organizacion}</h2>
          <p className="text-teal-600 text-lg">{rol_organizacion}</p>
        </div>
      </div>

      <div className="space-y-2 mb-4">
        <p className="text-gray-700"><strong>Causa:</strong> {causa_voluntariado}</p>
        <p className="text-gray-700 flex items-center">
          <Calendar className="mr-2" size={16} />
          {fecha_inicio_voluntariado} - {is_rolActual ? 'Presente' : fecha_finalizacion_voluntariado}
        </p>
        <p className="text-gray-600 text-sm">{descripcion}</p>
      </div>

      <div className="flex justify-between items-center mt-4 pt-4 border-t border-teal-200">
        <span className="text-sm text-gray-500">Voluntariado</span>
        {is_rolActual && <CheckCircle className="text-teal-500" size={20} />}
      </div>
    </div>
  );
};

export default VolunteerCard;