import React, { useState, useEffect } from 'react';
import { FaHome, FaClipboard, FaUsers, FaBriefcase, FaComments, FaBell, FaBusinessTime, FaBars, FaSearch, FaCaretDown } from 'react-icons/fa';
import { NavLink, useNavigate } from "react-router-dom";
import logoFesa from "../../Assets/images/logoFesa.png";
import { LogOut, reset } from '../../features/authSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import baseURL from "../../features/baseURL.js";
import Notification from "../Notificaciones/Notificacion.jsx";
import { getPerfilesProfesionales } from '../../features/usersSlice';
import axios from 'axios';
const CustomNavbar = () => {
  const [searchText, setSearchText] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usuario } = useSelector((state) => state.auth);
  const { list: userList } = useSelector((state) => state.users);
  const [notifications, setNotifications] = useState([]);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/login");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(getPerfilesProfesionales());
  }, [dispatch]);

  useEffect(() => {
    if (usuario && usuario.uuid_usuario) {
      const ws = new WebSocket(`ws://localhost:5000?userId=${usuario.uuid_usuario}`);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'unread_notifications') {

          setNotifications(data.data);
          console.log(data)

        } else if (data.type === 'postulacion') {
          setNotifications((prevNotifications) => [data.data, ...prevNotifications]);


        }
      };

      return () => {
        ws.close();
      };
    } else {
      console.warn('User not authenticated or ID not defined.');
    }
  }, [usuario]);

  const handleSearchInput = (event) => {
    const input = event.target.value;
    setSearchText(input);

    if (input.length > 0) {
      const filteredSuggestions = userList.filter((profile) =>
        profile.usuario.exalumno.nombre_completo.toLowerCase().includes(input.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSearchSubmit = () => {
    navigate(`/search?query=${searchText}`);
  };

  const handleSuggestionClick = (profileId) => {
    navigate(`/profile/${profileId}`);
    setSearchText('');
    setSuggestions([]);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotifications = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(`/api/notifications/${notificationId}/read`);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notif) => notif.uuid_comentario !== notificationId)
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const NavLinks = () => (
    <>
      <NavLink to="/home" className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
        <FaHome />
        {isMobile && <span>Inicio</span>}
      </NavLink>
      <NavLink to="/empleo" className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
        <FaBriefcase />
        {isMobile && <span>Empleo</span>}
      </NavLink>
      <NavLink to="/mensajes" className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
        <FaComments />
        {isMobile && <span>Mensajes</span>}
      </NavLink>
      <NavLink to="/negocios" className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
        <FaBusinessTime />
        {isMobile && <span>Negocios</span>}
      </NavLink>
      <NavLink to="/mis-postulaciones" className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
        <FaClipboard />
        {isMobile && <span>Mis Postulaciones</span>}
      </NavLink>

    </>
  );

  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="flex justify-between items-center px-4 py-2">
        <div className="flex items-center">
          <NavLink to="/home" className="block">
            <img src={logoFesa} alt="Logo" className="max-w-[60px] h-auto" />
          </NavLink>
        </div>

        {!isMobile && (
          <div className="flex items-center space-x-4">
            <div className="relative">
              <FaSearch className="absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-500" />
              <input
                type="text"
                value={searchText}
                onChange={handleSearchInput}
                className="w-[800px] h-[40px] rounded-lg bg-gray-200 pl-10 text-sm font-['Helvetica_Neue'] placeholder-gray-500 focus:outline-none font-STHeiti"
                placeholder="Buscar"
              />
              <button
                onClick={handleSearchSubmit}
                className="ml-4 bg-gray-200 text-sm font-semibold px-4 py-2 rounded-lg hover:bg-gray-300 transition font-STHeiti"
              >
                Buscar
              </button>
              {suggestions.length > 0 && (
  <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-md">
    {suggestions.length > 0 && (
  <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg">
  {suggestions.map(profile => (
    <div
      key={profile.uuid_perfilProfesional}
      className="flex items-center p-3 hover:bg-gray-100 cursor-pointer transition"
      onClick={() => handleSuggestionClick(profile.uuid_perfilProfesional)}
    >
      {profile.usuario.url_foto_perfil_usuario ? (
        <img
          src={`${baseURL.BASE_URL}${profile.usuario.url_foto_perfil_usuario}`}
          alt="Foto de perfil"
          className="w-10 h-10 rounded-full border border-gray-300 mr-3"
        />
      ) : (
        <div className="w-10 h-10 rounded-full bg-gray-200 border border-gray-300 mr-3"></div>
      )}
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">{profile.usuario.exalumno.nombre_completo}</span>
        <span className="text-gray-600 text-sm">{profile.usuario.exalumno.promocion_exalumno}</span>
      </div>
    </div>
  ))}
</div>

)}
  </div>
)}
            </div>

            <NavLinks />

            {/* Notification Button */}
            <div className="relative">
              <button onClick={toggleNotifications} className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
                <FaBell />
                {isMobile && <span>Notificaciones</span>}
              </button>
              {isNotificationOpen && <Notification />}
            </div>

            {/* User Info Section with Dropdown */}
            {usuario && (
              <div className="relative">
                <button onClick={toggleDropdown} className="flex items-center space-x-2">
                  {usuario.url_foto_perfil_usuario ? (
                    <img
                      src={`${baseURL.BASE_URL}${usuario.url_foto_perfil_usuario}`}
                      alt="Foto de perfil"
                      className="w-8 h-8 rounded-full border border-gray-300"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-200"></div>
                  )}
                  <span className="text-gray-700 font-STHeiti">{usuario.nombre_usuario}</span>
                  <FaCaretDown className="text-gray-600" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-md z-10 font-STHeiti">
                    <NavLink to="/mi-red" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Mi Red
                    </NavLink>
                    <button
                      onClick={logout}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Cerrar sesión
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {isMobile ? (
          <button onClick={toggleMenu} className="text-gray-600 text-2xl">
            <FaBars />
          </button>
        ) : null}
      </div>
      {isMobile && isMenuOpen && (
        <div className="bg-white px-4 py-2 shadow-md">
          <div className="flex flex-col space-y-4">
            <div className="relative mb-4">
              <FaSearch className="absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-500" />
              <input
                type="text"
                value={searchText}
                onChange={handleSearchInput}
                className="w-full h-[40px] rounded-lg bg-gray-200 pl-10 text-sm font-['Helvetica_Neue'] placeholder-gray-500 focus:outline-none"
                placeholder="Buscar"
              />
              <button
                onClick={handleSearchSubmit}
                className="mt-2 w-full bg-gray-200 text-sm font-semibold px-4 py-2 rounded-lg hover:bg-gray-300 transition"
              >
                Buscar
              </button>
              {suggestions.length > 0 && (
                <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-md">
                  {suggestions.map(profile => (
                    <div
                      key={profile.id}
                      className="px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleSuggestionClick(profile.id)}
                    >
                      {profile.nombre_usuario}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <NavLinks />

            {/* Notification Button for Mobile */}
            <div className="relative">
              <button onClick={toggleNotifications} className="flex items-center text-gray-600 text-xl hover:text-blue-500 transition space-x-2">
                <FaBell />
                {isMobile && <span>Notificaciones</span>}
              </button>
              {isNotificationOpen && <Notification />}
            </div>

            {/* User Info Section for Mobile */}
            {usuario && (
              <div className="relative">
                <button onClick={toggleDropdown} className="flex items-center space-x-2">
                  {usuario.url_foto_perfil_usuario ? (
                    <img
                      src={`${baseURL.BASE_URL}${usuario.url_foto_perfil_usuario}`}
                      alt="Foto de perfil"
                      className="w-8 h-8 rounded-full border border-gray-300"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-200"></div>
                  )}
                  <span className="text-gray-700">{usuario.nombre_usuario}</span>
                  <FaCaretDown className="text-gray-600" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-md z-10">
                    <NavLink to="/mi-red" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Mi Red
                    </NavLink>
                    <button
                      onClick={logout}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Cerrar sesión
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isNotificationOpen && <Notification notifications={notifications} markAsRead={markAsRead} />}
    </nav>
  );
};

export default CustomNavbar;