import React from 'react'
import { useNavigate } from 'react-router-dom';
import ProfileEdit from "../../Components/Profile/profileEdit.jsx"
import MainFeedNavBar from "../MainFeed/MainFeedNavBar.jsx"
const EdiPerfilPage = () => {
    const navigate=useNavigate();
    
    return (
     <MainFeedNavBar>
          <ProfileEdit/>
     </MainFeedNavBar>
    )
  }

export default EdiPerfilPage
