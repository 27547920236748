import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaUpload, FaFile, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import baseURL from "../../features/baseURL.js";
import { useSelector, useDispatch } from 'react-redux';
import { getMe } from '../../features/authSlice.js';

const JobApplication = () => {
  // ... (previous state and useEffect code remains the same)
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const user = useSelector((state) => state.auth.usuario);

  const [opportunity, setOpportunity] = useState(null);
  const [cvOption, setCvOption] = useState('upload');
  const [cvFile, setCvFile] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (authStatus === 'idle') {
        await dispatch(getMe());
      }

      if (!location.state?.opportunity) {
        try {
          const response = await axios.get(`${baseURL.BASE_URL}/oportunidadesLaborales/${id}`);
          setOpportunity(response.data);
        } catch (error) {
          console.error('Error fetching job opportunity:', error);
          toast.error('Error al cargar la oportunidad de trabajo');
          navigate('/empleos', { replace: true });
          return;
        }
      } else {
        setOpportunity(location.state.opportunity);
      }

      if (user) {
        try {
          const profileResponse = await axios.get(`${baseURL.BASE_URL}/perfilesProfesionales/usuario/${user.uuid_usuario}`);
          setProfile(profileResponse.data);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          toast.error('Error al cargar tu perfil profesional');
          return;
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, id, location.state, navigate, authStatus, user]);

  const handleCvUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type.includes('word'))) {
      setCvFile(file);
      setErrors(prev => ({ ...prev, cvFile: null }));
    } else {
      setErrors(prev => ({ ...prev, cvFile: 'Por favor, sube un archivo PDF o Word.' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (cvOption === 'upload' && !cvFile) {
      newErrors.cvFile = 'Por favor, sube tu CV.';
    }
    if (cvOption === 'existing' && (!profile || !profile.url_documento_cv)) {
      newErrors.cvFile = 'No tienes un CV guardado en tu perfil.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('profileId', profile.uuid_perfilProfesional);
      formData.append('jobId', opportunity.uuid_ofertasTrabajo);
      formData.append('cvOption', cvOption);

      if (cvOption === 'upload' && cvFile) {
        formData.append('cvFile', cvFile);
      } else if (cvOption === 'existing' && profile.url_documento_cv) {
        formData.append('cvUrl', profile.url_documento_cv);
      }

      formData.append('additionalInfo', additionalInfo);

      const response = await axios.post(`${baseURL.BASE_URL}/postulacionesLaborales/add_postulacionLaboral`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });

      if (response.data.msg === "Registro Exitoso!" || response.data.msg === "Postulación creada exitosamente") {
        toast.success('Postulación enviada con éxito');
        navigate('/mis-postulaciones');
      } else {
        throw new Error(response.data.msg || 'Error al enviar la postulación');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      if (error.message === "Postulación creada exitosamente") {
        // This is actually a success message
        toast.success('Postulación enviada con éxito');
        navigate('/mis-postulaciones');
      } else {
        toast.error('Error al enviar la postulación: ' + (error.response?.data?.msg || error.message));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading || authStatus === 'loading') {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-orange-600" />
      </div>
    );
  }

  if (!opportunity || authStatus === 'failed' || !user || !profile) {
    return (
      <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
        <p className="text-center text-red-600">
          No se pudo cargar la información necesaria. Por favor, inténtalo de nuevo más tarde.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-6">Postulación para {opportunity.titulo}</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
      <div>
          <h3 className="text-lg font-semibold mb-2">Currículum Vitae</h3>
          <div className="flex flex-wrap gap-4">
            {['upload', 'existing'].map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setCvOption(option)}
                className={`flex items-center px-4 py-2 rounded transition-colors ${
                  cvOption === option ? 'bg-orange-600 text-white' : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {option === 'upload' && <FaUpload className="mr-2" />}
                {option === 'existing' && <FaFile className="mr-2" />}
                {option === 'upload' ? 'Subir CV' : 'Usar CV existente'}
              </button>
            ))}
          </div>
        </div>

        {cvOption === 'upload' && (
          <div>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleCvUpload}
              className="w-full p-2 border border-gray-300 rounded"
            />
            {errors.cvFile && <p className="text-red-500 text-sm mt-1">{errors.cvFile}</p>}
          </div>
        )}

        <div>
          <label htmlFor="additional-info" className="block text-sm font-medium text-gray-700 mb-2">
            Información adicional (opcional)
          </label>
          <textarea
            id="additional-info"
            rows="4"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Añade cualquier información adicional que quieras compartir"
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full py-2 px-4 bg-orange-600 text-white rounded hover:bg-orange-700 transition-colors ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <FaSpinner className="animate-spin mr-2" /> Enviando...
            </span>
          ) : (
            'Enviar Postulación'
          )}
        </button>

      </form>
    </div>
  );

};

export default JobApplication;