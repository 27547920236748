import React from 'react';
import { motion } from 'framer-motion';
import Tooltip from '../UI/Tooltip.jsx';

const HighlightComponent = ({ children, active, content }) => (
  <div className="relative">
    {active && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
      />
    )}
    <Tooltip content={active ? content : null}>
      <motion.div
        animate={active ? { scale: 1.05, boxShadow: "0px 0px 8px rgb(59, 130, 246)" } : {}}
        transition={{ duration: 0.5 }}
        className={`relative ${active ? 'z-50' : ''}`}
      >
        {children}
      </motion.div>
    </Tooltip>
  </div>
);

export default HighlightComponent;