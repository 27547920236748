import React from 'react';
import { motion } from 'framer-motion';
import { FaUsers, FaLightbulb, FaHandshake } from 'react-icons/fa';

const AboutUs = () => {
  const features = [
    {
      icon: FaUsers,
      title: "Comunidad",
      description: "Únete a una red de profesionales agustinos apasionados que comparten tus intereses y objetivos.",
    },
    {
      icon: FaLightbulb,
      title: "Innovación",
      description: "Descubre las últimas tendencias y herramientas que están transformando tu campo.",
    },
    {
      icon: FaHandshake,
      title: "Conexiones",
      description: "Crea vínculos valiosos con agustinos que impulsen tu carrera hacia el éxito.",
    },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-b from-blue-100 to-indigo-200 py-20 px-4"
    >
      <div className="max-w-5xl mx-auto text-center">
        <motion.h1 
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-5xl font-extrabold mb-6 text-gray-900"
        >
          Sobre Nosotros
        </motion.h1>
        <motion.p 
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-xl mb-10 text-gray-700"
        >
          En Reconéctate, creemos en el poder de las conexiones profesionales para impulsar carreras y transformar vidas. ¡Juntos somos más fuertes!
        </motion.p>
        
        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.3 }}
              className="bg-white p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <div className="flex items-center justify-center mb-4">
                <feature.icon className="text-5xl text-blue-600" />
              </div>
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          className="mt-12"
        >
          <a 
            href="/registrarse" 
            className="bg-gradient-to-r from-purple-500 to-blue-600 text-white py-3 px-8 rounded-full text-lg font-bold shadow-lg hover:shadow-2xl transition duration-300"
          >
            Únete a nuestra comunidad
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
