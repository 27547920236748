// src/components/ui/EducationCard.jsx

import React from 'react';
import SchoolIcon from '@mui/icons-material/School';

const EducationCard = ({ title, institution, graduationDate, status }) => {
  return (
    <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-4 flex flex-col justify-between transition-transform transform hover:scale-105">
      <div className="flex items-start">
        <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3 shadow-sm">
          <SchoolIcon className="text-green-500 text-xl" />
        </div>
        <h1 className="font-semibold text-xl text-gray-800">{title}</h1>
      </div>
      <p className="text-gray-600 text-base mt-1">{institution}</p>
      <div className="flex flex-col mt-2">
        <span className="flex items-center text-gray-600 text-sm mt-1">
          <span className="font-semibold">Fechas:</span> 
          <span className="text-gray-800 ml-1">{graduationDate}</span>
        </span>
      </div>
      <div className="flex justify-end mt-4">
        <span className={`px-2 py-1 text-xs font-semibold text-white rounded-full ${status === 'Pending' ? 'bg-yellow-500' : 'bg-blue-500'}`}>
          {status}
        </span>
      </div>
    </div>
  );
};

export default EducationCard;
