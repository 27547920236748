import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMe } from '../../features/authSlice';
import axios from 'axios';
import baseURL from "../../features/baseURL";
import { motion } from 'framer-motion';
import { 
  FaBriefcase, 
  FaMoneyBillWave, 
  FaCalendarAlt, 
  FaLaptopCode, 
  FaStar, 
  FaClock, 
  FaEnvelope, 
  FaBuilding, 
  FaListUl, 
  FaGem,
  FaFileUpload
} from 'react-icons/fa';

const AddJobOpportunity = () => {
  const dispatch = useDispatch();
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);
  const [empresas, setEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(true);
  const [establecerRangoSalario, setEstablecerRangoSalario] = useState(false);
  const [archivoArte, setArchivoArte] = useState(null);
  const [archivoUrl, setArchivoUrl] = useState(null);

  const [formData, setFormData] = useState({
    id_empresa: '',
    titulo: '',
    descripcion: '',
    salario_min: '',
    salario_max: '',
    tipo_contrato: '',
    modalidad: '',
    fecha_publicacion: new Date().toISOString().split('T')[0],
    fecha_cierre: '',
    habilidades_requeridas: '',
    habilidades_deseadas: '',
    anios_experiencia: '',
    beneficios_especificos: '',
    posibilidad_crecimiento: false,
    proceso_seleccion: false,
    estado_ofertaLaboral: 'Abierta',
    correo_contacto: ''
  });

  useEffect(() => {
    if (!usuario) {
      dispatch(getMe());
    }
    if (usuario) {
      getEmpresas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, usuario]);

  const getEmpresas = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/empresas/usuario`);
      setEmpresas(response.data);
      setLoadingEmpresas(false);
    } catch (error) {
      console.error('Error al cargar empresas:', error);
      setLoadingEmpresas(false);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setArchivoArte(file);
      setArchivoUrl(URL.createObjectURL(file)); // Vista previa del archivo
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Si el usuario desactiva el rango salarial, limpiar los valores
    if (name === 'establecerRangoSalario') {
      setEstablecerRangoSalario(checked);
      if (!checked) {
        setFormData((prevData) => ({
          ...prevData,
          salario_min: '',
          salario_max: '',
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          salario_min: '0',
          salario_max: '1',
        }));
      }
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación: Si se establece un rango salarial, salario_max debe ser >= salario_min
    if (establecerRangoSalario) {
      const min = parseFloat(formData.salario_min);
      const max = parseFloat(formData.salario_max);
      if (isNaN(min) || isNaN(max) || max < min) {
        alert('El salario máximo debe ser mayor o igual al mínimo.');
        return;
      }
    }

    try {
      await axios.post(`${baseURL.BASE_URL}/oportunidadesLaborales/add_oportunidadLaboral`, formData);
      alert('Oferta laboral creada exitosamente');
      // Reiniciar el formulario
      setFormData({
        id_empresa: '',
        titulo: '',
        descripcion: '',
        salario_min: '',
        salario_max: '',
        tipo_contrato: '',
        modalidad: '',
        fecha_publicacion: new Date().toISOString().split('T')[0],
        fecha_cierre: '',
        habilidades_requeridas: '',
        habilidades_deseadas: '',
        anios_experiencia: '',
        beneficios_especificos: '',
        posibilidad_crecimiento: false,
        proceso_seleccion: false,
        estado_ofertaLaboral: 'Abierta',
        correo_contacto: ''
      });
      setEstablecerRangoSalario(false);
    } catch (error) {
      console.error('Error al crear la oferta laboral:', error);
      alert('Ocurrió un error al crear la oferta laboral');
    }
  };

  if (isLoading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-600"></div>
    </div>
  );
  if (isError) return <div className="text-red-500 text-center font-bold text-xl">Error al cargar usuario.</div>;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto bg-white rounded-lg shadow-2xl p-8 my-10"
      role="main"
    >
      <h2 className="text-4xl font-extrabold text-indigo-600 mb-8 text-center">
        Crear Nueva Oportunidad Laboral
      </h2>
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="id_empresa">
              <FaBuilding className="inline mr-2 text-indigo-500" />
              Empresa
            </label>
            <select
              id="id_empresa"
              name="id_empresa"
              value={formData.id_empresa}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              required
            >
              <option value="">Selecciona una empresa</option>
              {!loadingEmpresas && empresas.map((empresa) => (
                <option key={empresa.uuid_empresa} value={empresa.id}>{empresa.nombre_legal}</option>
              ))}
            </select>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="titulo">
              <FaBriefcase className="inline mr-2 text-indigo-500" />
              Título del Puesto
            </label>
            <input
              type="text"
              id="titulo"
              name="titulo"
              value={formData.titulo}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="Ej. Desarrollador Frontend"
              required
            />
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="tipo_contrato">
              <FaClock className="inline mr-2 text-indigo-500" />
              Tipo de Contrato
            </label>
            <select
              id="tipo_contrato"
              name="tipo_contrato"
              value={formData.tipo_contrato}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              required
            >
              <option value="">Selecciona el tipo de contrato</option>
              <option value="Tiempo Completo">Tiempo Completo</option>
              <option value="Medio Tiempo">Medio Tiempo</option>
              <option value="Freelance">Freelance</option>
              <option value="Temporal">Temporal</option>
            </select>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="modalidad">
              <FaLaptopCode className="inline mr-2 text-indigo-500" />
              Modalidad
            </label>
            <select
              id="modalidad"
              name="modalidad"
              value={formData.modalidad}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              required
            >
              <option value="">Selecciona la modalidad</option>
              <option value="Presencial">Presencial</option>
              <option value="Remoto">Remoto</option>
              <option value="Híbrido">Híbrido</option>
            </select>
          </motion.div>

          {/* Checkbox para establecer rango salarial */}
          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2 flex items-center">
            <input
              type="checkbox"
              id="establecerRangoSalario"
              name="establecerRangoSalario"
              checked={establecerRangoSalario}
              onChange={handleChange}
              className="h-5 w-5 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="establecerRangoSalario" className="ml-3 block text-sm font-medium text-gray-700">
              Establecer Rango Salarial
            </label>
          </motion.div>

          {/* Campos Condicionales para Salario Mínimo y Máximo */}
          {establecerRangoSalario && (
            <>
              <motion.div whileHover={{ scale: 1.02 }}>
                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="salario_min">
                  <FaMoneyBillWave className="inline mr-2 text-indigo-500" />
                  Salario Mínimo (USD)
                </label>
                <input
                  type="number"
                  id="salario_min"
                  name="salario_min"
                  value={formData.salario_min}
                  onChange={handleChange}
                  className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                  min="0"
                  required={establecerRangoSalario}
                />
              </motion.div>

              <motion.div whileHover={{ scale: 1.02 }}>
                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="salario_max">
                  <FaMoneyBillWave className="inline mr-2 text-indigo-500" />
                  Salario Máximo (USD)
                </label>
                <input
                  type="number"
                  id="salario_max"
                  name="salario_max"
                  value={formData.salario_max}
                  onChange={handleChange}
                  className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                  min="0"
                  required={establecerRangoSalario}
                />
              </motion.div>
            </>
          )}

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="fecha_cierre">
              <FaCalendarAlt className="inline mr-2 text-indigo-500" />
              Fecha de Cierre
            </label>
            <input
              type="date"
              id="fecha_cierre"
              name="fecha_cierre"
              value={formData.fecha_cierre}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              required
            />
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="anios_experiencia">
              <FaStar className="inline mr-2 text-indigo-500" />
              Años de Experiencia
            </label>
            <input
              type="number"
              id="anios_experiencia"
              name="anios_experiencia"
              value={formData.anios_experiencia}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              min="0"
              required
            />
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="descripcion">
              <FaListUl className="inline mr-2 text-indigo-500" />
              Descripción
            </label>
            <textarea
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="Descripción detallada de la oferta laboral..."
              required
            ></textarea>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="habilidades_requeridas">
              <FaGem className="inline mr-2 text-indigo-500" />
              Habilidades Requeridas
            </label>
            <textarea
              id="habilidades_requeridas"
              name="habilidades_requeridas"
              value={formData.habilidades_requeridas}
              onChange={handleChange}
              rows="3"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="Ej. React, JavaScript, CSS..."
              required={establecerRangoSalario}
            ></textarea>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }}>
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="habilidades_deseadas">
              <FaGem className="inline mr-2 text-indigo-500" />
              Habilidades Deseadas (No excluyente)
            </label>
            <textarea
              id="habilidades_deseadas"
              name="habilidades_deseadas"
              value={formData.habilidades_deseadas}
              onChange={handleChange}
              rows="3"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="Ej. TypeScript, Tailwind CSS..."
            ></textarea>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="beneficios_especificos">
              <FaGem className="inline mr-2 text-indigo-500" />
              Beneficios Específicos
            </label>
            <textarea
              id="beneficios_especificos"
              name="beneficios_especificos"
              value={formData.beneficios_especificos}
              onChange={handleChange}
              rows="3"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="Ej. Seguro médico, Flexibilidad horaria..."
              required
            ></textarea>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2 flex items-center">
            <input
              type="checkbox"
              id="posibilidad_crecimiento"
              name="posibilidad_crecimiento"
              checked={formData.posibilidad_crecimiento}
              onChange={handleChange}
              className="h-5 w-5 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="posibilidad_crecimiento" className="ml-3 block text-sm font-medium text-gray-700">
              Posibilidad de Crecimiento
            </label>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2 flex items-center">
            <input
              type="checkbox"
              id="proceso_seleccion"
              name="proceso_seleccion"
              checked={formData.proceso_seleccion}
              onChange={handleChange}
              className="h-5 w-5 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="proceso_seleccion" className="ml-3 block text-sm font-medium text-gray-700">
              Proceso de Selección
            </label>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="correo_contacto">
              <FaEnvelope className="inline mr-2 text-indigo-500" />
              Correo de Contacto
            </label>
            <input
              type="email"
              id="correo_contacto"
              name="correo_contacto"
              value={formData.correo_contacto}
              onChange={handleChange}
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
              placeholder="reclutamiento@empresa.com"
              required
            />
          </motion.div>
        </div>
        <motion.div whileHover={{ scale: 1.02 }} className="col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="archivo_arte">
            <FaFileUpload className="inline mr-2 text-indigo-500" />
            Archivo de Arte
          </label>
          <input
            type="file"
            id="archivo_arte"
            name="archivo_arte"
            onChange={handleFileChange}
            accept="image/*"
            className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
          />
        </motion.div>
        <motion.div 
          className="flex justify-center mt-8"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <button
            type="submit"
            className="py-3 px-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-full shadow-lg transition-transform duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
          >
            Publicar Oferta Laboral
          </button>
        </motion.div>
        
      </form>
    </motion.div>
  );
};

export default AddJobOpportunity;
