import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaSearch, FaFilter } from 'react-icons/fa';
import baseURL from "../../features/baseURL.js";
import { useSelector } from 'react-redux';
import PostulacionCard from './PostulacionCard';

const DashboardProfesional = () => {
  const [postulaciones, setPostulaciones] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState({ status: 'all', date: 'all' });
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const user = useSelector((state) => state.auth.usuario);

  useEffect(() => {
    const fetchProfileAndPostulaciones = async () => {
      setIsLoading(true);

      if (user) {
        try {
          const profileResponse = await axios.get(`${baseURL.BASE_URL}/perfilesProfesionales/usuario/${user.uuid_usuario}`);
          setProfile(profileResponse.data);

          // Ahora que tenemos el perfil, podemos buscar las postulaciones
          const res = await axios.get(`${baseURL.BASE_URL}/postulacionesLaborales/perfilProfesional/${profileResponse.data.uuid_perfilProfesional}`);
          setPostulaciones(res.data);
        } catch (error) {
          console.error('Error fetching data:', error);
          toast.error('Error al cargar los datos.');
        }
      }

      setIsLoading(false);
    };

    fetchProfileAndPostulaciones();
  }, [user]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (key, value) => {
    setFilterOptions(prev => ({ ...prev, [key]: value }));
  };

  const filteredPostulaciones = postulaciones.filter(postulacion => {
    const matchesSearch = postulacion.comentarios.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterOptions.status === 'all' || postulacion.estado === filterOptions.status;

    const matchesDate = (dateFilter) => {
      const today = new Date();
      const postulacionDate = new Date(postulacion.fecha_postulacion);
      switch (dateFilter) {
        case 'lastWeek':
          return (today - postulacionDate) <= 7 * 24 * 60 * 60 * 1000; // 7 días
        case 'lastMonth':
          return (today - postulacionDate) <= 30 * 24 * 60 * 60 * 1000; // 30 días
        case 'lastYear':
          return (today - postulacionDate) <= 365 * 24 * 60 * 60 * 1000; // 365 días
        default:
          return true; // Para "todas las fechas"
      }
    };

    return matchesSearch && matchesStatus && matchesDate(filterOptions.date);
  });

  // Nueva función para manejar la eliminación de la postulación
  const handleDelete = async (uuid) => {
    try {
      const response = await axios.delete(`${baseURL.BASE_URL}/postulacionesLaborales/${uuid}`);
      if (response.status === 200) {
        setPostulaciones(prev => prev.filter(postulacion => postulacion.uuid_postulacionlaboral !== uuid));
        toast.success('Postulación eliminada con éxito');
      }
    } catch (error) {
      console.error('Error al eliminar la postulación:', error);
      toast.error('Error al eliminar la postulación');
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto p-6"
    >
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Mis Postulaciones</h1>

      <div className="mb-6">
        <div className="flex items-center bg-white rounded-lg shadow-md p-2">
          <FaSearch className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Buscar postulaciones..."
            className="w-full outline-none"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-4">
          <select
            className="bg-white rounded-lg shadow-md p-2"
            value={filterOptions.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
          >
            <option value="all">Todos los estados</option>
            <option value="Revision">En Revisión</option>
            <option value="Aceptada">Aceptada</option>
            <option value="Rechazada">Rechazada</option>
          </select>
          <select
            className="bg-white rounded-lg shadow-md p-2"
            value={filterOptions.date}
            onChange={(e) => handleFilterChange('date', e.target.value)}
          >
            <option value="all">Todas las fechas</option>
            <option value="lastWeek">Última semana</option>
            <option value="lastMonth">Último mes</option>
            <option value="lastYear">Último año</option>
          </select>
        </div>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300 flex items-center">
          <FaFilter className="mr-2" /> Filtrar
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
        </div>
      ) : (
        <motion.div layout className="grid gap-6">
          {filteredPostulaciones.map(postulacion => (
            <PostulacionCard 
              key={postulacion.uuid_postulacionlaboral} 
              postulacion={postulacion} 
              onDelete={handleDelete} // Pasa handleDelete como prop
            />
          ))}
        </motion.div>
      )}
    </motion.div>
  );
};

export default DashboardProfesional;
