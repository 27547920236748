import React from 'react';
import { Link } from 'react-router-dom';
const EmpresaCard = ({id, nombre, logo, descripcion, onEditar, onVer }) => {
  return (
    <div 
      className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
    >
      <img src={logo} alt={nombre} className="h-40 w-full object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold text-gray-800">{nombre}</h3>
        <p className="text-gray-600 mt-2 text-sm">{descripcion}</p>
        <div className="flex justify-between mt-4">
          
        <Link 
            to={`/mi-red/empresas/update_empresa/${id}`} 
            className="bg-green-500 text-white px-4 py-2 rounded-full font-semibold hover:bg-green-600 transition-colors"
            onClick={onVer}  // Ejecuta la función onVer antes de redirigir
          >Editar
          </Link>
         
        </div>
      </div>
    </div>
  );
};

export default EmpresaCard;
