import React from 'react';
import { FaCheck, FaTimes, FaFilePdf } from 'react-icons/fa';
import axios from 'axios';
import baseURL from "../../features/baseURL";
import { toast } from 'react-toastify';

const PostulanteCard = ({ postulante, onUpdate }) => {

  const handleChangeStatus = async (estado) => {
    try {
      await axios.patch(`/postulacionesLaborales/update_postulacion/${postulante.uuid_postulacionlaboral}`, { estado });
      onUpdate();  // Refresca la lista tras cambios
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      toast.error('Error al actualizar el estado');
    }
  };

  const handleDownloadCV = async () => {
    if (postulante.url_cv) {
      try {
        console.log('Descargando CV desde:', postulante.url_cv); // Log de depuración
        const response = await fetch(`${baseURL.BASE_URL}${postulante.url_cv}`);
  
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }
  
        const blob = await response.blob(); // Obtener el archivo como un Blob
        const url = window.URL.createObjectURL(blob); // Crear un URL del Blob
        const a = document.createElement('a'); // Crear un enlace
        a.href = url;
        a.download = `CV_${postulante.perfilesProfesionale.usuario.exalumno.nombre_completo}.pdf`; // Nombre personalizado
        document.body.appendChild(a); // Agregar el enlace al DOM
        a.click(); // Simular clic en el enlace
        a.remove(); // Remover el enlace del DOM
        window.URL.revokeObjectURL(url); // Liberar el objeto URL
      } catch (error) {
        console.error('Error al descargar el CV:', error);
        toast.error('Error al descargar el CV');
      }
    } else {
      toast.warn('No hay CV disponible');
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4 transition transform hover:-translate-y-1 hover:shadow-xl">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">{postulante.perfilesProfesionale.usuario.exalumno.nombre_completo}</h3>
      <p className="text-gray-600 mb-1"><strong>Email: </strong>{postulante.perfilesProfesionale.usuario.email_usuario}</p>
      <p className="text-gray-600 mb-1"><strong>Estado actual:</strong> <span className={`font-bold ${postulante.estado === 'Aceptada' ? 'text-green-600' : postulante.estado === 'Rechazada' ? 'text-red-600' : 'text-yellow-600'}`}>{postulante.estado}</span></p>
      <p className="text-gray-600 mb-4"><strong>Fecha de postulación: </strong>{new Date(postulante.fecha_postulacion).toLocaleDateString()}</p>
      
      {postulante.comentarios && (
        <div className="bg-gray-100 p-3 rounded mb-4">
          <h4 className="font-medium text-gray-700">Comentarios:</h4>
          <p className="text-gray-600">{postulante.comentarios}</p>
        </div>
      )}

      <button
        onClick={handleDownloadCV}
        className="w-full bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 flex items-center justify-center mb-4 transition"
      >
        <FaFilePdf className="mr-2" /> Descargar CV
      </button>

      {/* Solo mostrar botones si el estado no es 'Aceptada' o 'Rechazada' */}
      {postulante.estado !== 'Aceptada' && postulante.estado !== 'Rechazada' && (
        <div className="flex justify-between">
          <button
            onClick={() => handleChangeStatus('Aceptada')}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center justify-center transition"
          >
            <FaCheck className="mr-2" /> Aceptar
          </button>
          <button
            onClick={() => handleChangeStatus('Rechazada')}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center justify-center transition"
          >
            <FaTimes className="mr-2" /> Rechazar
          </button>
        </div>
      )}
    </div>
  );
};

export default PostulanteCard;
