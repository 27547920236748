import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import JobApplication from '../../Components/OportunidadLaboral/AplicacionLaboral.jsx'
const AplicacionLaboralPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <LayoutMain>
       <JobApplication/>
   </LayoutMain>
  )
}

export default AplicacionLaboralPage