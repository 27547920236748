import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Camera } from 'lucide-react';
import axios from 'axios';
import { isAction } from '@reduxjs/toolkit';
import baseURL from "../../features/baseURL.js"
const ProfilePhotoUpload = () => {
  const [photo, setPhoto] = useState(null);
  const [showWelcome, setShowWelcome] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({


});
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
      setIsLoading(true);
      await getMe();
    } catch (error) {
      setError('Error al cargar los datos. Por favor, intenta de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  const getMe = async () => {
    try {
      const response = await axios.get('/me');
      setUsuario(response.data);
    } catch (error) {
      console.error('Error al obtener los datos del usuario', error);
      throw error;
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setFormData({...FormData,imagen:file})
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFinalize = async () => {
    try {
      // Aquí deberías implementar la lógica para subir la foto al servidor
      console.log(usuario);
      const data=new FormData();
      for (const key in formData) {
        if (key === 'imagen' && formData[key] === null) {
            continue; // Skip adding 'imagen' if it is null
        }
        data.append('url_foto_perfil_usuario', formData[key]);
    }
        await axios.patch(`${baseURL.BASE_URL}/usuarios/update_fotoPerfil/${usuario.uuid_usuario}`,data,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
      setShowWelcome(true);
      setTimeout(() => {
        navigate('/home');
      }, 5000);
    } catch (error) {
      console.error('Error al finalizar el proceso', error);
      setError('Error al subir la foto. Por favor, intenta de nuevo.');
    }
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      {!showWelcome ? (
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"
        >
          <h2 className="text-2xl font-bold mb-4">Sube tu foto de perfil</h2>
          <div className="flex flex-col items-center space-y-4">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden shadow-lg"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              {photo ? (
                <img src={photo} alt="Profile" className="w-full h-full object-cover" />
              ) : (
                <Camera className="w-12 h-12 text-gray-400" />
              )}
            </motion.div>
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              className="hidden"
              id="photo-upload"
            />
            <motion.label
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              htmlFor="photo-upload"
              className="px-4 py-2 bg-blue-500 text-white rounded cursor-pointer"
            >
              Seleccionar foto
            </motion.label>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleFinalize}
              disabled={!photo}
              className="px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
              transition={{ duration: 0.4 }}
            >
              Finalizar
            </motion.button>
          </div>
        </motion.div>
      ) : (
        <WelcomeScreen photo={photo} usuario={usuario} />
      )}
    </div>
  );
};

const WelcomeScreen = ({ photo, usuario }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
      className="text-center"
    >
      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 80, damping: 15 }}
        className="text-4xl font-bold mb-4"
      >
        ¡Bienvenido a Reconectate!
      </motion.h1>
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 80, damping: 15, delay: 0.2 }}
        className="w-32 h-32 rounded-full overflow-hidden mx-auto mb-4 shadow-lg"
      >
        <img src={photo} alt="Profile" className="w-full h-full object-cover" />
      </motion.div>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="text-4xl"
      >
        {usuario?.nombre_usuario || 'Usuario'}
      </motion.p>
      <Fireworks />
    </motion.div>
  );
};

const Fireworks = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {[...Array(15)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-2 h-2 bg-yellow-400 rounded-full"
          initial={{
            opacity: 0,
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          animate={{
            opacity: [0, 1, 0],
            x: window.innerWidth * Math.random(),
            y: window.innerHeight * Math.random(),
            transition: {
              duration: 2,
              repeat: Infinity,
              repeatType: 'mirror',
              delay: Math.random() * 1.5,
            },
          }}
        />
      ))}
    </div>
  );
};

export default ProfilePhotoUpload;
