import React, { useState } from 'react';
import { X } from 'lucide-react';
import Flag from 'react-world-flags'; // Importar la librería de banderas

const languages = [
    { code: 'en-US', name: 'Inglés (EE. UU.)', flag: 'US' },
    { code: 'pl', name: 'Polaco', flag: 'PL' },
    { code: 'ru', name: 'Ruso', flag: 'RU' },
    { code: 'uk', name: 'Ucraniano', flag: 'UA' },
    { code: 'de', name: 'Alemán', flag: 'DE' },
    { code: 'en-GB', name: 'Inglés (Reino Unido)', flag: 'GB' },
    { code: 'fr', name: 'Francés', flag: 'FR' },
    { code: 'es', name: 'Español', flag: 'ES' },
    { code: 'it', name: 'Italiano', flag: 'IT' },
    { code: 'ca', name: 'Catalán', flag: 'ES' }, // Catalunya como subdivisión de España
    { code: 'nl', name: 'Neerlandés', flag: 'NL' },
    { code: 'pt-PT', name: 'Portugués (PT)', flag: 'PT' },
    { code: 'pt-BR', name: 'Portugués (BR)', flag: 'BR' },
    { code: 'no', name: 'Noruego', flag: 'NO' },
    { code: 'fi', name: 'Finés', flag: 'FI' },
    { code: 'sv', name: 'Sueco', flag: 'SE' },
    { code: 'da', name: 'Danés', flag: 'DK' },
    { code: 'cs', name: 'Checo', flag: 'CZ' },
    { code: 'hu', name: 'Húngaro', flag: 'HU' },
    { code: 'ro', name: 'Rumano', flag: 'RO' },
    { code: 'ja', name: 'Japonés', flag: 'JP' },
    { code: 'zh-CN', name: 'Chino simplificado', flag: 'CN' },
    { code: 'zh-TW', name: 'Chino tradicional', flag: 'TW' },
    { code: 'el', name: 'Griego', flag: 'GR' },
    { code: 'tr', name: 'Turco', flag: 'TR' },
    { code: 'bg', name: 'Búlgaro', flag: 'BG' },
    { code: 'ar', name: 'Árabe', flag: 'SA' },
    { code: 'ko', name: 'Coreano', flag: 'KR' },
    { code: 'he', name: 'Hebreo', flag: 'IL' },
    { code: 'lv', name: 'Letón', flag: 'LV' },
    { code: 'et', name: 'Estonio', flag: 'EE' },
    { code: 'sr', name: 'Serbio', flag: 'RS' },
    { code: 'sl', name: 'Esloveno', flag: 'SI' },
    { code: 'lt', name: 'Lituano', flag: 'LT' },
    { code: 'sk', name: 'Eslovaco', flag: 'SK' },
    { code: 'vi', name: 'Vietnamita', flag: 'VN' },
    { code: 'th', name: 'Tailandés', flag: 'TH' },
    { code: 'id', name: 'Indonesio', flag: 'ID' },
    { code: 'ms', name: 'Malayo', flag: 'MY' },
    { code: 'hi', name: 'Hindi', flag: 'IN' },
    { code: 'qu', name: 'Quechua', flag: 'BO' }, // Quechua (Perú)
    { code: 'gn', name: 'Guaraní', flag: 'BO' }, // Guaraní (Paraguay)
    { code: 'ay', name: 'Aymara', flag: 'BO' } // Aymara (Bolivia)
  ]
  ;

const LanguageSelector = ({ onSelectLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    onSelectLanguage(language);
  };

  const suggestedLanguages = languages.slice(0, 5);

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <Flag code={selectedLanguage.flag} className="w-6 h-4 mr-2" />
        {selectedLanguage.name}
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-4 relative">
            <div className="flex justify-between items-center border-b pb-2">
              <h3 className="text-lg font-medium">Selecciona tu lenguaje:</h3>
              <button onClick={() => setIsOpen(false)} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="py-2">
              <h4 className="text-sm font-medium text-gray-500">Sugerencias para usted:</h4>
              <div className="mt-2 grid grid-cols-2 gap-2">
                {suggestedLanguages.map((language) => (
                  <button
                    key={language.code}
                    onClick={() => handleSelectLanguage(language)}
                    className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full rounded-md"
                  >
                    <Flag code={language.flag} className="w-6 h-4 mr-2" />
                    {language.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="py-2 border-t">
              <h4 className="text-sm font-medium text-gray-500">Todos los</h4>
              <div className="mt-2 grid grid-cols-2 gap-2 h-64 overflow-y-auto">
                {languages.map((language) => (
                  <button
                    key={language.code}
                    onClick={() => handleSelectLanguage(language)}
                    className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full rounded-md"
                  >
                    <Flag code={language.flag} className="w-6 h-4 mr-2" />
                    {language.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
