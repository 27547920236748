import axios from 'axios';

const config = {
    BASE_URL: 'https://apibolsareconectate.fesa.edu.bo'  // Cambiado a HTTPS
};

// Configuración base de axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.BASE_URL;

// Configurar headers por defecto
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default config;