import React, { useEffect, useState } from 'react';
import EmpresaCard from './EmpresaCard';
import FichaTecnica from './EmpresaFicha';
import { Link } from 'react-router-dom';
import baseURL from "../../features/baseURL.js"
import axios from 'axios';

const ListaEmpresas = () => {
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [mostrarFicha, setMostrarFicha] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [isLoading,setIsLoading]=useState(false)
  const fetchData=()=>{
    getEmpresasByUser();
  }
  useEffect(() => {
    fetchData();
  }, []);
  const getEmpresasByUser=async()=>{
    setIsLoading(true);
    try {
      const response=await axios.get(`${baseURL.BASE_URL}/empresas/usuario`);
      setEmpresas(response.data || []);
    } catch (error) {
      console.error("Error al obtener emmpresas",error);
      setEmpresas([])
      
    }finally{
      setIsLoading(false)
    }
  }

  const handleVer = (empresa) => {
    setEmpresaSeleccionada(empresa);

    setMostrarFicha(true);
  };

  const handleCloseFicha = () => {
    setMostrarFicha(false);
    setEmpresaSeleccionada(null);
  };

  return (
    <div className="p-4">
      <Link
        to="/mi-red/empresas/add_empresa"
        className="bg-green-500 text-white p-2 rounded mb-4 hover:bg-green-600 transition duration-300"
      >
        Añadir Empresa
      </Link>
  
      {isLoading ? ( // Agregar estado de carga
        <div className="text-center">Cargando empresas...</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {empresas.map((empresa) => (
            <EmpresaCard
              key={empresa.uuid_empresa}
              id={empresa.uuid_empresa}  // Pasamos uuid_empresa como id
              nombre={empresa.nombre_legal}
              logo={`${baseURL.BASE_URL}${empresa.url_logo}`}
              fundacion={empresa.año_fundacion}
              webEmpresa={empresa.webEmpresa}
              descripcion={empresa.descripcion}
              onVer={() => handleVer(empresa)}
            />
          ))}
  
          {mostrarFicha && empresaSeleccionada && (
            <FichaTecnica
              empresa={empresaSeleccionada}
              producto={empresaSeleccionada.producto}
              onClose={handleCloseFicha}
            />
          )}
        </div>
      )}
    </div>
  );
}
  

export default ListaEmpresas;
