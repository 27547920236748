import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from '../../features/baseURL';
import { FaPlus } from 'react-icons/fa';
import OfertaLaboralCardEmpresa from './ofertaLaboralCardEmpresa'; // Asegúrate de que la ruta sea correcta

const GestionarOfertasEmpresa = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ofertas, setOfertas] = useState([]);
  const [filteredOfertas, setFilteredOfertas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOfertas();
  }, [id]);

  useEffect(() => {
    filterOfertas();
  }, [searchTerm, ofertas]);

  const fetchOfertas = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/oportunidadesLaborales/empresa/${id}`);
      setOfertas(response.data);
      setFilteredOfertas(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ofertas laborales:', error);
      setLoading(false);
    }
  };

  const filterOfertas = () => {
    const results = ofertas.filter(oferta =>
      oferta.titulo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOfertas(results);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCrearOferta = () => {
    navigate(`/crear-oferta/${id}`);
  };

  const handleEditar = (oferta) => {
    navigate(`/editar-oferta/${oferta.uuid_ofertasTrabajo}`); // Asegúrate de usar el identificador correcto
  };

  const handleEliminar = async (ofertaId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta oferta?')) {
      try {
        await axios.delete(`${baseURL.BASE_URL}/oportunidadesLaborales/${ofertaId}`);
        fetchOfertas(); // Recargar las ofertas después de eliminar
      } catch (error) {
        console.error('Error al eliminar la oferta:', error);
      }
    }
  };

  const handleVerPostulantes = (ofertaId) => {
    navigate(`/postulantes/${ofertaId}`); // Redirecciona a la página de postulantes
  };

  if (loading) {
    return <div className="text-center">Cargando ofertas laborales...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Gestionar Ofertas Laborales</h1>
      
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Buscar ofertas..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-2/3 p-2 border rounded"
        />
        <button
          onClick={handleCrearOferta}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          <FaPlus className="inline mr-2" /> Crear Nueva Oferta
        </button>
      </div>

      {filteredOfertas.length > 0 ? (
        filteredOfertas.map(oferta => (
          <OfertaLaboralCardEmpresa
            key={oferta.uuid_ofertasTrabajo} // Asegúrate de usar el identificador correcto
            opportunity={oferta} // Cambia "oferta" por "opportunity"
            onEdit={handleEditar}
            onDelete={handleEliminar}
            onViewPostulantes={handleVerPostulantes}
          />
        ))
      ) : (
        <p className="text-center text-gray-500">No se encontraron ofertas laborales.</p>
      )}
    </div>
  );
};

export default GestionarOfertasEmpresa;
