import React from 'react';
import { Calendar, Briefcase, Code } from 'lucide-react';

const ProjectCard = ({ nombre_proyecto, fecha_inicio_proyecto, fecha_final_proyecto, descripcion_proyecto, rol_proyecto, tecnologias }) => {
  return (
    <div className="bg-gradient-to-br from-green-50 to-teal-100 border-2 border-teal-200 rounded-xl shadow-lg p-6 flex flex-col justify-between transition-all hover:shadow-xl hover:-translate-y-1">
      <div className="flex items-start mb-4">
        <div className="w-12 h-12 bg-teal-500 rounded-full flex items-center justify-center mr-4 shadow-md">
          <Briefcase className="text-white" size={24} />
        </div>
        <div>
          <h2 className="font-bold text-2xl text-teal-800">{nombre_proyecto}</h2>
          <p className="text-teal-600 text-lg">{rol_proyecto}</p>
        </div>
      </div>
      
      <div className="space-y-2 mb-4">
        <div className="flex items-center text-gray-700">
          <Calendar className="mr-2" size={18} />
          <span>{fecha_inicio_proyecto} - {fecha_final_proyecto}</span>
        </div>
        <div className="flex items-center text-gray-700">
          <Code className="mr-2" size={18} />
          <span>{tecnologias}</span>
        </div>
      </div>
      
      <p className="text-gray-600 mb-4">{descripcion_proyecto}</p>
      
      <div className="flex justify-between items-center mt-4 pt-4 border-t border-teal-200">
        <span className="text-sm text-gray-500">Proyecto</span>
      </div>
    </div>
  );
};

export default ProjectCard;