import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessModal = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  if (!isVisible) return null;

  const handleClose = () => {
    onClose(); // Cierra el modal
    navigate(`/login`);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-lg text-center w-80">
        <div className="mb-4">
          <svg
            className="w-12 h-12 mx-auto stroke-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="stroke-blue-600"
              cx="26"
              cy="26"
              r="25"
              fill="none"
              strokeWidth="2"
            />
            <path
              className="stroke-blue-600"
              fill="none"
              d="M14 26l7 7 15-15"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h2 className="text-2xl text-blue-600 mb-4">¡Registro Exitoso!</h2>
        <p className="text-gray-800 mb-4">
          Tu cuenta ha sido creada con éxito. Te hemos enviado un correo electrónico de confirmación.
        </p>
        <button
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          onClick={handleClose}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
