import React,{useEffect} from 'react'
import MainFeedNavBar from "../MainFeed/MainFeedNavBar.jsx"
import { useNavigate } from 'react-router-dom'
import PerfilResumeComponent from '../../Components/Profile/PerfilResumeComponent.jsx';
const MiPerfilPage= () => {
    const navigate=useNavigate();
    
    
  return (
   <MainFeedNavBar>
        <PerfilResumeComponent/>
   </MainFeedNavBar>
  )
}

export default MiPerfilPage
