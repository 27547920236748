// src/components/ui/Card.jsx

import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Card = ({ title, description, deadline, status,tipoExperienciaLaboral }) => {
    console.log(status)
  return (
    <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-4 flex flex-col justify-between transition-transform transform hover:scale-105">
      <div className="flex items-start">
        <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3 shadow-sm">
          <span role="img" aria-label="Globe" className="text-blue-500 text-xl">💼</span>
        </div>
        <h1 className="font-semibold text-xl text-gray-800">{title}</h1>
      </div>
      <p className="text-gray-700 text-base mt-2">{description}</p>
      <p className="text-gray-700 text-base mt-2">{tipoExperienciaLaboral}</p>

      <div className="flex justify-between items-center mt-4">
            <p className="flex flex-col text-gray-600 text-sm mt-1">
        <span className="flex items-center">
        <CalendarTodayIcon className="text-gray-400 mr-1" />
        <span className="font-semibold">Fechas:</span>
        </span>
        <span className="text-gray-800">{deadline}</span>
        </p>

    

        <span className={`px-2 py-1 text-xs font-semibold text-white rounded-full ${status === false ? 'bg-yellow-500' : 'bg-green-500'}`}>
          {status}
        </span>
      </div>
    </div>
  );
};

export default Card;
