import React from 'react';
import NavBarMain from '../../Components/NavBarMain/NavBarMain';
import ReConectateBanner from '../../Components/NavBarMain/BannerPrincipal';
import SaludoUsuario from '../../Components/NavBarMain/SaludoPrincipal';
import MainEventos from '../../Components/NavBarMain/MainEventos';
import MainPublication from '../../Components/NavBarMain/MainPublicacion';
import Feed from '../../Components/MainFeed/Feed';
import AddPublicacion from "../../Components/Publicacion/AddPublicacion.jsx"
const Layout = ({ children }) => {
  const eventos = [
    { nombre: 'Conferencia Anual', diaLugar: '15 Agosto/FESA' },
    { nombre: 'Taller de Programación', diaLugar: '16 Agosto/FESA' },
    { nombre: 'Webinar de Tecnología', diaLugar: '17 Agosto/FESA' },
    { nombre: 'Webinar de Tecnología', diaLugar: '18 Agosto/FESA' },
  ];

  return (
    <div className="flex flex-col h-screen">
      <NavBarMain />

      <div className="flex mt-12">
        <ReConectateBanner />
      </div>

      <div className="flex-1 grid grid-cols-12 gap-4 p-4 overflow-y-auto" style={{ backgroundColor: '#F4F2EE' }}>
        {/* Sección de Eventos */}
        <div className="col-span-3 mt-6">
            <MainEventos eventos={eventos} className="mb-8" />
            
        </div>

        {/* Sección Principal con Saludo y Feed */}
        <div className="col-span-6 flex flex-col">
          <div className="flex-1 overflow-hidden hover:overflow-y-auto">
          <div className="flex-1 mt-10 mb-10">
          <SaludoUsuario />

            </div>
            <div className="flex-1 mt-10 mb-10">
            <AddPublicacion/>
            
            </div>
            
                    <Feed />
            
            
          </div>
          
        </div>

        {/* Sección de Publicaciones */}
        <div className="col-span-3 mt-10">
          <MainPublication />
        </div>
      </div>
    </div>
  );
};

export default Layout;
