import React from 'react';
import { FaElementor } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MainPublication = () => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-md ">
      <aside className="flex flex-col items-center w-100">
        <div className="flex  mb-5">
          <div className="w-[70px] h-[70px] bg-[#F4F2EE] rounded-full flex items-center justify-center">
            <FaElementor className="w-10 h-10 text-[#C6C0BD]" />
          </div>
        </div>
        <div className="mb-4 w-full">
          <Link
            to="/add_publicacion" // Navega a la ruta deseada
            className="block w-full py-3 px-4 bg-[#F4F2EE] hover:bg-[#b8b8b7] text-black rounded-full transition duration-300 ease-in-out font-STHeitiMedium text-lg text-center"
          >
            Crear Publicación
          </Link>
        </div>

        <ul className="space-y-2 font-STHeiti text-center w-full">
          <li>
            <Link 
              to="#contenido-multimedia" 
              className="block w-full px-3 py-2 text-black-700 hover:bg-gray-200 rounded transition duration-300 ease-in-out text-center"
            >
              Contenido Multimedia
            </Link>
          </li>
          <li>
            <Link 
              to="#eventos" 
              className="block w-full px-3 py-2 text-black-700 hover:bg-gray-200 rounded transition duration-300 ease-in-out text-center"
            >
              Eventos
            </Link>
          </li>
          <li>
            <Link 
              to="#articulos" 
              className="block w-full px-3 py-2 text-black-700 hover:bg-gray-200 rounded transition duration-300 ease-in-out text-center"
            >
              Artículos
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default MainPublication;
