import React, { useState, useRef } from 'react';
import { Camera } from 'lucide-react';
import axios from 'axios';
import baseURL from "../../features/baseURL.js";
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const AgregarEmpresa = ({ onAgregar }) => {
  const navigate = useNavigate(); // Inicializa el hook para redirigir
  const [nombreLegal, setNombreLegal] = useState('');
  const [industria, setIndustria] = useState('');
  const [anoFundacion, setAnoFundacion] = useState('');
  const [webEmpresa, setWebEmpresa] = useState('');
  const [logo, setLogo] = useState(null);
  const [NIT, setNIT] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [descripcion, setDescripcion] = useState('');
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setIsLoading(true); // Activa el estado de carga
    try {
      const data = new FormData();
      data.append('nombre_legal', nombreLegal);
      data.append('industria', industria);
      data.append('ano_Fundacion', anoFundacion);
      data.append('webEmpresa', webEmpresa);
      data.append('descripcion', descripcion);
      data.append('telefono', telefono);
      data.append('correo', correo);
      data.append('NIT', NIT);
      if (logo) {
        data.append('url_logo', logo);
      }

      const response = await axios.post(`${baseURL.BASE_URL}/empresas/add_empresa`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log(response);
      // Redirige a la página de empresas después de registrar la empresa
      navigate('/mi-red/empresas'); 

    } catch (error) {
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setError(`Error: ${error.response.data.msg || 'Error al registrar la empresa.'}`);
      } else if (error.request) {
        console.error('Solicitud enviada, sin respuesta recibida:', error.request);
        setError('Error: No se recibió respuesta del servidor.');
      } else {
        console.error('Error al configurar la solicitud:', error.message);
        setError(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false); // Desactiva el estado de carga en cualquier caso
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-2xl rounded-lg overflow-hidden">
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
        <h3 className="text-3xl font-bold text-center">Registro de Nueva Empresa</h3>
        <p className="text-center mt-2 text-blue-100">Únete a nuestra red de negocios innovadores</p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-8 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4 md:col-span-1">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre Legal</label>
            <input 
              type="text" 
              value={nombreLegal}
              onChange={(e) => setNombreLegal(e.target.value)}
              required
              placeholder='Ingresa el nombre legal de la empresa..'
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
            />
          </div>
          <div className='flex md-4'>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">Industria</label>
              <input 
                type="text" 
                value={industria}
                onChange={(e) => setIndustria(e.target.value)}
                required
                placeholder='Ingresa la industria de la empresa...'
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">NIT</label>
              <input 
                type="number" 
                value={NIT}
                onChange={(e) => setNIT(e.target.value)}
                required
                placeholder='Ingresa el nit de la empresa...'
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Año de Fundación</label>
            <input 
              type="date" 
              value={anoFundacion}
              onChange={(e) => setAnoFundacion(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Web de la Empresa</label>
            <input 
              type="text" 
              value={webEmpresa}
              onChange={(e) => setWebEmpresa(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
              placeholder='Ingresa la pagina web de la empresa...'
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Telefono</label>
            <input 
              type="text" 
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
              placeholder='Ingresa el telefono de la empresa...'
            />
          </div>
        </div>
        
        <div className="space-y-4 md:col-span-1">
          <div>
            <label className="block text-sm font-medium text-gray-700">Logo de la Empresa</label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                {previewUrl ? (
                  <img src={previewUrl} alt="Logo Preview" className="mx-auto h-32 w-32 object-cover rounded-full" />
                ) : (
                  <Camera className="mx-auto h-12 w-12 text-gray-400" />
                )}
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                    <span>Subir un archivo</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleLogoChange}
                      ref={fileInputRef}
                    />
                  </label>
                  <p className="pl-1">o arrastrar y soltar</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF hasta 10MB</p>
              </div>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Descripción</label>
            <textarea 
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-100 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Correo</label>
            <input 
              type="text" 
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 h-9 pl-4"
              placeholder='Ingresa el correo de  la empresa...'
            />
          </div>
        </div>
        
        <div className="md:col-span-2 flex justify-between">
          <button 
            type="button" 
            onClick={() => navigate(-1)} // Redirige a la página anterior
            className="w-full bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-md hover:bg-gray-400 transition duration-300"
          >
            Atrás
          </button>
          <button 
            type="submit" 
            className={`w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-4 rounded-md hover:from-blue-600 hover:to-purple-700 transition duration-300 transform hover:scale-105 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} 
            disabled={isLoading} // Desactiva el botón si está cargando
          >
            {isLoading ? 'Cargando...' : 'Registrar Empresa'} {/* Muestra "Cargando..." si está en carga */}
          </button>
        </div>
      </form>
      {error && <p className="text-red-500 text-center">{error}</p>} {/* Muestra errores si los hay */}
    </div>
  );
};

export default AgregarEmpresa;
