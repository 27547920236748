import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarMain from '../../Components/NavBarMain/NavBarMain';
import Sidebar from '../../Components/Profile/sidebarMiespacio.jsx';

const LayoutMainFeedSideBar = ({ children }) => {
  const navigate = useNavigate();

  const handleOptionSelect = (option) => {
    switch (option) {
      case 'empresas':
        navigate('/mi-red/empresas'); // Cambia la ruta según tus necesidades
        break;
      case 'editarPerfil':
        navigate('/mi-red/editar-perfil'); // Cambia la ruta según tus necesidades
        break;
      case 'perfilProfesional':
        navigate('/mi-red/perfilProfesional'); // Cambia la ruta según tus necesidades
        break;
      case 'configuracion':
        navigate('/mi-red/configuracion'); // Cambia la ruta según tus necesidades
        break;
      default:
        break;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <NavBarMain className="fixed top-0 left-0 right-0 z-50" />

      {/* Layout with Sidebar */}
      <div className="flex pt-16">
        {/* Sidebar */}
        <Sidebar onOptionSelect={handleOptionSelect} className="w-64 h-full bg-gray-800 fixed top-0 left-0 z-40" />

        {/* Main Content */}
        <div className="flex-1 mr-70 p-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutMainFeedSideBar;
