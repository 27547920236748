import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import { useNavigate } from 'react-router-dom'
import JobsOp from "../../Components/LandingPage/JobsOp.jsx"
const JobsOpPage = () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <JobsOp/>
    </Layout>
  )
}

export default JobsOpPage;
