import React,{useEffect} from 'react'
import LayoutMain from '../LayoutMain.jsx'
import { useNavigate } from 'react-router-dom'
import GestionarOfertasLaborales from '../../Components/OportunidadLaboral/gestionOfertasLaboralesEmpresa.jsx'
const EmpresaGestionarOfertas= () => {
    
    
  return (
   <LayoutMain>
       <GestionarOfertasLaborales/>
   </LayoutMain>
  )
}

export default  EmpresaGestionarOfertas
