import React from 'react';

const MainEventos = ({ eventos }) => {
  // Definimos un arreglo de colores
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#FFA833'];

  return (
    <div className="p-4">
      <aside className="bg-white shadow-md rounded-lg p-4">
        <p className="text-lg font-semibold font-STHeiti mb-4">Eventos</p>
        <ul className="space-y-4">
          {eventos.length > 0 ? (
            eventos.map((evento, index) => (
              <button
                key={index}
                className="flex items-center p-3 rounded-lg bg-gray-100 border border-gray-300 shadow-sm hover:bg-gray-200 transition-transform transform hover:translate-y-[-2px] cursor-pointer w-full"
              >
                <div
                  className="w-6 h-6 rounded-full mr-3"
                  style={{ backgroundColor: colors[index % colors.length] }}
                ></div>
                <div className="flex flex-col">
                  <p className="text-base font-medium font-STHeiti">{evento.nombre}</p>
                  <p className="text-sm text-gray-800 font-STHeiti">{evento.diaLugar}</p>
                </div>
              </button>
            ))
          ) : (
            <li>No hay eventos disponibles</li>
          )}
        </ul>
      </aside>
    </div>
  );
};

export default MainEventos;
