import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { motion } from 'framer-motion';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaBriefcase } from 'react-icons/fa';

// Icono personalizado para los marcadores
const icon = L.icon({
  iconUrl: 'https://img.icons8.com/ios-filled/50/000000/job.png',
  iconSize: [30, 30],
});

const jobLocations = [
  { position: [37.7749, -122.4194], title: 'Oportunidad en San Francisco', description: 'Desarrollador Frontend en una startup innovadora.' },
  { position: [40.7128, -74.0060], title: 'Oportunidad en Nueva York', description: 'Gerente de Producto en una gran empresa.' },
  { position: [51.5074, -0.1278], title: 'Oportunidad en Londres', description: 'Analista de Datos en una firma de análisis.' },
  { position: [35.6762, 139.6503], title: 'Oportunidad en Tokio', description: 'Ingeniero de Software en una empresa tecnológica.' },
  { position: [-33.8688, 151.2093], title: 'Oportunidad en Sydney', description: 'Diseñador UX/UI en una agencia creativa.' },
];

const JobsPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gray-100 py-20 px-4"
    >
      <div className="max-w-6xl mx-auto text-center">
        <motion.h1
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-5xl font-extrabold mb-6 text-gray-900"
        >
          Oportunidades Laborales alrededor del Mundo
        </motion.h1>
        <motion.p
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-xl mb-10 text-gray-700"
        >
          Explora un mundo lleno de oportunidades laborales. ¡Tu próximo trabajo te está esperando!
        </motion.p>
        
        {/* Mapa Interactivo */}
        <MapContainer center={[20, 0]} zoom={2} scrollWheelZoom={false} className="h-96 rounded-lg shadow-lg">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {jobLocations.map((location, index) => (
            <Marker key={index} position={location.position} icon={icon}>
              <Popup>
                <div className="text-center">
                  <FaBriefcase className="text-blue-600 mb-2" />
                  <h3 className="font-semibold">{location.title}</h3>
                  <p className="text-gray-600">{location.description}</p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </motion.div>
  );
};

export default JobsPage;
