import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import { useNavigate } from 'react-router-dom'
import AboutUs from '../../Components/LandingPage/AboutUs.jsx'
const AboutUsPage = () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <AboutUs/>
    </Layout>
  )
}

export default AboutUsPage;
