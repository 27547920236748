import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../features/authSlice';
//import { updateUser } from '../../features/userSlice'; // Asegúrate de tener esta acción
import baseURL from '../../features/baseURL';
import { User, MapPin, Phone, Calendar, Mail, Flag } from 'lucide-react';

const PerfilEditComponent = () => {
  const dispatch = useDispatch();
  const { usuario, isLoading, isError } = useSelector((state) => state.auth);
  const [usuarioEdit, setUsuarioEdit] = useState(null);
  const [isLoading2, setIsLoading2] = useState(true);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (!usuario) {
      dispatch(getMe());
    } else {
      setUsuarioEdit(usuario);
      setIsLoading2(false);
    }
  }, [dispatch, usuario]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioEdit({ ...usuarioEdit, [name]: value });
  };

  const handleSave = async () => {
    try {
    //   await dispatch(updateUser(usuarioEdit)); // Asume que esta acción realiza una solicitud al backend
      alert('Perfil actualizado correctamente');
    } catch (error) {
      setMsg('Error al actualizar el perfil.');
    }
  };

  if (isLoading || isLoading2) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> No se pudo cargar el perfil.</span>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl mx-auto p-6">
      {msg && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {msg}</span>
        </div>
      )}
      {usuarioEdit && (
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InputField 
              label="Nombre de Usuario" 
              name="nombre_usuario" 
              value={usuarioEdit.nombre_usuario} 
              onChange={handleChange} 
              icon={<User />} 
            />
            <InputField 
              label="Fecha de Nacimiento" 
              name="fecha_nacimiento_usuario" 
              type="date" 
              value={usuarioEdit.fecha_nacimiento_usuario} 
              onChange={handleChange} 
              icon={<Calendar />} 
            />
            <InputField 
              label="Teléfono" 
              name="telefono_usuario" 
              value={usuarioEdit.telefono_usuario} 
              onChange={handleChange} 
              icon={<Phone />} 
            />
            <InputField 
              label="Email" 
              name="email_usuario" 
              type="email" 
              value={usuarioEdit.email_usuario} 
              onChange={handleChange} 
              icon={<Mail />} 
            />
            <InputField 
              label="Género" 
              name="genero" 
              value={usuarioEdit.genero} 
              onChange={handleChange} 
              icon={<Flag />} 
            />
            <InputField 
              label="Lugar de Nacimiento" 
              name="ciudad_nacimiento_usuario" 
              value={usuarioEdit.ciudad_nacimiento_usuario} 
              onChange={handleChange} 
              icon={<MapPin />} 
            />
            <InputField 
              label="Lugar de Residencia" 
              name="ciudad_residencia_usuario" 
              value={usuarioEdit.ciudad_residencia_usuario} 
              onChange={handleChange} 
              icon={<MapPin />} 
            />
            <InputField 
              label="Dirección de Referencia" 
              name="direccion_referencia_usuario" 
              value={usuarioEdit.direccion_referencia_usuario} 
              onChange={handleChange} 
              icon={<MapPin />} 
            />
          </div>

          <div className="mt-6">
            <button 
              type="submit" 
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              Guardar Cambios
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

const InputField = ({ label, name, value, onChange, icon, type = "text" }) => (
  <div className="flex items-center space-x-2">
    <div className="text-blue-500">{icon}</div>
    <div className="flex-1">
      <label className="text-sm text-gray-500">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
);

export default PerfilEditComponent;
