import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/authSlice"
import userReducer from "../features/usersSlice"
export const store = configureStore({
  reducer: {
    auth:authReducer,
    users: userReducer, // Asegúrate de que este slice esté aquí

    
  },
});
